import * as React from 'react';
import { has } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { preferredOrder, reorder, getItemStyle, getListStyle } from '../utils/for-dnd';

import { FileUpload } from './FileUpload';
import { Item, CopyableItemAttributes } from '../../types/Item';
import ItemEditor, { ItemEditorWithCollapse } from './ItemEditor';
import { Button, Col, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { AuthContext } from '../../providers/AuthProvider';
import { adminGetItem } from '../../REST/items';
import { removeTopology } from '../utils/removeTopology';

import 'styles/components/metadata/accordionCollapse.scss';
import RollingHeader from 'components/layout/RollingHeader';
import FilePreview from './FilePreview';
import { File as UploadFile } from './FileUpload'
import { FaTimes } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import classNames from 'classnames';
import { API } from 'aws-amplify';

import { Spinner } from 'reactstrap';

interface Props extends RouteComponentProps {
  callback?: Function;
  items?: Item[];
  allowRemoveItem?: boolean;
  updateItemsOrder: Function;
  isAdmin: boolean;
  centerSlot?: JSX.Element;
}

interface State {
  items: ItemsObject;
  isNewItem: boolean;
  updateKeyCount: number;
  uploadedFiles: UploadFile[]
  selectedItemS3Key: string
  itemsToUpdate: {
    [key: string]: string;
  }
}

interface ItemsObject {
  [id: string]: {
    loaded: boolean;
    isLoading: boolean;
    details?: Item;
  };
}

const ItemsDisplay = (props: { isNewItem: boolean; isAdmin: boolean; isContributorPath: boolean; removeItem: Function | undefined; s3Key: string; item: { loaded: boolean; isLoading: boolean; details?: Item }; callback: Function; onDataCopy?: Function; updateKey: number; dataToUpdate?: any }): JSX.Element => {

  if (props.item && Object.keys(props.item).length && !props.item.isLoading && props.item.loaded && props.item.details) {
    return (
      <ItemEditor
        item={props.item.details}
        isContributorPath={props.isContributorPath}
        isAdmin={props.isAdmin}
        onDataCopy={props.onDataCopy}
        toUpdate={{ key: props.updateKey, fields: props.dataToUpdate }}
      />
    )
  } else {
    if (props.item.isLoading) {
      return (
        <Row onClick={() => props.callback(props.s3Key)}>
          <Col xs="12">Loading....</Col>
        </Row>
      );
    } else {
      return (
        <Row onClick={() => props.callback(props.s3Key)}>
          <Col xs="12">Click here to load this item&apos;s details.</Col>
        </Row>
      );
    }
  }
};

class ItemsClass extends React.Component<Props, State> {
  _isMounted;
  isContributorPath;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;

    this.state = {
      items: {},
      isNewItem: false,
      uploadedFiles: [],
      updateKeyCount: 0,
      selectedItemS3Key: '',
      itemsToUpdate: {}
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
    // If we have items from props, put them into the items state
    this.setState({ items: this.checkPropsItems() });
    const context: React.ContextType<typeof AuthContext> = this.context;
    if (!context.authorisation.hasOwnProperty('admin')) {
      this.isContributorPath = (this.props.location.pathname.match(/contributor/i));
    } else {
      this.isContributorPath = false;
    }
  }
  componentWillUnmount(): void {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (!this._isMounted) {
 return; 
}

    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.checkPropsItems() });
    }
  }

  checkPropsItems = (): ItemsObject => {
    let propItems: ItemsObject = {};
    if (this.props.items && this.props.items.length) {
      this.props.items.forEach( (item: Item) => {
        Object.assign(propItems, {
          [item.s3_key]: {
            loaded: true,
            isLoading: false,
            details: item
          }
        });
      });
    }
    return propItems;
  };

  /**
   *
   * A response s3key from the FileUpload component
   *
   * @param s3Key { string }
   */
  fileUploadCallback = async (s3Key: string, file: UploadFile): Promise<void> => {
    if (!this._isMounted) return;

    const items: ItemsObject = {};

    Object.assign(items, {
      [s3Key]: {
        loaded: false,
        isLoading: true
      }
    });

    if (file) {
      this.setState({ uploadedFiles: [ ...this.state.uploadedFiles, file ] })
      if (!this.state.selectedItemS3Key) this.setState({ selectedItemS3Key: file.s3key })
    }

    // Load item
    this.loadItem(s3Key);

    this.setState({isNewItem: true, items: {...this.state.items, ...items} } );
  };

  /**
   * Get the item from the database by s3key, assign it to ItemObject
   * Set the loaded flag to true and isLoading to false and the details of the item from the db
   *
   * If we have a callback set as props, call it. (See CollectionsEditor)
   *
   * @param s3Key { string }
   */
  loadItem = async (s3Key: string): Promise<void> => {
    if (!this._isMounted) {
 return; 
}
    const
      items: ItemsObject = {},
      itemState = {
        loaded: false,
        isLoading: false,
      };
    try {
      const result: Item | null = await this.getItemByKey(s3Key);

      if (result) {
        Object.assign(itemState, { details: result, loaded: true });

        if (typeof this.props.callback === 'function') {
          this.props.callback(result.s3_key);
        }
      }
    } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      console.log('error', e);
    } finally {
      Object.assign(items, { [s3Key]: itemState });

      this.setState({ items: {...this.state.items, ...items} } );
    }
  };

  removeItem = async (s3Key: string) => {
    if (!this._isMounted) return

    const items: ItemsObject = this.state.items
    if (items[s3Key]) {
      delete items[s3Key]
      const uploadedFiles = this.state.uploadedFiles.filter(file => file.s3key !== s3Key)

      this.setState({ items, uploadedFiles })

      await API.del('tba21', (this.isContributorPath ? 'contributor/items' :  'admin/items'), {
        queryStringParameters: { s3Key }
      })

      if (typeof this.props.callback === 'function') {
        this.props.callback(s3Key, true)
      }
    }
  }

  onDragEnd = async (result) => {
    // dropped outside the list,
    if (!result.destination) {
      return;
    }

    if (!this._isMounted) { 
      return; 
    }
    const items: ItemsObject = this.state.items;
      
    let keys = Object.keys(items);
    keys = reorder(keys, result.source.index, result.destination.index);
    this.setState({items: preferredOrder(items, keys)}, () => {
      this.props.updateItemsOrder(result.source.index, result.destination.index);
    });
  };

  getItemByKey = async (key: string): Promise<Item | null> => {
    if (!this._isMounted) {
      return null; 
    }
    if (!key || ( has(this.state.items, key) && this.state.items[key].loaded )) {
      return null; 
    }

    let
      counter = 6,
      timeoutSeconds = 1000;

    const doAPICall = async (s3Key: string): Promise<Item | null> => {
      return new Promise( resolve => {

        const apiTimeout = setTimeout( async () => {
          if (!this._isMounted) {
 clearTimeout(apiTimeout); return; 
}
          counter --;

          const response = await adminGetItem(this.isContributorPath, { s3Key });
          timeoutSeconds = timeoutSeconds * 2;

          const tryApiAgain = async() => {
            if (!counter) {
              clearTimeout(apiTimeout);
              return resolve(null);
            } else {
              return resolve(await doAPICall(s3Key));
            }
          };

          if (response) {
            const responseItems = removeTopology(response) as Item[];

            if (responseItems && responseItems.length && !!responseItems[0]) {
              const item = responseItems[0];
              clearTimeout(apiTimeout);
              return resolve(item);
            } else {
              tryApiAgain();
            }
          } else {
            tryApiAgain();
          }
        },                             timeoutSeconds);
      });
    };

    return await doAPICall(key);
  };

  copyDataToAllItems = (itemDetails: Item) => {
    const filteredDetails = Object.fromEntries(
      Object.entries(itemDetails).filter( ([key]) => CopyableItemAttributes.includes(key) )
    )

    // console.log(filteredDetails)

    const updatedItems = {}

    // for (const itemKey in this.state.items) {
    //   if (this.state.items.hasOwnProperty(itemKey)) {
    //     const updatedDetail = { ...this.state.items[itemKey].details, ...filteredDetails }
    //     updatedItems[itemKey] = { ...this.state.items[itemKey], details: updatedDetail }
    //   }
    // }

    const toUpdate = {}
    Object.keys(this.state.items).forEach(key => toUpdate[key] = filteredDetails)

    this.setState({ itemsToUpdate: toUpdate, updateKeyCount: this.state.updateKeyCount + 1  })
  }

  render() {
    const isStandalone = window.location.pathname.includes('/contributor/items/add')
    const isBulkUpload = Object.keys(this.state.items).length > 1
    const selectedItem = this.state.items[this.state.selectedItemS3Key]

    const thumbs = this.state.uploadedFiles.map(file => {
      const classes = classNames(
        'item-thumbnail',
        file.type.toLowerCase(),
        file.uploaded ? 'uploaded' : 'pending',
        { active: file.s3key === this.state.selectedItemS3Key }
      )

      const isCompact = ['VideoEmbed', 'WebArchive'].includes(file.type)

      return (
        <Col xs="12" md={ isCompact ? '12' : '6' } lg={ isCompact ? '6' : '2' } key={file.name}>
          <div
            className={ classes }
            onClick={ () => this.setState({ selectedItemS3Key: file.s3key }) }
          >
            {file.preview ? <img alt={file.name} className="img-fluid" src={file.preview} /> : <>{file.name}</>}
            { isCompact && <span>{ file.url }</span> }
            <FaTimes
              className="remove"
              size={ 28 }
              onClick={ () => this.removeItem(file.s3key) }
            />
          </div>
        </Col>
      );
    });

    return (
      <>
        <RollingHeader noMargin />

        { !isStandalone && (Object.keys(this.state.items)?.length
            ? <section className="mt-4">
                <h5>Items order</h5>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {Object.keys(this.state.items).map((itemsKey, index) => (
                          <Draggable key={this.state.items[itemsKey].details?.s3_key} draggableId={this.state.items[itemsKey].details?.s3_key} index={index}>
                            {(providedB, snapshotB) => (
                              <div
                                ref={providedB.innerRef}
                                {...providedB.draggableProps}
                                {...providedB.dragHandleProps}
                                style={getItemStyle(
                                  snapshotB.isDragging,
                                  providedB.draggableProps.style
                                )}
                                className="draggable-item"
                              >
                                <span style={{ paddingTop: '3px' }}>{this.state.items[itemsKey].details?.title}</span>
                                <div>
                                  { this.props.isAdmin && 
                                      <a
                                        href={'/admin/items/' + this.state.items[itemsKey].details?.id}
                                        target="_blank"
                                        className="btn btn--utility"
                                      >
                                        <MdEdit
                                          size={ 24 }
                                          color="#142636"
                                        />
                                      </a>
                                  }
                                  <button
                                    className="btn btn--utility"
                                    onClick={ () => this.props.callback?.(this.state.items[itemsKey].details?.s3_key, true) }
                                  >
                                    <FaTimes
                                      className="remove"
                                      size={ 28 }
                                      color="#dc3545"
                                    />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </section>
            : <div className="mt-4">No items yet</div>)
        }

        { this.props.centerSlot
            ? <section className="mt-4">{this.props.centerSlot}</section>
            : <></>
        }

        <section className={ isStandalone ? 'page-margin' : 'my-4'}>
        { isStandalone
            ? <h1 className="text-title text-uppercase text-center mt-4 mb-5 pb-2">〜  Enrich the ocean database</h1>
            : <h5 className="mb-3">Add new item</h5>
        }
          <FileUpload callback={this.fileUploadCallback} isAdmin={this.props.isAdmin} />

          { this.state.isNewItem && Object.keys(this.state.items).length
              ? <div className="mt-5 mb-5">
                  <h2 className="text-title text-uppercase pt-3 mb-3">2/ Add info & metadata</h2>
                  <Row className="preview item-thumbnails">
                    {thumbs}
                  </Row>
                </div>
              : <></>
          }
          { selectedItem && selectedItem.isLoading &&
            <div className="text-center mt-5">
              <Spinner style={{ width: '5rem', height: '5rem' }} />
            </div>
          }
          { selectedItem && selectedItem.details &&
              <ItemsDisplay
                isNewItem={this.state.isNewItem}
                isAdmin={this.props.isAdmin}
                isContributorPath={this.isContributorPath}
                key={selectedItem.details.s3_key}
                updateKey={this.state.updateKeyCount}
                s3Key={selectedItem.details.s3_key || ''}
                item={selectedItem}
                callback={this.fileUploadCallback}
                removeItem={this.props.allowRemoveItem ? this.removeItem : undefined}
                onDataCopy={ isBulkUpload ? this.copyDataToAllItems : undefined }
                dataToUpdate={ this.state.itemsToUpdate[selectedItem.details.s3_key] }
              />
          }
          {/* {
            Object.entries(this.state.items).map( ( [s3Key, item] ) => {
              return <ItemsDisplay
                isNewItem={this.state.isNewItem}
                isAdmin={this.props.isAdmin}
                isContributorPath={this.isContributorPath}
                key={s3Key}
                updateKey={this.state.updateKeyCount}
                s3Key={s3Key}
                item={item}
                callback={this.fileUploadCallback}
                removeItem={this.props.allowRemoveItem ? this.removeItem : undefined}
                onDataCopy={ isBulkUpload ? this.copyDataToAllItems : undefined }
                dataToUpdate={ this.state.itemsToUpdate[s3Key] }
              />
            })
          } */}
        </section>
      </>
    )
  }
}

export const Items = withRouter(ItemsClass);
ItemsClass.contextType = AuthContext;
