import {
  FETCH_STORY_LOADING,
  FETCH_STORY_SUCCESS,
} from '../../actions/story/viewStory';

type prevOrNextLink = {
  id: number;
  slug: string;
} | null;

export interface ViewStoryState {
  status: typeof FETCH_STORY_LOADING | typeof FETCH_STORY_SUCCESS;
  story: {
    id: number;
    title: string;
    html: string;
    excerpt: string;
    featured_image_url: string;
    concept_tags: Array<string>;
    keyword_tags: Array<string>;
    prev: prevOrNextLink;
    next: prevOrNextLink;
  };
}

const initialState: ViewStoryState = {
  status: FETCH_STORY_LOADING,
  story: {
    id: NaN,
    title: '',
    html: '',
    excerpt: '',
    featured_image_url: '',
    concept_tags: [],
    keyword_tags: [],
    prev: null,
    next: null
  },
};

export default (state: ViewStoryState = initialState, action): ViewStoryState => {
  if (state === undefined) {
    state = initialState;
  }
  switch (action.type) {
    case FETCH_STORY_LOADING:
      return {
        ...state,
        status: FETCH_STORY_LOADING,
      };

    case FETCH_STORY_SUCCESS:
      return {
        ...state,
        status: FETCH_STORY_SUCCESS,
        story: {
          id: action.payload.id,
          title: action.payload.title,
          html: action.payload.html,
          excerpt: action.payload.excerpt.replace(/{{{concept_tag:([a-zA-Z0-9_ ]*)}}}/g, '$1').replace(/{{{keyword_tag:([a-zA-Z0-9_ ]*)}}}/g, '$1'),
          concept_tags: action.payload.concept_tags,
          keyword_tags: action.payload.keyword_tags,
          featured_image_url: action.payload.featured_image_url,
          // Note that we display in reverse order, so
          /// when we use these, prev = next and next = prev.
          prev: action.payload.next,
          next: action.payload.prev,
        },
      };

    default:
      return state;
  }
};
