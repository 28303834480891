
import { RouteComponentProps, withRouter } from 'react-router';
import 'styles/components/pages/viewItem.scss';
import _ from 'lodash';
import ViewCollection from './ViewCollection';

type MatchParams = {
  id: string;
};

interface Props extends RouteComponentProps<MatchParams> {
  embedded?: boolean;
}

const ViewCollectionContainer = (props: Props): JSX.Element => {
  const { id } = props.match.params;
  return (
    <ViewCollection id={id} embedded={!!props.embedded} />
  );
};


export default withRouter(ViewCollectionContainer);
