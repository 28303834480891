import * as React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Container,
  Spinner,
  Modal,
  ModalBody, ModalFooter
} from 'reactstrap'

import { Item } from 'types/Item'
import { Collection } from '../../../types/Collection'
import { Announcement } from '../../../types/Announcement'

import { Alerts, ErrorMessage, SuccessMessage } from '../../utils/alerts'
import { FaCheck, FaExternalLinkAlt, FaTimes } from 'react-icons/fa'
import { adminGetItem, adminGetItems, contributorGetByPerson } from '../../../REST/items'
import { removeTopology } from '../../utils/removeTopology'
import { debounce, get } from 'lodash'
import { adminGet } from '../../../REST/collections'
import { API } from 'aws-amplify'
import Delete from './Delete'
import { CollectionEditor } from '../..'
import ItemEditor from '../../metadata/ItemEditor'
import { AnnouncementEditor } from '../../metadata/AnnouncementEditor'
import { connect } from 'react-redux'

import { doSearch, clearSearch } from 'actions/search'

import { clear as clearHistory } from 'actions/user-history'
import { SearchState } from 'reducers/search'
import { IoSearchSharp } from 'react-icons/io5'

import './AdminSearch.scss'
import { BsFillFlagFill } from 'react-icons/bs'
import { MdOutlinePreview, MdPreview } from 'react-icons/md'
import { collectionURL, itemURL } from 'urls'
import { withRouter, RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps {
  limit: number
  path: string
  searchState: SearchState
  doSearch: Function
  clearHistory: Function
  clearSearch: Function
  initialId?: string
}

interface State extends Alerts {
  results: Item[] | Collection[] | Announcement[]
  itemIndex?: number
  editResult?: Item | Collection | Announcement

  byField: string
  inputQuery: string

  paginationToken?: string | undefined
  page: number
  sizePerPage: number
  totalSize: number

  tableIsLoading: boolean
  componentModalOpen: boolean
  showSuggestions: boolean

  order?: string
}

class AdminSearch extends React.Component<Props, State> {
  searchInputRef
  tableColumns
  algoliaFilters
  isContributorPath
  isAdmin

  constructor(props: Props) {
    super(props)

    this.state = {
      errorMessage: undefined,
      successMessage: undefined,

      results: [],
      inputQuery: '',
      byField: 'Title',

      componentModalOpen: false,
      tableIsLoading: false,
      showSuggestions: false,

      page: 1,
      sizePerPage: 15,
      totalSize: 0,
      order: 'desc'
    }

    this.onDocumentClick = this.onDocumentClick.bind(this) // trick to make removeEventListener work

    this.searchInputRef = React.createRef()

    const style = { overflowWrap: 'break-word', wordWrap: 'break-word'  } 

    this.tableColumns = [
      {
        dataField: 's3_key',
        hidden: true
      },
      {
        dataField: 'id',
        hidden: true
      },
      {
        dataField: 'status',
        text: '',
        headerStyle: () => {
          return {...style, width: '120px'}
        },
        formatter: (status, row) => {
          return (
            <>
              <span>{ status && <FaCheck color="green" size={20} title="Published" /> }</span>
              <span>{ row.preview && <MdOutlinePreview color="#ef5d60" size={23} title="Preview" /> }</span>
              <span>{ row.oa_highlight && <BsFillFlagFill color="#0076ff" size={20} title="OA Highlight" /> }</span>
            </>
          )
        }
      },
      {
        dataField: 'title',
        text: 'Title',
        headerStyle: () => {
          return style
        },
        style: () => {
          return style
        },
      },
      {
        dataField: 'item_type',
        text: 'Type',
        headerStyle: () => {
          return {...style, width: '110px', whiteSpace: 'nowrap'}
        }
      },
      {
        dataField: 'created_at',
        text: 'Created Date',
        formatter: (cell: string) => {
          return ( new Date(cell).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) )
        },
        headerStyle: () => {
          return {...style, width: 'min-content', whiteSpace: 'nowrap'}
        },
      },
      {
        dataField: 'creators',
        formatter: (cell: string[]) => {
          return Array.isArray(cell) ?
              cell.join(', ')
              :
              ''
        }, headerStyle: () => {
          return style
        },
        style: () => {
          return style
        },
        hidden: this.isContributorPath || this.props.path !== 'items',
        text: 'Creator(s)'
      },
      {
        dataField: 'options',
        text: '',
        isDummyField: true,
        formatter: (e, row, rowIndex) => {
          let result: Item | Collection | Announcement = this.state.results[rowIndex] as Collection | Announcement
          let identifier = result.id
          const isVideo = 'item_type' in result && result.item_type === 'Video'
          const secondsSinceCreated = 'created_at' in result
            ? ( new Date().getTime() - new Date(result.created_at as any).getTime() ) / 1000
            : undefined
          if (this.props.path === 'items') {
            result = this.state.results[rowIndex] as Item
            identifier = result.s3_key
          }
          if (identifier && result) {
            return (
                <>
                  <a
                    href={ this.props.path === 'collections' ? collectionURL(result.id || '') : itemURL(result.id || '') }
                    target="_blank"
                    className="btn btn--plain mr-3"
                    title="Open"
                  >
                    <FaExternalLinkAlt size={23} color="#eeeeee" />
                  </a>
                  <Button color="warning" size="sm" className="mr-3" onClick={() => this.editResult(result)}>Edit</Button>
                  <Delete
                    path={this.props.path}
                    isContributorPath={this.isContributorPath}
                    index={rowIndex}
                    identifier={identifier}
                    callback={() => this.getResultsQuery()}
                    disabled={ isVideo && !!secondsSinceCreated && secondsSinceCreated < 15 }
                  />
                </>
            )
          } else {
            return <></>
          }
        },
        headerStyle: () => {
          return style
        },
      }
    ]
  }

  async componentDidMount() {
    this.isContributorPath = (this.props.location.pathname.match(/contributor/i))
    this.isAdmin = !!this.props.location.pathname.match(/admin/i)
    
    window.addEventListener('click', this.onDocumentClick)

    this.algoliaFilters = {
      creator: '',
      tags: '',
      fileType: '',
      regions: '',
      sortBy: 'newest',
      viewTypes: this.props.path === 'collections' ? 'collection' : 'item'
    }

    if (this.props.initialId) {
      await this.getResultsQuery(this.props.initialId)
      this.state.results.length && this.editResult(this.state.results[0])
    } else {
      this.getResultsQuery()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onDocumentClick)
  }

  onDocumentClick = (e: any) => {
    const allowedTargets = [ 'admin-search__input' ]
    this.setState({ showSuggestions: allowedTargets.includes(e.target.className) })
  }

  editResult = (result) => {
    if (result) {
      this.setState({ componentModalOpen: true, editResult: result })
    }
  }

  componentModalToggle = () => {
    this.setState( prevState => ({ ...prevState, componentModalOpen: !prevState.componentModalOpen }) )
  }

  onSuggestionClick = (suggestion) => {
    this.getResultsQuery(suggestion.id)
  }

  handleSearchInput = async (val: string) => {
    // Don't search for input shorter than 3 chars
    if (val.length < 3 && val.length > 0) return

    this.setState(prev => ({ ...prev, inputQuery: val, showSuggestions: true }))

    // If user has cleared the input, show the default results, otherwise do search
    val.length === 0
      ? this.props.clearSearch()
      : this.props.doSearch(val, this.algoliaFilters, true)
  }

  setSearchField = (event: React.MouseEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement
    this.setState({ byField: target.value })
  }

  // We get user input and check the path and put the results in to state
  getResultsQuery = async (id?: string, order?: string, offset?: number): Promise<{ results: Item[] | Collection[]; totalSize: number } | void> => {
    let path = this.props.path

    const inputQuery = this.state.inputQuery

    this.setState({ tableIsLoading: true, errorMessage: undefined, showSuggestions: false })

    const queryStringParameters = {}

    if (id || (this.state.byField === 'ID' && inputQuery)) {
      Object.assign(queryStringParameters, { id: id || inputQuery })
    } else {
      Object.assign(queryStringParameters, {
        limit: this.state.sizePerPage,
        order: order || this.state.order || 'desc',
        offset: offset === undefined ? (this.state.page - 1) * this.state.sizePerPage : offset
      })
      if (inputQuery) {
        Object.assign(queryStringParameters, { inputQuery })
      }
      if (inputQuery && this.state.byField) {
        Object.assign(queryStringParameters, { byField: this.state.byField })
      }
    }

    try {
      let results
      if (path === 'items') {
        const response = this.isContributorPath
          ? await contributorGetByPerson(queryStringParameters)
          : id || this.state.byField === 'ID' ? await adminGetItem(false, queryStringParameters) : await adminGetItems(queryStringParameters)
        results = removeTopology(response) as Item[]
      }
      if (path === 'collections') {
        const response = await adminGet(this.isAdmin, queryStringParameters)
        results = removeTopology(response) as Collection[]
      }
      if (path === 'announcements') {
        const response = await API.get('tba21', `${this.isContributorPath ? 'contributor' : 'admin'}/announcements`, { queryStringParameters: queryStringParameters })
        results = response.announcements.map(a => ({ ...a, created_at: new Date(a.created_at).toISOString().substr(0, 10) }))
      }
      
      if (results && results.length) {
        this.setState({
          tableIsLoading: false,
          errorMessage: undefined,
          results: results,
          totalSize: results[0] && results[0].count ? (typeof results[0].count === 'string' ? parseInt(results[0].count, 10) : results[0].count) : 0
        })

        return results
      } else { // if we have no results, set an error message
        this.setState({
          tableIsLoading: false,
          errorMessage: 'No results found, please try again',
          results: []
        })
      }
    } catch (error) {
      this.setState({
        tableIsLoading: false,
        errorMessage: 'Please try again',
        results: []
      })
    }
  }

  handleTableChange = async (type, { page, sizePerPage }): Promise<void> => {
    if (type === 'pagination') {
      const offset = (page - 1) * sizePerPage
      this.setState({ tableIsLoading: true })
      console.log(offset, page, sizePerPage);

      try {
        const response = await this.getResultsQuery(undefined, this.state.order || 'desc', offset)

        if (response) {
          this.setState({
            errorMessage: undefined,
            page,
            sizePerPage,
            // items: response.items,
            tableIsLoading: false
          })
        }

      } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.setState({page: this.state.page - 1, errorMessage: 'We\'ve had some trouble getting the list of items.', tableIsLoading: false})
      }
    }
  }

  render() {
    const
        { page, sizePerPage, totalSize } = this.state,
        results = this.state.results,
        path = this.props.path
    
    return (
      <Container>
        <ErrorMessage message={this.state.errorMessage}/>
        <SuccessMessage message={this.state.successMessage}/>
        <FormGroup name="search" className="admin-search">
          <div className="admin-search__bar mb-5">
            <div className="admin-search__fields">
              <UncontrolledDropdown addonType="prepend" type="select" name="searchBy" id="searchBy">
                <DropdownToggle caret>{this.state.byField}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.setSearchField} value="Title">Title</DropdownItem>
                  { this.props.path === 'announcements' ? <></> :
                    <DropdownItem onClick={this.setSearchField} value="Creator">Creator</DropdownItem>
                  }
                  <DropdownItem onClick={this.setSearchField} value="ID">ID</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <input
                className="admin-search__input"
                type="text"
                placeholder="Start typing to search"
                onChange={ debounce( (e) => this.handleSearchInput(e.target.value), 300) }
                onKeyUp={ (e) => e.key === 'Enter' && this.getResultsQuery() }
              />
              <button className="admin-search__submit btn btn--plain" onClick={ () => this.getResultsQuery() }>
                <IoSearchSharp className="admin-search__fields-icon" size="26px" color="#eeeeee" />
              </button>
            </div>

            { this.props.searchState.searchHits?.length && this.state.showSuggestions && (
              <div className="admin-search__results">
                { this.props.searchState.searchHits?.map((it, i) => i < 10 && (
                  <div
                    className="admin-search__result"
                    key={ it.id }
                    onClick={ () => this.onSuggestionClick(it) }
                  >
                    <span>{ it.title }</span>
                    <span>{ new Date(it.created_at).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }</span>
                  </div>
                )) }
              </div>
            ) || <></> }
          </div>

          {
            this.state.results.length ?
              <div className="admin-search__table">
                <BootstrapTable
                  remote
                  bootstrap4
                  className="itemTable"
                  keyField="s3_key"
                  bordered={false}
                  striped={false}
                  data={this.state.tableIsLoading ? [] : results}
                  columns={this.tableColumns}
                  onTableChange={this.handleTableChange}
                  pagination={paginationFactory({ page, sizePerPage, totalSize })}
                />
              </div>
            : <></>
          }
          { this.state.tableIsLoading ?
            <div  className="mx-auto" style={{ width: '5rem', height: '5rem' }}>
              <Spinner style={{ width: '5rem', height: '5rem' }} type="grow" />
            </div> : <></>
          }
          {
            this.state.paginationToken ?
              <Button  color="primary" size="lg">
                Load More &nbsp 
              </Button>
              : <></>
          }
        </FormGroup>

        {/* Start Modals*/}
          <Modal isOpen={this.state.componentModalOpen} centered size="lg" scrollable backdrop className="fullwidth blue theme-dark">
            <ModalBody>
              {
                path === 'items' && this.state.editResult ?
                    <ItemEditor
                        item={this.state.editResult as Item}
                        isAdmin={this.isAdmin}
                    />
                    : <></>
              }
              {
                path === 'collections' && this.state.editResult ?
                    <CollectionEditor
                        editMode={true}
                        collection={this.state.editResult as Collection}
                        isAdmin={this.isAdmin}
                    />
                    : <></>
              }
              {
                path === 'announcements' && this.state.editResult ?
                    <AnnouncementEditor
                        editMode={true}
                        announcement={this.state.editResult as Announcement}
                        path={path}
                    />
                    : <></>
              }
            </ModalBody>
            <ModalFooter>
              <Button className="mr-auto" color="secondary" onClick={this.componentModalToggle}>Close</Button>
            </ModalFooter>
          </Modal>
        {/* End Modals*/}

      </Container>
    )
  }
}

const mapStateToProps = (state: { search: SearchState }) => ({
  searchState: state.search
})

export default connect(mapStateToProps, { doSearch, clearHistory, clearSearch })(withRouter(AdminSearch))
