import * as React from 'react'
import { API } from 'aws-amplify'
import { Button, CustomInput, Input } from 'reactstrap'

import './Settings.scss'
import { IoClose } from 'react-icons/io5'

export type NewsflashSettingsData = {
  text: string
  link: string
  countdownDate: string
  enabled: boolean
}

interface Props {
  data: NewsflashSettingsData | null
  onError?: Function
  onSuccess?: Function
}

interface State {
  data: NewsflashSettingsData | null
}

class NewsflashSettings extends React.Component<Props, State> {
  carouselTableCols

  constructor(props: Props) {
    super(props)

    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    this.setState({ data: this.props.data })
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.data && this.props.data) {
      this.setState({ data: this.props.data })
    }
  }

  setText(text: string) {
    const data = { ...this.state.data } as NewsflashSettingsData
    this.setState({ data: Object.assign(data, { text }) })
  }

  setLink(link: string) {
    const data = { ...this.state.data } as NewsflashSettingsData
    this.setState({ data: Object.assign(data, { link }) })
  }

  setDate(date: string | null) {
    const data = { ...this.state.data } as NewsflashSettingsData
    this.setState({ data: Object.assign(data, { countdownDate: date || null }) })
  }

  setEnabled(enabled: boolean) {
    const data = { ...this.state.data } as NewsflashSettingsData
    this.setState({ data: Object.assign(data, { enabled }) })
  }

  async saveNewsflashSettings() {
    const result = await API.put('tba21', 'admin/settings/update', { body: {
        name: 'newsflash',
        values: JSON.stringify(this.state.data)
      }
    })

    result.success
      ? this.props.onSuccess?.('Newsflash settings saved successfully')
      : this.props.onError?.('Failed to save newsflash settings')
  }

  render() {
    return (
      <>
        <div className="mt-5">
          <h5>Banner text</h5>
          <Input
            type="text"
            onChange={ e => this.setText(e.target.value) }
            value={ this.state.data?.text }
          />
        </div>

        <div className="mt-4">
          <h5>Banner link</h5>
          <Input
            type="url"
            onChange={ e => this.setLink(e.target.value) }
            value={ this.state.data?.link }
          />
        </div>

        <div className="mt-4">
          <h5>Countdown date and time</h5>
          <div className="flex justify-content-between">
            <div className="flex-grow-1">
              <Input
                type="datetime-local"
                onChange={ e => this.setDate(e.target.value) }
                value={ this.state.data?.countdownDate || '' }
              />
            </div>
            { this.state.data?.countdownDate && (
              <Button
                className="btn--plain px-2 ml-2"
                title="Remove item"
                onClick={ () => this.setDate(null) }
                color="danger"
                outline
              >
                <IoClose size="24" />
              </Button>
            ) }
          </div>
          <p className="text-help mt-1">Enter UTC date and time</p>
        </div>

        <div className="mt-4">
          <div className="flex">
            <h5>Enabled</h5>
            <div className="ml-3" style={{ top: '-2px', position: 'relative' }}>
              <CustomInput
                type="switch"
                id="newsflash-enabled"
                checked={ this.state.data?.enabled }
                onChange={e => this.setEnabled(e.target.checked) }
              />
            </div>
          </div>
        </div>

        <div className="mt-4 text-right">
          <Button onClick={ () => this.saveNewsflashSettings() }>Save</Button>
        </div>
      </>
    )
  }
}

export default NewsflashSettings
