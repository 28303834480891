import { useEffect, useState, Children, cloneElement, ReactNode } from 'react'
import classNames from 'classnames/dedupe'

interface Props {
  show: boolean
  children?: ReactNode
  duration?: number
}

const Transition = ({ show, children, duration = 300 }: Props) => {
  const [state, setState] = useState<string | boolean>(false)

  useEffect(() => {
    setState(show ? 'enter-from' : 'leave-to')
    setTimeout(() => setState(show), show ? 20 : duration)
  }, [show])

  if (!state) return <></>

  const childrenClones = Children.map(children, (child: any) => cloneElement(child, {
      ...child.props,
      className: classNames(child.props.className, state)
    })
  )

  return <>{ childrenClones }</>
}

export default Transition