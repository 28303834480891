export enum Languages {
  AR = 'Arabic',
  BG = 'Bulgarian',
  CA = 'Catalan',
  ZH = 'Hans Chinese, Han',
  CS = 'Czech',
  DA = 'Danish',
  DE = 'German',
  EL = 'Modern Greek',
  EN = 'English',
  ES = 'Spanish',
  FI = 'Finnish',
  FR = 'French',
  HE = 'Hebrew',
  HU = 'Hungarian',
  IS = 'Icelandic',
  IT = 'Italian',
  JA = 'Japanese',
  KO = 'Korean',
  NL = 'Dutch',
  NO = 'Norwegian',
  PL = 'Polish',
  PT = 'Portuguese',
  RM = 'Romansh',
  RO = 'Romanian',
  RU = 'Russian',
  HR = 'Croatian',
  SK = 'Slovak',
  SQ = 'Albanian',
  SV = 'Swedish',
  TH = 'Thai',
  TR = 'Turkish',
  UR = 'Urdu',
  ID = 'Indonesian',
  UK = 'Ukrainian',
  BE = 'Belarusian',
  SL = 'Slovenian',
  ET = 'Estonian',
  LV = 'Latvian',
  LT = 'Lithuania',
  TG = 'Tajik',
  FA = 'Persian',
  VI = 'Vietnamese',
  HY = 'Armenian',
  AZ = 'Azerbaijani',
  EU = 'Basque',
  HSB = 'Upper Sorbian',
  MK = 'Macedonian',
  TN = 'Tswana',
  XH = 'Xhosa',
  ZU = 'Zulu',
  AF = 'Afrikaans',
  KA = 'Georgian',
  FO = 'Faroese',
  HI = 'Hindi',
  MT = 'Maltese',
  SE = 'Northern Sami',
  GA = 'Irish',
  MS = 'Malay',
  KK = 'Kazakh',
  KY = 'irghiz',
  SW = 'Swahili',
  TK = 'Turkmen',
  UZ = 'Uzbek',
  TT = 'Tatar',
  BN = 'Bengali',
  PA = 'Panjabi',
  GU = 'Gujarati',
  OR = 'Oriya',
  TA = 'Tamil',
  TE = 'Telugu',
  KN = 'Kannada',
  ML = 'Malayalam',
  AS = 'Assamese',
  MR = 'Marathi',
  SA = 'Sanskrit',
  MN = 'Mongolian',
  BO = 'Tibetan',
  CY = 'Welsh',
  KM = 'Central Khmer',
  LO = 'Lao',
  GL = 'Galician',
  KOK = 'Konkani',
  SYR = 'Syriac',
  SI = 'Sinhala',
  IU = 'Inuktitut',
  AM = 'Amharic',
  TZM = 'Central Atlas Tamazight',
  NE = 'Nepali',
  FY = 'Western Frisian',
  PS = 'Pushto',
  FIL = 'Filipino',
  DV = 'Dhivehi',
  HA = 'Hausa',
  YO = 'Yoruba',
  QUZ = 'Cusco Quechua',
  NSO = 'Pedi',
  BA = 'Bashkir',
  LB = 'Luxembourgish',
  KL = 'Kalaallisut',
  IG = 'Igbo',
  II = 'Sichuan Yi',
  ARN = 'Mapudungun',
  MOH = 'Mohawk',
  BR = 'Breton',
  UG = 'Uighur',
  MI = 'Maori',
  OC = 'Occitan',
  CO = 'Corsican',
  GSW = 'Swiss German',
  SAH = 'Yakut',
  QUT = 'Guatemala',
  RW = 'Kinyarwanda',
  WO = 'Wolof',
  PRS = 'Dari',
  GD = 'Scottish Gaelic'
}
