const RipplesLayoutSvg = ({ name }: { name: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="127" height="63" fill="none" viewBox="0 0 127 63">
      { name === 'one-only' && <>
        <path stroke="currentColor" d="M.5.5h125.86v61.72H.5V.5Z"/>
      </> }

      { name === 'one-by-one' && <>
        <path stroke="currentColor" d="M.57.5H63.7v61.72H.57V.5Z"/>
        <path stroke="currentColor" d="M63.29.5h63.14v61.72H63.3V.5Z"/>
      </> }

      { name === 'one-by-two' && <>
        <path stroke="currentColor" d="M.57.5H63.7v61.72H.57V.5Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85ZM63.29.5h63.14v30.35H63.3V.5Z"/>
      </> }

      { name === 'two-by-two' && <>
        <path stroke="currentColor" d="M.57.5H63.7v61.72H.57V.5Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85Z"/>
        <path stroke="currentColor" d="M.57 30.85H63.7v31.37H.57V30.85ZM63.29.5h63.14v30.35H63.3V.5Z"/>
        <path stroke="currentColor" d="M.57.5H63.7v30.35H.57V.5Z"/>
      </> }

      { name === 'one-by-four' && <>
        <path stroke="currentColor" d="M.57.5H63.7v61.72H.57V.5Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85ZM63.29.5h63.14v30.35H63.3V.5Z"/>
        <path stroke="currentColor" d="M94.64 30.85h31.79v31.37H94.64V30.85ZM94.64.5h31.79v30.35H94.64V.5Z"/>
      </> }

      { name === 'two-by-four' && <>
        <path stroke="currentColor" d="M.57.5H63.7v61.72H.57V.5Z"/>
        <path stroke="currentColor" d="M.57 30.85H63.7v31.37H.57V30.85Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85ZM.57.5H63.7v30.35H.57V.5Z"/>
        <path stroke="currentColor" d="M63.29.5h63.14v30.35H63.3V.5ZM94.65 30.85h31.78v31.37H94.65V30.85Z"/>
        <path stroke="currentColor" d="M94.65.5h31.78v30.35H94.65V.5Z"/>
      </> }

      { name === 'four-by-four' && <>
        <path stroke="currentColor" d="M31.93.5H63.7v30.35H31.93V.5ZM94.64.5h31.79v30.35H94.64V.5ZM31.93 30.85H63.7v31.37H31.93V30.85ZM94.64 30.85h31.79v31.37H94.64V30.85Z"/>
        <path stroke="currentColor" d="M63.29.5h63.14v30.35H63.3V.5ZM.57 30.85H63.7v31.37H.57V30.85Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85Z"/>
        <path stroke="currentColor" d="M.57 30.85H63.7v31.37H.57V30.85Z"/>
        <path stroke="currentColor" d="M63.29 30.85h63.14v31.37H63.3V30.85Z"/>
        <path stroke="currentColor" d="M.57 30.85H63.7v31.37H.57V30.85Z"/>
        <path stroke="currentColor" d="M.57.5H63.7v61.72H.57V.5Z"/>
      </> }
    </svg>
  )
}

export default RipplesLayoutSvg