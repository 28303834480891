import { FileTypes } from 'types/s3File'
import { Item, itemType } from '../../types/Item'
import classnames from 'classnames'

import { plainText } from 'components/utils/textUtils'
import { Link } from 'react-router-dom'
import { itemURL } from 'urls'

import './ItemSimpleView.scss'

const ItemPreview = ({ item, openInNewTab = true, className }: { item: Item, openInNewTab?: boolean, className?: string }) => {
  const {
    id,
    file,
    title,
    item_type,
    description
  } = item

  const isAudio =
    (!!file && item_type === itemType.Audio) ||
    (!!file && file.type === FileTypes.Audio)

  const plainDescription = plainText(description)
  const creators = item.creators || []
  const concept_tags = item.concept_tags || []
  const yearProduced = item.year_produced || ( item.time_produced && new Date(item.time_produced).getFullYear() ) || ''

  return (
    <>
      { item_type === itemType.Image && (
        <div className={ classnames('item-simple', className ) }>
          <div className="item-simple__preview">
            <img src={ file.url } />
          </div>
          <div className="item-simple__body">
            <h3 className="item-simple__title">
              <Link
                to={ itemURL(`${id}`) }
                dangerouslySetInnerHTML={{ __html: title || '' }}
                target={ openInNewTab ? '_blank' : '_self' }
              />
            </h3>
            <span className="item-simple__meta" dangerouslySetInnerHTML={{ __html: [ ...creators, yearProduced ].join(', ') }} />
            <p className="item-simple__description" dangerouslySetInnerHTML={{ __html: plainDescription.substring(0, 800) }} />
            {/* { !noTags && (
              <div className="search-results__item-tags">
                { concept_tags?.map(tag => (
                  <Button
                    key={ tag }
                    className={ classnames('page-link tag d-inline-block', { active: searchState.filters.tags === tag }) }
                    style={{ padding: 0, marginBottom: 10 }}
                    dangerouslySetInnerHTML={{ __html: '#' + tag }}
                    onClick={ () => onTagClick?.(tag) }
                  />
                )) }
              </div>
            ) } */}
          </div>
        </div>
      ) }
    </>
  )
}

export default ItemPreview