import { API } from 'aws-amplify'

export const startCrawl = async (itemId: string) => {
  const response = await API.post('tba21', 'admin/webrecorder/startCrawl', {
    body: { item_id: itemId }
  })

  return !!response.success
}

export const getCrawlStatusByItemId = async (itemId: string) => {
  const response = await API.get('tba21', 'admin/webrecorder/getStatusByItemId', {
    queryStringParameters: { item_id: itemId }
  })

  return response.status
}
