 /* eslint-disable */
import React from 'react';
import { useRef, useEffect, useState } from 'react';
import 'styles/components/story.scss';
import {
  FETCH_STORY_LOADING,
  FETCH_STORY_SUCCESS,
} from '../../actions/story/viewStory';
import { ViewStoryState } from '../../reducers/story/viewStory';
import { connect } from 'react-redux';
import { fetchStory } from '../../actions/story/viewStory';
import {
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  Nav,
  NavbarToggler,
  Collapse,
  NavItem
} from 'reactstrap';
import useStoryTheme from 'hooks/useStoryTheme';
import { NavLink } from 'react-router-dom';

import {
  addUserFavourite,
  deleteUserFavourite,
} from '../../actions/user/profile';
import { ProfileState } from '../../reducers/user/profile';
import { TiStar, TiStarOutline } from 'react-icons/ti';
import $ from 'jquery';
import { throttle } from 'lodash';
import Share from 'components/utils/Share';
import { searchAndOpen as dispatchSearchConsole } from 'actions/searchConsole';
import { createCriteriaOption } from '../search/SearchConsole';
import useBodyClass from 'hooks/useBodyClass';
import { storyURL } from '../../urls';
import Footer from 'components/layout/Footer';
import { BsCaretDownFill } from 'react-icons/bs';

import { setTheme } from 'actions/global'
import ThemeSwitch from 'components/utils/ThemeSwitch';

type ViewStory = ViewStoryState['story'] & {
  status: typeof FETCH_STORY_SUCCESS | typeof FETCH_STORY_LOADING;
  favouriteStatus: 'LOADING' | 'FAVOURITED' | 'IDLE';
  dispatchSearchConsole: Function;
};

type ViewStoryWithMatch = ViewStory & {
  fetchStory: Function;
  addUserFavourite: Function;
  deleteUserFavourite: Function;
  setTheme: Function;
  match: {
    params: { slug: string };
  };
};

type ViewStorySection = { key: string; level: number; title: string };

const HEADER_TAGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
const MAIN_HEADER_ID = 'MAIN_HEADER';
const SUBHEADING_CLASS = 'story-content__subheading';

let CONCEPT_TAG_CAPTURE_GROUP = /{{{concept_tag:(.*?)}}}/;
let KEYWORD_TAG_CAPTURE_GROUP = /{{{keyword_tag:(.*?)}}}/;
let CONCEPT_TAG_OR_KEYWORD_TAG_REGEX =
  /({{{concept_tag:.*?}}}|{{{keyword_tag:.*?}}})/g;

const ViewStory: React.FC<ViewStoryWithMatch> = ({
  id,
  match,
  title,
  html,
  excerpt,
  featured_image_url,
  keyword_tags,
  concept_tags,
  status,
  fetchStory,
  addUserFavourite,
  deleteUserFavourite,
  favouriteStatus,
  dispatchSearchConsole,
  prev,
  next,
  setTheme
}) => {
  let [sections, setSections] = useState<ViewStorySection[]>([
    { key: MAIN_HEADER_ID, level: 1, title },
  ]);
  let [activeSection, setActiveSection] = useState(sections[0]);
  let [manipulatedHTML, setManipulatedHTML] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  useEffect(() => {
    fetchStory(match.params.slug)
    setTheme('light')
  }, [fetchStory, match.params.slug, title]);

  useBodyClass('story-page');

  useEffect(() => {
    if (!html) {
      return;
    }
    let temporarySections: ViewStorySection[] = [
      { key: MAIN_HEADER_ID, level: 1, title },
    ];
    let $parsedHTML = $.parseHTML(html);
    $parsedHTML.forEach((node) => {
      let $node = $(node);
      let tagName = $node.prop('tagName');
      let isHeader = HEADER_TAGS.includes(tagName);

      $node.each((i, v) => {
        let pNodeText = String($(v).html());
        let splittedText = pNodeText.split(CONCEPT_TAG_OR_KEYWORD_TAG_REGEX);

        if (splittedText.length > 1) {
          $(v).empty();
          splittedText.forEach((text) => {
            let conceptTagGroup = text.match(CONCEPT_TAG_CAPTURE_GROUP);
            let keywordTagGroup = text.match(KEYWORD_TAG_CAPTURE_GROUP);
            if (conceptTagGroup != null) {
              let conceptTag = conceptTagGroup[1];
              let $span = $('<span>')
                .text(`#${conceptTag}`)
                .addClass('concept_tag')
                .attr('data-concept-tag', conceptTag);
              $(v).append($span);
            } else if (keywordTagGroup != null) {
              let keywordTag = keywordTagGroup[1];
              let $span = $('<span>')
                .text(`#${keywordTag}`)
                .addClass('keyword_tag')
                .attr('data-keyword-tag', keywordTag);
              $(v).append($span);
            } else {
              $(v).append($('<span>').html(text));
            }
          });
        }
      });

      // append ID to each heading
      if (isHeader) {
        let text = $node.text();
        // unique identifier
        let key = [tagName, text.replace(/ /g, '_')].join('_');
        // append ID to each heading tag[]
        $node.attr('id', key);
        $node.addClass(SUBHEADING_CLASS);
        temporarySections.push({
          key,
          level: parseInt(tagName.split('')[1], 10),
          title: text,
        });
      }
    });

    const description = document.querySelector('meta[name=\'description\']');
    description!.setAttribute('content', excerpt);
    const ogImage = document.querySelector('meta[name=\'og:image\']');
    ogImage!.setAttribute('content', featured_image_url);
    const docTitle = document.querySelector('title');
    docTitle!.innerHTML = title;
    if (concept_tags && concept_tags.length > 0) {
      const keywords = document.querySelector('meta[name=\'keywords\']');
      let keyword_list = concept_tags.join(', ');
      if (keyword_tags && keyword_tags.length > 0) {
        keyword_list += ', ' + keyword_tags.join(', ');
      }
      keywords!.setAttribute('content', keyword_list);
    }

    setManipulatedHTML($('<div>').append($parsedHTML).html());
    setSections(temporarySections);
    setActiveSection(temporarySections[0]);
  }, [html, title, excerpt, featured_image_url, dispatchSearchConsole]);

  useEffect(() => {
    $('body').on('click', '.concept_tag', function () {
      let conceptTag = $(this).data('concept-tag');
      dispatchSearchConsole(
        [createCriteriaOption(conceptTag, 'concept_tag')],
        false,
        false,
        false
      );
    });

    $('body').on('click', '.keyword_tag', function () {
      let keywordTag = $(this).data('keyword-tag');
      dispatchSearchConsole(
        [createCriteriaOption(keywordTag, 'keyword_tag')],
        false,
        false,
        false
      );
    });

    $('body').on('click', function (e) {
      if (
        $(e.target).parent('.modern-footnotes-footnote').length > 0 ||
        $(e.target).parent('.modern-footnotes-footnote__note').length > 0
      ) {
        return;
      }
      $('.modern-footnotes-footnote__note').removeClass('active');
    });

    $('body').on('click', '.modern-footnotes-footnote', function () {
      let dataMfn = $(this).data('mfn');
      if (dataMfn > 0) {
        $(`.modern-footnotes-footnote__note[data-mfn=${dataMfn}]`).toggleClass(
          'active'
        );
      }
    });

    return () => {
      $('body').off();
    };
  }, [dispatchSearchConsole]);

  useEffect(() => {
    const scrollHandler = () => {
      if (!wrapperRef.current) {
        return;
      }

      let elements = $(wrapperRef.current).find(`.${SUBHEADING_CLASS}`);
      let nearestHeadingElement: HTMLElement | null = null;
      let iter = 0;
      while (iter < elements.length) {
        let element = elements[iter];
        let rect = element.getBoundingClientRect();
        // 12px is arbitary buffer for sensitivity
        if (rect.top >= 12) {
          // default to first element heading if it's not scrolled yet
          if (iter === 0) {
nearestHeadingElement = element; 
}
          break;
        }
        nearestHeadingElement = element;
        iter++;
      }

      if (nearestHeadingElement != null) {
        let activeSection = sections.find(
          (section) => section.key === nearestHeadingElement?.getAttribute('id')
        );

        if (activeSection) {
          setActiveSection(activeSection);
        }
      }
    };
    const throttledScrollHandler = throttle(scrollHandler, 250);
    document.addEventListener('scroll', throttledScrollHandler);
    return () => document.removeEventListener('scroll', throttledScrollHandler);
  }, [sections]);

  return (
    <div className="story">
      {status === FETCH_STORY_LOADING && (
        <div className="story-spinner-wrapper">
          <Spinner />
        </div>
      )}
      {status === FETCH_STORY_SUCCESS && (
        <>
          <ViewStoryHeader
            activeSection={activeSection}
            sections={sections}
            favouriteStatus={favouriteStatus}
            id={id}
            addUserFavourite={addUserFavourite}
            deleteUserFavourite={deleteUserFavourite}
            isBurgerOpen={isBurgerOpen}
            setIsBurgerOpen={setIsBurgerOpen}
          />
          <div className="story-content" ref={wrapperRef}>
            <h1
              className={SUBHEADING_CLASS}
              id={MAIN_HEADER_ID}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div dangerouslySetInnerHTML={{ __html: manipulatedHTML }} />
          </div>
          <div className="story-navigation">
            {prev ? (<a href={storyURL(prev.slug)}><span className="amare-arrow-fix">{'<< '}</span>{'PREVIOUS JOURNEY'}</a>) : <span />}
            {next ? (<a href={storyURL(next.slug)}>{'NEXT JOURNEY'}<span className="amare-arrow-fix">{' >>'}</span></a>) : <span />}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

const ViewStoryHeader = ({
  activeSection,
  sections,
  favouriteStatus,
  id,
  addUserFavourite,
  deleteUserFavourite,
  isBurgerOpen,
  setIsBurgerOpen
}) => (
  <Navbar expand="sm" className={'story-header'} dark>
    <ViewStoryBreadcrumb activeSection={activeSection} sections={sections} />
      <Nav className="ml-auto float-right" style={{alignItems: 'center', flexDirection: 'row'}} navbar>
    {sections.length > 1 && (
      <NavItem>
        <div className="section-dropdown-toggle-mobile header-button-container">
          <SectionsDropdownToggle sections={sections} isMobile={true}>
            <BsCaretDownFill size={22} color="inherit" />
          </SectionsDropdownToggle>
        </div>
      </NavItem>)}
      <NavItem>
      <div className="flex-vertical-center header-button-container">
        {favouriteStatus === 'FAVOURITED' && (
          <TiStar
            size={28}
            color="#50E2C1"
            style={{cursor: 'pointer'}}
            onClick={() => {
              deleteUserFavourite('stories', id);
            }}
          />
        )}
        {favouriteStatus === 'LOADING' && <Spinner size="sm" />}
        {favouriteStatus === 'IDLE' && (
          <TiStarOutline
            size={28}
            style={{cursor: 'pointer'}}
            color="#50E2C1"
            onClick={() => {
              addUserFavourite('stories', id);
            }}
          />
        )}
      </div>
      </NavItem>
      <NavItem>
        <div className="header-button-container">
        <Share variant="fullText" text={String(window.location)} size={18} />
        </div>
      </NavItem>
      <NavItem className="ml-2">
        <ThemeSwitch />
      </NavItem>
      </Nav>
    {/* <Collapse isOpen={isBurgerOpen} navbar style={{flexBasis: '0%'}}>
      </Collapse>
      <NavbarToggler
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsBurgerOpen(!isBurgerOpen);
                  }}
    > <BsThreeDotsVertical color="inherit" size={30} /> </NavbarToggler> */}
  </Navbar>
);

type ViewStoryBreadcrumbProps = {
  activeSection: ViewStorySection;
  sections: ViewStorySection[];
};

const darkenBackground = (isMobile: boolean) => {
  if (isMobile) {
    if (!$('#shadow').length) {
      $(document.body).append('<div id=\'shadow\' style=\'position:fixed;left:0px;top:46px;width:100%; height:100%; background:#142636; opacity: 0\'></div>');
      $('#shadow').on('click', function() {
darkenBackground(true);
});
    }
    if ($('#shadow').css('opacity') === '0') {
      /* show it */
      $('#shadow').fadeTo(200, 0.2);
    } else {
      /* hide it */
      $('#shadow').fadeTo(200, 0);
      $('#shadow').remove();
    }
  }
};

const SectionsDropdownToggle = ({ children, sections , isMobile}) => (
  <UncontrolledDropdown>
    <DropdownToggle nav caret 
      className="flex-vertical-center"
      onClick={(e) => darkenBackground(isMobile)}
    >
      {children}
    </DropdownToggle>
    {sections.length > 1 ? (
      <DropdownMenu
        style={{
          maxHeight: '28rem',
          maxWidth: '30rem',
          minWidth: '50vw',
          border: 0,
          overflowY: 'scroll',
          color: 'inherit',
        }}
        right={isMobile}
        onClick={(e) => $('#shadow').fadeTo(200, 0)}
      >
        {sections.slice(isMobile ? 0 : 1).map((section) => (
          <a key={section.key} href={`#${section.key}`}>
            <DropdownItem
              style={{
                backgroundColor: 'inherit',
                color: 'inherit',
                paddingLeft: 0,
              }}
            >
              <span
                className="mobile-section-wrap"
                dangerouslySetInnerHTML={{
                  __html: `>&nbsp;&nbsp;${section.title}`,
                }}
                style={{
                  marginLeft: `${Math.floor(section.level - 2) * 1}rem`,
                }}
              />
            </DropdownItem>
          </a>
        ))}
      </DropdownMenu>
    ) : (
      <></>
    )}
  </UncontrolledDropdown>
);

const ViewStoryBreadcrumb: React.FC<ViewStoryBreadcrumbProps> = ({
  activeSection,
  sections,
}) => {
  return (
    <div className="story-breadcrumb">
      <span>Ocean Archive</span>
      <span className="amare-arrow-fix">{'>'}</span>
      <NavLink to="/stories">Journeys</NavLink>
      <span>
        {sections.length === 1 ? (<><span className="amare-arrow-fix" style={{ marginRight: '0.7rem'}}>{' > '}</span><span dangerouslySetInnerHTML={{ __html: sections[0].title }} /></>) : (<></>)}
        {sections.length > 1 ? (<span className="amare-arrow-fix" style={{ marginLeft: '0.7rem'}}>{' > '}</span>) : <></>}
      </span>
      {sections.length > 1 ? (
      <SectionsDropdownToggle sections={sections} isMobile={false}>
        <span dangerouslySetInnerHTML={{ __html: activeSection.title }} />
      </SectionsDropdownToggle>) : <></>}
    </div>
  );
};

const mapStateToProps = (
  state: { viewStory: ViewStoryState; profile: ProfileState },
  props: ViewStory
): Omit<ViewStory, 'dispatchSearchConsole'> => {
  const isFavourited = (
    (state.profile &&
      state.profile.details &&
      state.profile.details.favourites &&
      state.profile.details.favourites.stories) ||
    []
  ).includes(state.viewStory.story.id)
    ? 'FAVOURITED'
    : 'IDLE';
  return {
    status: state.viewStory.status,
    ...state.viewStory.story,
    favouriteStatus: state.profile.favouriteIsLoading
      ? 'LOADING'
      : isFavourited,
  };
};

export default connect(mapStateToProps, {
  fetchStory,
  addUserFavourite,
  deleteUserFavourite,
  dispatchSearchConsole,
  setTheme
})(ViewStory);
