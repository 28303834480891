import * as React from 'react'
import { API, Storage } from 'aws-amplify'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Input, Container, Spinner, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import { HiPlus, HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi'
import { BsTrash } from 'react-icons/bs'
import { v4 as uuidv4 } from 'uuid'

import { ErrorMessage, SuccessMessage } from 'components/utils/alerts'
import config from 'config'

import './Settings.scss'
import CarouselSettings, { CarouselTableData } from './CarouselSettings'
import NewsflashSettings, { NewsflashSettingsData } from './NewsflashSettings'
import TabSwitch from 'components/utils/TabSwitch'

interface State {
  loading: boolean
  carouselTableData: CarouselTableData
  newsflashData: NewsflashSettingsData | null,
  errorMessage: string
  successMessage: string,
  activeTab: string,
  exporting: string
}

class Settings extends React.Component<RouteComponentProps, State> {
  carouselTableCols

  constructor(props: RouteComponentProps) {
    super(props)

    this.state = {
      loading: true,
      carouselTableData: [],
      newsflashData: null,
      errorMessage: '',
      successMessage: '',
      activeTab: 'carousel',
      exporting: ''
    }
  }

  async componentDidMount() {
    const settings = await API.get('tba21', 'admin/settings', {})
    this.setState(prev => ({
      ...prev,
      loading: false,
      carouselTableData: settings.home_carousel_items,
      newsflashData: settings.newsflash
    }))
  }

  async doExport(contentType: string) {
    this.setState({ exporting: contentType })
    const res = await API.get('tba21', 'admin/settings/export/' + contentType, {})

    if (res && res.file) {
      const url = config.urls.TEMP_URL + res.file
      window.location.href = url
    } else {
      this.setState({ errorMessage: 'Export failed' })
    }

    this.setState({ exporting: '' })
  }

  render() {
    return (
      <Container className="admin-settings pt-3">
        { this.state.errorMessage && <ErrorMessage message={this.state.errorMessage} sticky /> }
        { this.state.successMessage && <SuccessMessage message={this.state.successMessage} sticky /> }

        <TabSwitch
          className="mt-4"
          tabs={[
            { label: 'Homepage Carousel', value: 'carousel' },
            { label: 'Newsflash Banner', value: 'newsflash' },
            { label: 'Export options', value: 'export' }
          ]}
          activeTab={ this.state.activeTab }
          onTabChange={ tab => this.setState({ activeTab: tab }) }
        />

        { !this.state.loading
            ? <TabContent activeTab={ this.state.activeTab }>
                <TabPane tabId="carousel">
                  <CarouselSettings
                    data={ this.state.carouselTableData }
                    onError={ msg => this.setState({ errorMessage: msg }) }
                    onSuccess={ msg => this.setState({ successMessage: msg }) }
                  />
                </TabPane>
                <TabPane tabId="newsflash">
                  <NewsflashSettings
                    data={ this.state.newsflashData }
                    onError={ msg => this.setState({ errorMessage: msg }) }
                    onSuccess={ msg => this.setState({ successMessage: msg }) }
                  />
                </TabPane>
                <TabPane tabId="export">
                  <h5 className="mt-5 mb-4">Export content</h5>
                  <div className="flex">
                    <button 
                      className={ 'button button--small mr-2' + (this.state.exporting === 'items' ? ' button--spinning' : '') }
                      disabled={ !!(this.state.exporting && this.state.exporting !== 'items') }
                      onClick={ () => this.doExport('items') }
                    >
                      Items
                    </button>
                    <button 
                      className={ 'button button--small mr-2' + (this.state.exporting === 'collections' ? ' button--spinning' : '') }
                      disabled={ !!(this.state.exporting && this.state.exporting !== 'collections') }
                      onClick={ () => this.doExport('collections') }
                    >
                      Collections
                    </button>
                    <button 
                      className={ 'button button--small mr-2' + (this.state.exporting === 'profiles' ? ' button--spinning' : '') }
                      disabled={ !!(this.state.exporting && this.state.exporting !== 'profiles') }
                      onClick={ () => this.doExport('profiles') }
                    >
                      Users
                    </button>
                  </div>
                </TabPane>
              </TabContent>

            : <div className="text-center mt-5">
                <Spinner style={{ width: '5rem', height: '5rem' }} />
              </div>
        }
      </Container>
    )
  }
}

export default withRouter(Settings)
