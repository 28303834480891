import { SET_LOADING, SET_DATA } from '../actions/highlights';
import { Highlight } from '../types/Highlight';

export interface HighlightsState {
  data?: Highlight[]
  isLoading: boolean
}

const initialState: HighlightsState = {
  data: [],
  isLoading: false
};

export default (state: HighlightsState | null = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case SET_DATA:
      return {
        ...state,
        data: action.data,
        isLoading: false
      }

    default:
      return state
  }
};
