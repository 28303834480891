// user-history.js
import { createBrowserHistory } from '../node_modules/history';
// import ReactGA from 'react-ga4';

import config from './config';

// if ((window === window.top) && localStorage.getItem('acceptCookies') === 'true') {
  // ReactGA.initialize(config.google.UA_ID);
// }

const history = createBrowserHistory();

const historyListener = (location) => {
  // if ((window === window.top) && localStorage.getItem('acceptCookies') === 'true') {
    // ReactGA.set({ page: location.pathname });
    // ReactGA.send({hitType: 'pageview', page: location.pathname});
  // }
};

history.listen(historyListener);
historyListener(window.location);

export default history;
