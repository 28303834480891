// This library is a TypeScript version of https://github.com/georgedoescode/splinejs/ from George Francis

export interface Point {
  x: number
  y: number
  originX: number
  originY: number
  noiseOffsetY: number
  noiseOffsetX: number
}

const formatPoints = (points: Point[], close: boolean) => {
  let pointsArr = [ ...points ] as any
  // so that coords can be passed as objects or arrays
  if (!Array.isArray(pointsArr[0])) {
    pointsArr = pointsArr.map(({ x, y }) => [x, y])
  }

  if (close) {
    const lastPoint = pointsArr[pointsArr.length - 1]
    const secondToLastPoint = pointsArr[pointsArr.length - 2]

    const firstPoint = pointsArr[0]
    const secondPoint = pointsArr[1]

    pointsArr.unshift(lastPoint)
    pointsArr.unshift(secondToLastPoint)

    pointsArr.push(firstPoint)
    pointsArr.push(secondPoint)
  }

  return pointsArr.flat()
}

export const spline = (points: Point[], tension = 1 as number, close = false, cb?: Function) => {
  if (!points) return

  const _points = formatPoints(points, close)

  const size = _points.length
  const last = size - 4

  const startPointX = close ? _points[2] : _points[0]
  const startPointY = close ? _points[3] : _points[1]

  let path = 'M' + [startPointX, startPointY]

  if (cb) cb('MOVE', [startPointX, startPointY])

  const startIteration = close ? 2 : 0
  const maxIteration = close ? size - 4 : size - 2
  const inc = 2

  for (let i = startIteration; i < maxIteration; i += inc) {
    const x0 = i ? _points[i - 2] : _points[0]
    const y0 = i ? _points[i - 1] : _points[1]

    const x1 = _points[i + 0]
    const y1 = _points[i + 1]

    const x2 = _points[i + 2]
    const y2 = _points[i + 3]

    const x3 = i !== last ? _points[i + 4] : x2
    const y3 = i !== last ? _points[i + 5] : y2

    const cp1x = x1 + ((x2 - x0) / 6) * tension
    const cp1y = y1 + ((y2 - y0) / 6) * tension

    const cp2x = x2 - ((x3 - x1) / 6) * tension
    const cp2y = y2 - ((y3 - y1) / 6) * tension

    path += 'C' + [cp1x, cp1y, cp2x, cp2y, x2, y2]

    if (cb) cb('CURVE', [cp1x, cp1y, cp2x, cp2y, x2, y2])
  }

  return path
}
