import { useEffect } from 'react';

export default (className: string | Array<string>) => {
  useEffect(() => {
    if (Array.isArray(className)) {
      className.forEach(c => {
        if (c) {
          document.body.classList.add(c);
        }
      });
    } else {
      document.body.classList.add(className);
    }
    

    return () => {
      if (Array.isArray(className)) {
        className.forEach(c => {
          if (c) {
            document.body.classList.remove(c);
          }
        });
      } else {
        document.body.classList.remove(className);
      }
    };
  }, [className]);
};
