import { useEffect } from 'react'
import { connect } from 'react-redux'
import RollingHeader from 'components/layout/RollingHeader'
import Footer from 'components/layout/Footer'
import { setTheme } from 'actions/global'

const InfoPageWrapper = ({ children, setThemeState }: { children: any, setThemeState: Function }) => {
  useEffect(() => setThemeState('dark'))

  return (
    <>
      <RollingHeader />
      <div className="info-page">
        <div className="info-page__content">
          { children }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default connect(null, { setThemeState: setTheme })(InfoPageWrapper)
