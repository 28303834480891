import { useState, useEffect, useRef } from 'react'
import { duration } from 'moment'
import { IoTimerOutline } from 'react-icons/io5'

import './NewsflashBanner.scss'
import { log } from 'console'

const NewsflashBanner = ({ text, link, countdownTo }: { text: string, link?: string, countdownTo?: number }) => {
  const [ repeater, setRepeater ] = useState(0)
  const [ timeLeft, setTimeLeft ] = useState(0)
  const [ animationDuration, setAnimationDuration ] = useState(0)
  const [ animationPlayState, setAnimationPlayState ] = useState('running')

  const textRef = useRef(null)

  const setSizeAndSpeed = () => {
    const textEl = textRef.current as unknown as HTMLElement
    if (!textEl) return

    // repeating
    const documentWidth = document.body.clientWidth
    const marqueeWidth = textEl.offsetWidth

    if (documentWidth > marqueeWidth) {
      setRepeater( Math.max(Math.ceil(documentWidth / marqueeWidth), 1) )
    } else {
      setRepeater(1)
    }

    // sliding speed
    // this.data.forEach(item => {
    //   this.words += this.words == '' ? item.flashBannerConfiguration.title : ' ' + item.flashBannerConfiguration.title
    // })
    // const wordsCount = this.words.split(' ').length
    const fromWidth = 1440

    setAnimationDuration( (fromWidth / documentWidth) * (documentWidth / fromWidth * 20000) * (marqueeWidth / fromWidth) )
  }
  const onHover = () => {
    if (window.innerWidth >= 768) setAnimationPlayState('paused')
    }
  const onLeave = () => {
    if (window.innerWidth >= 768) setAnimationPlayState('running')
  }
  // const close = () => {
  //   this.showNewsBar = false
  // }


  useEffect(() => {
    setSizeAndSpeed()

    if (countdownTo) {
      setTimeLeft(Math.max(0, countdownTo - new Date().getTime()))
      setInterval(() => {
        setTimeLeft(Math.max(0, countdownTo - new Date().getTime()))
      }, 1000)
    }
  }, [])

  return (
    <div
      className="newsflash background-rainbow"
      onMouseEnter={ onHover }
      onMouseLeave={ onLeave }
    >
      { countdownTo && (
        <div className="newsflash__countdown background-rainbow">
          { timeLeft === 0
            ? <>
                <span className="newsflash__reddot" />
                LIVE
              </>
            : <>
                <IoTimerOutline className="mr-1" style={{ marginBottom: '3px' }} />
                { duration(timeLeft).format('D [days, ] HH:mm:ss') }
              </>
          }
        </div>
      ) }

      <a
        href={ link || '#' }
        target="_blank"
        className='newsflash__content'
        style={{ animationDuration: animationDuration + 'ms', animationPlayState: animationPlayState }}
      >
        <p ref={ textRef }>{ text }</p>
        { [...Array(repeater)].map((e, i) => (
          <p className="clone" key={i}>{ text }</p>
        )) }
      </a>
    </div>
  )
}

export default NewsflashBanner