import { getSocialNetwork } from 'utils'
import { InstagramIcon, FacebookIcon, TwitterIcon, LinkedInIcon } from 'lib/socialIcons'

const SocialLink = ({ url }: { url: string }) => {
  const socialNetwork = getSocialNetwork(url)

  const urlWithProtocol = url.startsWith('http') ? url : 'https://' + url

  return socialNetwork ? (
    <a href={ urlWithProtocol } target="_blank" rel="noopener noreferrer nofollow">
      { socialNetwork === 'facebook' && <FacebookIcon /> }
      { socialNetwork === 'instagram' && <InstagramIcon /> }
      { socialNetwork === 'linkedin' && <LinkedInIcon /> }
      { socialNetwork === 'twitter' && <TwitterIcon /> }
    </a>
  ) : <></>
}

export default SocialLink