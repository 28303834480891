import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Spinner } from 'reactstrap';
import { fetchItem } from 'actions/items/viewItem';
import { ViewItemState } from 'reducers/items/viewItem';
import { Alerts, ErrorMessage } from '../utils/alerts';
import { Item, itemType, Regions } from '../../types/Item';
import { FilePreview } from '../utils/FilePreview';
import SpecialMenu from '../utils/SpecialMenu';
import { Languages } from '../../types/Languages';
import { browser } from '../utils/browser';
import { RouteComponentProps, withRouter } from 'react-router';
import 'styles/components/pages/viewItem.scss';
import Share from '../utils/Share';
import { getLicenceLink } from '../utils/licence';
import moment from 'moment';
import { FileTypes } from '../../types/s3File';
import PrettyAudio from '../layout/audio/PrettyAudio';
import { dateFromTimeYearProduced } from '../../actions/home';
import { pushEntity as pushUserHistoryEntity } from '../../actions/user-history';
import {
  search as dispatchSearch,
  toggle as searchOpenToggle,
} from '../../actions/searchConsole';
import { toggle as collectionModalToggle } from 'actions/modals/collectionModal';
import { toggle as itemModalToggle } from 'actions/modals/itemModal';
import { modalToggle } from 'actions/pages/privacyPolicy';
import { UserHistoryState } from '../../reducers/user-history';
import { HomepageData } from '../../reducers/home';
import HtmlDescription from '../utils/HtmlDescription';
import { isEqual } from 'lodash';
import { FaCode } from 'react-icons/fa';
import { iframeItemEmbedCodeURL } from 'urls';
import { itemURL } from '../../urls';
import { ProfileState } from '../../reducers/user/profile';
import { Profile } from '../../types/Profile';
import { TiStar, TiStarOutline } from 'react-icons/ti';
import { getCurrentUserProfileById } from '../../actions/user/profile';

import { GlobalState } from 'reducers/global';
import RollingHeader from 'components/layout/RollingHeader';
import ThemeSwitch from 'components/utils/ThemeSwitch'
import { SearchFilters } from '../../reducers/search'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { ReactComponent as CitationIcon } from 'images/icons/citation.svg';

import {
  addUserFavourite,
  deleteUserFavourite,
} from '../../actions/user/profile';
import { tagsToString } from 'components/metadata/Tags';

import './ViewItem.scss'
import ItemPreview from './ItemPreview';
import { AuthContext } from 'providers/AuthProvider';

type MatchParams = {
  id: string;
};

interface Props extends RouteComponentProps<MatchParams>, Alerts {
  fetchItem: Function;
  modalToggle: Function;
  collectionModalToggle: Function;
  itemModalToggle: Function;
  searchOpenToggle: Function;
  dispatchSearch: Function;
  pushUserHistoryEntity: Function;
  addUserFavourite: Function;
  deleteUserFavourite: Function;
  getCurrentUserProfile: Function;
  item: Item;
  profile: ProfileState;
  userHistory?: UserHistoryState;
  userIsAdmin?: boolean;
  favourites:  Profile['favourites'];
  favouriteIsLoading: boolean;
  activeTheme: string;
}

interface State {
  errorMessage: string | undefined;
  item: HomepageData | Item | undefined;
  isBurgerOpen: boolean;
  isVerticalLayout: boolean
  wasRendered: boolean
  descriptionOverflown: boolean
  descriptionOpen: boolean
}

class ViewItem extends React.Component<Props, State> {
  static contextType = AuthContext
  userCanEdit
  userIsAdmin
  browser: string
  descriptionDiv = React.createRef<HTMLDivElement>()


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);

    this.browser = browser();

    this.state = {
      errorMessage: undefined,
      item: undefined,
      isBurgerOpen: false,
      isVerticalLayout: false,
      descriptionOverflown: false,
      descriptionOpen: false,
      wasRendered: false
    }
  }

  componentDidMount() {
    this.pushItemToHistory();

    this.setState(prev => ({ ...prev, isVerticalLayout: window.innerWidth < 1140 }))

    window.addEventListener('resize', () => {
      this.setState(prev => ({ ...prev, isVerticalLayout: window.innerWidth < 1140 }))
    })

    const { match } = this.props;
    let matchedItemId: string | null = null;

    // Get our itemId passed through from URL props
    if (match.params.id) {
      matchedItemId = match.params.id;
    }

    // If we have an id from the URL pass it through, otherwise use the one from Redux State
    if (matchedItemId) {
      this.props.fetchItem(matchedItemId);
    } else {
      this.setState({ errorMessage: 'No item with that id.' });
    }

    this.props.getCurrentUserProfile(0);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>
  ): void {
    this.pushItemToHistory(prevProps.item);

    if (!this.state.wasRendered && this.descriptionDiv.current) {
      const isOverflown = this.descriptionDiv.current.scrollHeight > this.descriptionDiv.current.clientHeight
      this.setState(prev => ({ ...prev, descriptionOverflown: isOverflown, wasRendered: true }))
    }

    const authContext: React.ContextType<typeof AuthContext> = this.context

    if (this.props.item && authContext.isLoading === false) {
      this.userIsAdmin = ('admin' in authContext.authorisation && !!authContext.authorisation.admin)
      this.userCanEdit = authContext.uuid === this.props.item.contributor || this.userIsAdmin
    }
  }

  pushItemToHistory(prevItem?: Item) {
    if (this.props.item !== undefined) {
      if (prevItem !== undefined) {
        if (!isEqual(this.props.item, prevItem)) {
          const userHistoryEntity = this.createHistoryEntity();
          this.props.pushUserHistoryEntity(userHistoryEntity);
        }
      } else {
        const userHistoryEntity = this.createHistoryEntity();
        this.props.pushUserHistoryEntity(userHistoryEntity);
      }
    }
  }

  createHistoryEntity(): Item {
    return { ...this.props.item, __typename: 'item' };
  }

  // @todo should be a util / dispatch
  onTagClick = (label: string, field: string) => {
    setTimeout(() => {
      this.props.collectionModalToggle(false);
      this.props.itemModalToggle(false);
      // this.props.searchOpenToggle(true);
      // this.props.dispatchSearch([createCriteriaOption(label, field)]);

      field === 'keyword_tag'
        ? this.props.history.push('/search', { searchString: label.replace('#', '') })
        : this.props.history.push('/search', { filters: { tags: label } as SearchFilters })
    });
  };

  onShowMoreClick = () => {
    this.setState(prev => ({ ...prev, descriptionOpen: true }))
  }

  isFavourited() {
    const retVal = this.props.favourites?.items?.includes(Number(this.props.item.id));
    return retVal;
  }

  render() {
    if (typeof this.props.item === 'undefined') {
      return (
        <div id="item" className="view-item pt-5">
          <div className="mt-5"><ErrorMessage message={this.props.errorMessage} /></div>
        </div>
      )
    }

    const {
      id,
      file,
      creators,
      title,
      description,
      item_subtype,
      regions,
      language,
      license,
      aggregated_concept_tags,
      aggregated_keyword_tags,
      journal,
      time_produced,
      year_produced,
      end_year_produced,
      venues,
      exhibited_at,
      copyright_holder,
      url,
      s3_key,
      medium,
      item_type,
      directors,
      collaborators,
      stage_link,
      stage_link_caption
    } = this.props.item;

    if (item_type === itemType.IFrame && url && title && !(url.match(/soundcloud/) || url.match(/3DOA/))) {
      return (
        <>
          {/* <div className="row">
            <Col>
              <div className="flex items-center justify-between">
                <h1> </h1>
                <div className="flex items-center">
                  {!!id && (
                    <h3 style={{ marginLeft: '1rem' }}>
                      <Share
                        variant="prefixedWithHostname"
                        text={itemURL(id)}
                      />
                    </h3>
                  )}
                  {(file && file.type !== FileTypes.VideoEmbed) ? (
                  <h3>
                    <Share
                      variant="fullText"
                      iconComponent={<FaCode />}
                      text={iframeItemEmbedCodeURL(
                        this.props.item.id,
                        title || '',
                        'IFrame',
                        url
                      )}
                    />
                  </h3>) : <></>
                  }
                </div>
              </div>
            </Col>
          </div> */}

          <div className="row">
            <div id="item" className="container-fluid header-margin">
              <ErrorMessage message={this.props.errorMessage} />
              {url.match(/freq_waveall/)
                ? 'Note that the loading button only turns into a play button after all audio files are downloaded. This mixer also requires a fair amount of memory to work.'
                : ''}
              <iframe
                title={title}
                src={url}
                allow="autoplay"
                className={
                  url.match(/freq/) ? 'freq_wave-iframe' : 'special_item-iframe'
                }
              />
            </div>
          </div>
        </>
      );
    }

    if (this.props.userHistory && this.props.userHistory.loading) {
      return <></>;
    }

    if (description) {
      const metadescription = document.querySelector('meta[name=\'description\']');
      metadescription!.setAttribute('content', description);
    }
    if (aggregated_concept_tags && aggregated_concept_tags.length > 0) {
      const keywords = document.querySelector('meta[name=\'keywords\']');
      let keyword_list = tagsToString(aggregated_concept_tags);
      if (aggregated_keyword_tags && aggregated_keyword_tags.length > 0) {
        keyword_list += ', ' + tagsToString(aggregated_keyword_tags);
      }
      keywords!.setAttribute('content', keyword_list);
    }
    if (title) {
      const docTitle = document.querySelector('title');
      docTitle!.innerHTML = title;
    }

    const Metadata = (): JSX.Element => (
      <div className="view-item__meta">
        { item_subtype &&
          <p>Type: { item_subtype }</p>
        }
        { journal &&
          <p>Publisher: { journal }</p>
        }
        { time_produced ?
            <p>Date Produced: { moment(time_produced, moment.defaultFormatUtc).format('Do MMMM YYYY') }</p>
          : year_produced &&
            <p>Year Produced: { dateFromTimeYearProduced( time_produced, year_produced, end_year_produced ) }</p>
        }
        { venues?.length &&
          <p>{ venues.length > 1 ? 'Publication Venue' : 'Publication Venues' }: { venues.join(', ') }</p>
        }
        { exhibited_at?.length &&
          <p>Exhibited At: { exhibited_at.join(', ') }</p>
        }
        { regions?.length &&
          <p>{ regions.length > 1 ? 'Regions' : 'Region' }: { regions.map((region) => Regions[region]).join(', ') }</p>
        }
        { directors?.length &&
          <p>{ directors.length > 1 ? 'Directors' : 'Director' }: { directors.join(', ') }</p>
        }
        { collaborators?.length &&
          <p>{ collaborators.length > 1 ? 'Collaborators' : 'Collaborator' }: { collaborators.join(', ') }</p>
        }
        { language &&
          <p>Language: { Languages[language] }</p>
        }
        { license && (
          <p>License: {
            getLicenceLink(license)
              ? <a href={ getLicenceLink(license) || '' } target="_blank" rel="noopener noreferrer">{ license }</a>
              : <a href='#' onClick={() => this.props.modalToggle('RL_MODAL', true)}>Ocean Archive</a>
          }</p>
        ) }
        { copyright_holder &&
          <p>Copyright Owner: { copyright_holder }</p>
        }
        { medium &&
          <p>Medium: { medium }</p>
        }
        { url &&
          <p>Relation: <a href={ url } target="_blank" rel="noreferrer noopener">Click to view</a></p>
        }
        { aggregated_concept_tags?.length !== 0 && (
          <div className="view-item__concept-tags mt-4">
            <p>Concept Tags</p>
            <div>
              {aggregated_concept_tags?.map((t) => {
                return (
                  <Button
                    className="page-link tag d-inline-block text-left"
                    key={t.tag_name}
                    onClick={() =>
                      this.onTagClick(t.tag_name, 'concept_tag')
                    }
                  >
                    #{t.tag_name}
                  </Button>
                );
              })}
            </div>
          </div>
        ) }
        { aggregated_keyword_tags?.length !== 0 && (
          <div className="view-item__keyword-tags mt-3">
            <p>Keyword Tags</p>
            <div>
              {aggregated_keyword_tags?.map((t) => {
                return (
                  <Button
                    className="mr-1 tag d-inline-block text-left"
                    key={t.tag_name}
                    onClick={() =>
                      this.onTagClick(t.tag_name, 'keyword_tag')
                    }
                  >
                    #{t.tag_name}
                  </Button>
                );
              })}
            </div>
          </div>
        ) }
        { stage_link && (
          <div className="view-item__stage-link mt-4">
            <p>{ stage_link_caption }</p>
            <a href={ stage_link } target="_blank" className="button button--stage button--small">
              <span>tba 21 on st_age</span>
            </a>
          </div>
        ) }
        <div className="row">
          <SpecialMenu id={id} />
        </div>
      </div>
    )

    return (
      <div id="item" className="view-item">
        <RollingHeader dark={ this.props.activeTheme === 'light' } />

        <ErrorMessage message={this.props.errorMessage} />

        <div className="view-item__body">
          {/* ======== LEFT ======== */}
          <div className="view-item__left">
            <div>
              {/* ======== Details ======== */}
              <h1 className="view-item__title">{ title }</h1>
              { creators &&
                <div className="view-item__subtype">{ creators.join(', ') }</div>
              }
              { !this.state.isVerticalLayout &&
                <Metadata />
              }
            </div>
          </div>

          {/* ======== RIGHT ======== */}
          <div className="view-item__right">

            {/* ======== Preview ======== */}
            <div className="view-item__preview">
              <ItemPreview item={ this.props.item } />
            </div>

            {/* ======== Description ======== */}
            <div ref={ this.descriptionDiv } className="view-item__description open">
              <div>
                { file && (
                  file.type === FileTypes.DownloadText ||
                  file.type === FileTypes.Pdf) &&
                  file.url && (
                    <div className="view-item__download">
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        <AiOutlineCloudDownload />
                        <span>Click here to download this file</span>
                      </a>
                    </div>
                ) }
                <div className={ `view-item__description-text${ this.state.descriptionOverflown && ' overflown' || '' }` }>
                  {description && (
                    <HtmlDescription description={description} />
                  ) }
                </div>
              </div>
              { this.state.descriptionOverflown && !this.state.descriptionOpen && (
                <button className="view-item__description-more button" onClick={ this.onShowMoreClick }>
                  Read more
                </button>
              ) }
            </div>

            {/* ======== Actions ======== */}
            <div className="view-item__actions">
              <div className="view-item__actions-first-row">
                <div className="view-item__actions-favourite">
                  { !this.props.favouriteIsLoading
                    ? <button className="btn btn--plain">
                        { this.isFavourited()
                          ? <TiStar
                              color="#50E2C1"
                              title='Remove from favourites'
                              onClick={() => {
                                this.props.deleteUserFavourite('items', Number(id));
                              }}
                            />
                          : <TiStarOutline
                              color="#50E2C1"
                              title='Add to favourites'
                              onClick={() => {
                                this.props.addUserFavourite('items', Number(id));
                              }}
                            />
                        }
                      </button>
                    : <Spinner style={{ color: '#50E3C2', width: '22px', height: '22px', margin: '4px 0'}}/>
                  }
                </div>
                <div className="view-item__actions-citation">
                  <Share
                    color={ this.props.activeTheme === 'light' ? 'dark-gray' : 'white' }
                    variant="citation"
                    iconComponent={<CitationIcon />}
                    authors={ creators }
                    title={ title }
                    published={ year_produced || time_produced }
                    help='Copy citation'
                  />
                </div>
                { file?.type !== FileTypes.VideoEmbed && (
                  <div className="view-item__actions-embed">
                    <Share
                      color={ this.props.activeTheme === 'light' ? 'dark-gray' : 'white' }
                      variant="fullText"
                      iconComponent={<FaCode />}
                      text={iframeItemEmbedCodeURL(this.props.item.id, this.props.item.title || '', file?.type, file?.url)}
                      help='Copy embed code'
                    />
                  </div>
                ) }
                { id && (
                  <div className="view-item__actions-share">
                    <Share
                      color={ this.props.activeTheme === 'light' ? 'dark-gray' : 'white' }
                      variant="prefixedWithHostname"
                      text={itemURL(id)}
                      help='Copy link'
                    />
                  </div>
                ) }
                <ThemeSwitch />
              </div>
              { this.userCanEdit && (
                <div className="mt-4 text-right">
                  <a href={`/${this.userIsAdmin ? 'admin' : 'contributor'}/items/${id}`} target="_blank" className="button">Edit</a>
                </div>
              ) }
            </div>

            { this.state.isVerticalLayout &&
              <Metadata />
            }
          </div>
        </div>
      </div>
    );
  }
}

// State to props
const mapStateToProps = (state: {
  global: GlobalState;
  viewItem: ViewItemState;
  userHistory: UserHistoryState;
  profile: ProfileState;
}) => ({
  activeTheme: state.global.theme,
  errorMessage: state.viewItem.errorMessage,
  item: state.viewItem.item,
  userHistoryLoading: state.userHistory.loading,
  favouriteIsLoading: state.profile.favouriteIsLoading,
  favourites: state.profile?.details?.favourites,
  profile: state.profile
});

// Connect our redux store State to Props, and pass through the fetchItem function.
export default withRouter(
  connect(mapStateToProps, {
    fetchItem,
    modalToggle,
    collectionModalToggle,
    itemModalToggle,
    searchOpenToggle,
    dispatchSearch,
    pushUserHistoryEntity,
    addUserFavourite,
    deleteUserFavourite,
    getCurrentUserProfile: getCurrentUserProfileById
  })(ViewItem)
);
