import { Container, Row, Col } from 'reactstrap'

import { CommunityItem } from '../../types/Community'

const HomeCommunity = ({ data, children }: { data: CommunityItem[], children: any }) => {
  return (
    <Container fluid className="home-community background-violet py-5" tag="section">
      <h2 className="pt-4">〜 ocean comm/uni/ty latest posts</h2>
      <p className="text-subtitle mt-4 mb-5">An online social space for ocean lovers, researchers and practitioners to gather, connect and (un)learn across the Ocean.</p>
      <Row className="home-highlights__strip">
        { data?.map((item, i) => (
          <Col xs="12" sm="6" md="3" className="px-4 mb-4" key={i}>
            <h4>{ item.title }</h4>
            <p className="mb-0 text-clamp-5">{ item.summary }</p>
            <div>
              <a href={ item.permalink } target="_blank" rel="noreferrer" className="highlight-item__link">〜 <span>View item</span></a>
            </div>
          </Col>
        )) }
      </Row>
      { children }
    </Container>
  )
}

export default HomeCommunity
