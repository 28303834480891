import { HomepageData } from '../../reducers/home';
import { Collection } from '../../types/Collection';
import ReactGA from 'react-ga4';
import { collectionURL } from '../../urls';

// Defining our Actions for the reducers
export const COLLECTION_MODAL_TOGGLE = 'COLLECTION_MODAL_TOGGLE';

// Modal
export const toggle = (open: boolean, data?: HomepageData | Collection) => (dispatch, getState) => {
  if (open && data && data.id && localStorage.getItem('acceptCookies') === 'true') {
    ReactGA.send({hitType: 'pageview', page: collectionURL(data.id)});
  }

  dispatch({
     type: COLLECTION_MODAL_TOGGLE,
     open,
     data
   });

};
