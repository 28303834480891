import { IoClose } from 'react-icons/io5'
import { FiEdit, FiCheck } from 'react-icons/fi'

const iconsMap = {
  'close': <IoClose size={ 30 } />,
  'edit': <FiEdit size={ 30 } />,
  'check': <FiCheck size={ 30 } />
}

const IconButton = ({ icon, onClick }: { icon: string, onClick?: () => void }) => (
  <button className="button button--plain" onClick={ onClick }>
    { iconsMap[icon] }
  </button>
)

export default IconButton