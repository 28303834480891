import type {
  WP_REST_API_Categories,
  WP_REST_API_Post,
  WP_REST_API_Posts,
  WP_REST_API_Tags,
} from 'wp-types';

import config from '../config.js';

const ROOT_WP_URL = `${config.urls.WP_URL}wp-json/wp/v2`;

export const getStory = async (slug: string): Promise<WP_REST_API_Post> => {
  const response = await fetch(`${ROOT_WP_URL}/posts?slug=${slug}`);
  const [story] = await response.json();
  return story;
};

export const getStoriesAndTotalStoriesInDatabase = async (
  params: SearchStoryParams
): Promise<{
  stories: WP_REST_API_Posts;
  totalStoriesInDatabase: number;
  hasMore: boolean;
}> => {
  // exclude preview category from REST call
  let urlWithParams = `${ROOT_WP_URL}/posts?_embed&categories_exclude=${config.WP.PREVIEW_CATEGORY_ID}`;
  // This URL is used to only get total posts available on database. Fetches only 1 posts because information of total posts on database is available on GET request header.
  let urlWithoutParams = `${ROOT_WP_URL}/posts?per_page=1&categories_exclude=${config.WP.PREVIEW_CATEGORY_ID}`;

  if (params) {

    if (params.perPage) {
      urlWithParams += `&per_page=${params.perPage}`;
    }

    if (params.page) {
      urlWithParams += `&page=${params.page}`;
    }
    
    if  (params.include) {
      urlWithParams += `&include=${(params.include.join(','))}`;
    }

    if (params.title) {
      urlWithParams += `&search=${params.title}`;
    }

    if (params.sticky) {
      urlWithParams += '&sticky=true';
    }

    if (params.orderBy) {
      urlWithParams += `&orderby=${params.orderBy}`;
    }

    if (params.order) {
      urlWithParams += `&order=${params.order}`;
    }

    if (params.categoryIds && params.categoryIds.length > 0) {
      urlWithParams += `&categories=${params.categoryIds.join(',')}`;
    }

    if (params.tagIds && params.tagIds.length > 0) {
      urlWithParams += `&tags=${params.tagIds.join(',')}`;
    }
  }

  const responseWithParams = await fetch(urlWithParams);
  const responseWithoutParams = await fetch(urlWithoutParams);
  const stories = await responseWithParams.json();

  let totalStoriesInDatabase =
    Number(responseWithoutParams.headers.get('x-wp-total')) || 0;
  let hasMore = Number.isNaN(params.page) ? false : 
    (Number(responseWithParams.headers.get('x-wp-totalpages')) || 0) >
    params.page!;

  return {
    stories,
    totalStoriesInDatabase,
    hasMore,
  };
};

type WPArgs = {
  perPage: number;
  page: number;
};

const getAllWPDataRecursively = async <T>(
  fn: (wpargs: WPArgs) => Promise<T[]>
): Promise<T[]> => {
  let page = 1;
  let perPage = 100;
  let allData: T[] = [];
  while (perPage === 100) {
    let someCategories = await fn({ perPage, page });
    allData = allData.concat(someCategories);
    perPage = someCategories.length;
    page++;
  }

  return allData;
};

export const getCategories = async ({
  perPage = 100,
  page = 1,
} = {}): Promise<WP_REST_API_Categories> => {
  const response = await fetch(
    `${ROOT_WP_URL}/categories?per_page=${perPage}&page=${page}`
  );
  return await response.json();
};

export const getAllCategories = () => getAllWPDataRecursively(getCategories);

export const getTags = async ({
  perPage = 100,
  page = 1,
} = {}): Promise<WP_REST_API_Tags> => {
  const response = await fetch(
    `${ROOT_WP_URL}/tags?per_page=${perPage}&page=${page}`
  );
  return await response.json();
};

export const getAllTags = () => getAllWPDataRecursively(getTags);

export const getPostsInPreview = async ({
  perPage = 100,
  page = 1,
} = {}): Promise<WP_REST_API_Posts> => {
  const response = await fetch(
    `${ROOT_WP_URL}/posts?per_page=${perPage}&page=${page}&categories=${config.WP.PREVIEW_CATEGORY_ID}`
  );
  return await response.json();
};

export const getAllPostsInPreview = () => getAllWPDataRecursively(getPostsInPreview);

export type SearchStoryParams = {
  title?: string;
  order?: 'asc' | 'desc';
  orderBy?: 'date' | 'author' | 'title';
  categoryIds?: string[];
  tagIds?: string[];
  perPage?: number;
  page?: number;
  sticky: boolean;
  include?: number[] | string[];
};
