import * as React from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

import { FiFilter } from 'react-icons/fi'

import Footer from 'components/layout/Footer'
import SearchIntro from 'components/search/SearchIntro'
import SearchFilters from 'components/search/SearchFilters'
import SearchResults from 'components/search/SearchResults'
import ThemeSwitch from 'components/utils/ThemeSwitch'
import RollingHeader from 'components/layout/RollingHeader'

import { SearchState, SearchFilters as SearchStateFilters } from '../../reducers/search'
import { RouteComponentProps } from 'react-router-dom'

import { doSearch, doInitialSearch, restoreInitialSearch, setFilters } from 'actions/search'

import { clear as clearHistory } from 'actions/user-history'

import './Search.scss'

interface Props extends RouteComponentProps {
  searchState: SearchState
  doSearch: Function
  doInitialSearch: Function
  restoreInitialSearch: Function
  setFilters: Function,
  clearHistory: Function
}

interface State {
  currentInput: string
  filtersOpen: boolean
}

class Search extends React.Component<Props, State> {
  searchBody = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)
    this.state = {
      currentInput: '',
      filtersOpen: false
    }
  }

  componentDidMount() {
    document.title = 'Search | Ocean Archive'
    const routeFilters = this.props.location.state?.filters
    const routeSearchString = this.props.location.state?.searchString

    if (routeFilters) {
      this.props.setFilters(routeFilters, true)
      this.props.doSearch(routeSearchString || '', routeFilters)
    } else if (routeSearchString) {
      this.props.doSearch(routeSearchString)
    } else {
      this.props.doInitialSearch()
    }
  }

  handleSearchInput = async (val: string) => {
    // Don't search for input shorter than 3 chars
    if (val.length < 3 && val.length > 0) return

    this.setState(prev => ({ ...prev, currentInput: val }))

    // If user has cleared the input, show the default results, otherwise do search
    val.length === 0
      ? this.resetSearch()
      : this.props.doSearch(val, this.props.searchState.filters)

    setTimeout(() => this.searchBody.current?.scrollIntoView({ behavior: 'smooth' }), 100)
  }

  handleFilterChange = (filters: SearchStateFilters) => {
    this.props.doSearch(this.state.currentInput, filters)
    this.setState(prev => ({ ...prev, filtersOpen: false }))

    setTimeout(() => this.searchBody.current?.scrollIntoView({ behavior: 'smooth' }), 100)
  }

  toggleFiltersOpen = () => {
    this.setState(prev => ({ ...prev, filtersOpen: !prev.filtersOpen }))
  }

  resetSearch = () => {
    this.props.setFilters({}, true)
    this.props.restoreInitialSearch()
  }

  render() {
    const totalResults = this.props.searchState.totalHits
    const showing = Math.min((this.props.searchState.currentPage + 1) * this.props.searchState.pagination, totalResults)

    return (
      <>
        <div className="search-header">
          <RollingHeader />
        </div>

        <div className="search-sticky-wrapper">
          <div>
            <SearchIntro onInput={ debounce(this.handleSearchInput, 300) } />
          </div>
        </div>

        <div className="search-body theme-colors" ref={ this.searchBody }>
          <SearchFilters facets={ this.props.searchState.facets } onChange={ this.handleFilterChange } open={ this.state.filtersOpen }>
            <p className="text-lead mt-3">
              Displaying { showing } out of { totalResults } results
            </p>
          </SearchFilters>
          <SearchResults />

          <button className="search-body__filter-btn btn btn--plain" onClick={ this.toggleFiltersOpen }>
            <FiFilter />
          </button>
          <ThemeSwitch />
        </div>

        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state: { search: SearchState }) => ({
  searchState: state.search
})

export default connect(mapStateToProps, { doSearch, doInitialSearch, restoreInitialSearch, setFilters, clearHistory })(Search)
