import * as React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { Item } from 'types/Item'

import 'styles/components/admin/tables/modal.scss'
import ItemSimpleView from 'components/item/ItemSimpleView'

class ItemGalleryModal extends React.Component<{ items: Item[] , open: boolean, onToggle: Function }, {}> {
  render() {
    return (
      <Modal isOpen={ this.props.open } toggle={() => this.props.onToggle()} className="fullwidth blue padded">
        <ModalBody>
          { this.props.items?.map((item, i) => (
            <ItemSimpleView item={ item } key={ item.id } className={ i > 0 ? 'mt-5' : '' } />
          )) }
        </ModalBody>

        <FaTimes className="closeButton" onClick={() => this.props.onToggle()}/>
      </Modal>
    )
  }
}

export default ItemGalleryModal
