export const WebIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 25 25">
    <path fill="currentColor" d="M24.97 12.33c0 1.13-.11 2.1-.35 3.05-.03.13-.06.24.08.36.48.45.38 1.12-.22 1.42-.9.44-1.8.85-2.7 1.28l-2.1 1c-.14.07-.2.17-.27.3l-2.18 4.6c-.06.12-.13.26-.22.37a.82.82 0 0 1-1.22.03c-.13-.13-.24-.16-.4-.12-3.3.77-6.41.31-9.3-1.43A11.98 11.98 0 0 1 .25 14.9c-.84-4.46.43-8.32 3.68-11.48 2-1.95 4.44-3.05 7.23-3.34 3.19-.34 6.1.44 8.68 2.33a12.26 12.26 0 0 1 5.13 9.92ZM12.5 9.2H8.48c-.38 0-.38 0-.42.38a27.11 27.11 0 0 0-.1 4.61l.12 1.32c.01.2.1.27.31.27h3.97c.29 0 .3-.03.21-.3l-.85-2.56c-.06-.18-.12-.35-.09-.54.1-.57.6-.87 1.19-.68l3.96 1.32c.2.07.29.06.29-.2 0-1.1-.03-2.22-.16-3.33-.03-.25-.12-.3-.34-.3-1.36.02-2.72.01-4.07.01Zm-.02-1.73h3.83c.2 0 .28-.04.23-.27a31.86 31.86 0 0 0-1.43-4.95c-.06-.16-.16-.22-.32-.26a10.37 10.37 0 0 0-4.62 0c-.16.04-.25.12-.31.28-.62 1.6-1.1 3.24-1.44 4.92-.04.22.01.29.24.28h3.81ZM10.82 17.5H8.64c-.22-.01-.26.06-.22.26.34 1.7.82 3.34 1.44 4.95.06.18.18.25.35.29 1.52.33 3.04.32 4.55 0 .22-.03.3-.1.21-.34l-1.64-4.92c-.06-.2-.16-.25-.35-.25h-2.16Zm-6.5-8.3L2.6 9.18c-.27-.01-.38.08-.45.33a10.6 10.6 0 0 0-.04 5.74c.15.57.06.5.64.5H6.1c.14 0 .29.02.26-.2-.06-.4-.07-.78-.1-1.17-.13-1.65-.09-3.3.1-4.94.02-.2-.04-.26-.23-.26h-1.8Zm17.51 7.31-7.98-2.66 2.64 7.92c.09-.03.1-.09.11-.13l1.42-3c.12-.26.28-.46.53-.58l1.08-.5 2.2-1.05ZM20.7 9.19h-1.46c-.4 0-.59.21-.55.63v.07c.12 1.18.13 2.35.1 3.52 0 .2.07.3.26.36l3.61 1.2c.23.08.31.01.35-.2.37-1.8.31-3.57-.2-5.33-.06-.2-.16-.25-.35-.25h-1.76ZM7.79 22.15c0-.1 0-.13-.02-.16a29.4 29.4 0 0 1-1.1-4.28c-.04-.2-.16-.22-.32-.22H3.24c-.23 0-.25.05-.14.24a10.72 10.72 0 0 0 4.7 4.42ZM17.17 2.8l.03.18c.47 1.38.84 2.8 1.1 4.23.04.2.13.25.32.25h3.06c.31 0 .32-.02.16-.3a10.8 10.8 0 0 0-4.67-4.36Zm-9.35.03-.14.05a10.73 10.73 0 0 0-4.55 4.33c-.12.21-.05.25.15.25h3.09c.21 0 .28-.1.31-.28.14-.77.32-1.52.52-2.28.18-.68.4-1.35.62-2.07Z"/>
  </svg>

)

export const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path fill="currentColor" d="M8.73 2.91A5.81 5.81 0 0 0 2.9 8.73v14.54c0 3.21 2.6 5.82 5.82 5.82h14.54c3.21 0 5.82-2.6 5.82-5.82V8.73c0-3.22-2.6-5.82-5.82-5.82H8.73ZM0 8.73C0 3.9 3.9 0 8.73 0h14.54C28.1 0 32 3.9 32 8.73v14.54C32 28.1 28.1 32 23.27 32H8.73A8.73 8.73 0 0 1 0 23.27V8.73Z"/>
    <path fill="currentColor" d="M16.7 11.63a4.36 4.36 0 1 0-1.78 8.54 4.36 4.36 0 0 0 1.78-8.54Zm-3.97-2.15a7.27 7.27 0 1 1 6.95 12.78 7.27 7.27 0 0 1-6.95-12.78ZM22.54 8c0-.81.66-1.47 1.45-1.47h.03a1.46 1.46 0 1 1 0 2.92h-.03c-.8 0-1.45-.66-1.45-1.45Z"/>
  </svg>
)

export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 31 31">
    <path fill="currentColor" d="M19 7.79c-.2-.05-.2 0-.33.13-.14.14-.17.13-.13.33v3.36h6.07l-1.95 7.79h-4.13V31h-7.8V19.4H6.4v-7.8h4.35V8.26c.04-2.21.98-4.4 2.42-5.84A8.68 8.68 0 0 1 19 0h5.33v7.79h-5.34Zm3.33-5.8H19a5.82 5.82 0 0 0-4.43 1.84 5.82 5.82 0 0 0-1.83 4.43v5.34H8.4v3.8h4.34V29h3.8V17.4h4.57l.95-3.79h-5.5V8.25c.03-.61.31-1.35.7-1.74A3 3 0 0 1 19 5.8h3.34v-3.8Z" />
  </svg>
)

export const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path fill="currentColor" d="M5.21.01h4.27c.23 0 .38.07.5.26l7.5 11.08c.25.37.24.36.52.04l8.45-9.98c.34-.39.68-.78 1-1.18a.55.55 0 0 1 .48-.22h2.15c.08 0 .2-.04.24.06.04.1-.06.17-.11.24l-4.63 5.46-6.43 7.58c-.18.21-.08.35.03.52L24 20.98l6.75 9.97.45.67c.07.1.2.2.14.32-.05.1-.2.04-.32.05H22.55a.6.6 0 0 1-.55-.3l-7.9-11.66c-.26-.4-.27-.4-.58-.03L4.97 30.1c-.49.56-.97 1.12-1.45 1.7a.5.5 0 0 1-.42.2c-.74-.02-1.48-.01-2.21-.01-.08 0-.19.03-.23-.05-.05-.09.05-.15.1-.2L8 23.2l4.4-5.2c.17-.18.13-.3.01-.48L5.78 7.73C4.1 5.26 2.43 2.8.77.33.7.25.6.16.66.06.7-.04.84.01.94.01h4.27Zm20.32 29.94h1.72c.09 0 .21.04.26-.04.07-.1-.06-.18-.12-.26L20.74 20 8.64 2.41a.52.52 0 0 0-.48-.25H4.78c-.1 0-.24-.04-.3.04-.06.12.08.2.14.3l11.17 16.22c2.52 3.66 5.05 7.32 7.56 10.99.12.17.25.25.46.24h1.72Z" />
  </svg>
)


export const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 25 24">
    <path fill="currentColor" d="M14.03 24H8.86V8h4.95v2.08c.1.02.11-.02.13-.05a5.2 5.2 0 0 1 3.7-2.34 8 8 0 0 1 3.64.23 4.59 4.59 0 0 1 3.17 3.1c.3.87.43 1.77.49 2.67.1 1.87.04 3.73.05 5.6v4.33c0 .1.03.21-.03.33h-5.13c-.06-.12-.03-.23-.03-.35v-7.35c0-.76-.01-1.52-.17-2.27a4.88 4.88 0 0 0-.11-.4c-.31-.99-1-1.52-2.07-1.61-.5-.05-.98 0-1.45.13-.96.28-1.47.96-1.73 1.85-.2.7-.24 1.42-.24 2.15V24ZM5.6 8V24H.44V8.02C.64 7.96 5.1 7.94 5.6 8ZM2.98 5.8c-1.64 0-3-1.34-2.98-2.94A2.97 2.97 0 0 1 3.03 0c1.65.01 3.01 1.34 2.99 2.91a2.98 2.98 0 0 1-3.04 2.9Z"/>
  </svg>
)

