import * as React from 'react';

interface Props {
  description: string;
}

export default class HtmlDescription extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  htmlDecode(input: string): string {
    let e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : String(e.childNodes[0].nodeValue);
  }

  render() {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
    );
  }
}
