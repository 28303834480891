import algoliasearch, { SearchIndex } from 'algoliasearch/lite'

import { SearchFilters, SearchState } from '../reducers/search'
import { SearchOptions } from '@algolia/client-search'
import { SearchHit } from 'reducers/search'

import addFilesToData from 'REST/utils/addFilesToData'
import config from 'config'

const searchClient = algoliasearch(config.algolia.APP_ID, config.algolia.SEARCH_API_KEY)
const searchIndex = searchClient.initIndex('ITEMS_AND_COLLECTIONS')
const searchIndexChronological = searchClient.initIndex('ITEMS_AND_COLLECTIONS_sort_newest')
const searchIndexViewsSort = searchClient.initIndex('ITEMS_AND_COLLECTIONS_sort_views')

const searchIndexMap = {
  'default': searchIndex,
  'newest': searchIndexChronological,
  'views': searchIndexViewsSort
}

export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTER = 'SET_FILTER'
export const MERGE_STATE = 'MERGE_STATE'
export const RESTORE_INITIAL = 'RESTORE_INITIAL'
export const CLEAR = 'CLEAR'

const searchOptions: SearchOptions = {
  filters: 'status:true',
  facets: ['*']
}

// ---------------

export const setFilters = (filters: SearchFilters, merge = false) => (dispatch) => {
  dispatch({
    type: SET_FILTERS,
    filters,
    merge
  })
}

export const setFilter = (name: string, value: any, withReset = false) => (dispatch) => {
  console.log('setting filter', name, value, withReset);
  
  dispatch({
    type: SET_FILTER,
    name,
    value,
    withReset
  })
}

export const doInitialSearch = () => async (dispatch, getState) => {
  const searchState: SearchState = getState().search
  await search(searchIndexChronological, '', '', searchState.pagination, 0, true, [], false, dispatch)
}

export const doSearch = (val: string, filters?: SearchFilters, titleOnly?: boolean) => async (dispatch, getState) => {
  const searchState: SearchState = getState().search
  const filtersQuery = filters ? getQueryForFilters(filters) : ''
  const index = filters ? searchIndexMap[filters.sortBy] || searchIndex : searchIndex

  await search(index, val, filtersQuery, searchState.pagination, 0, false, [], titleOnly, dispatch)
}

export const nextPage = () => async (dispatch, getState) => {
  const searchState: SearchState = getState().search
  const filtersQuery = getQueryForFilters(searchState.filters)
  const nextPageNumber = searchState.currentPage + 1
  const currentRecords = searchState.searchHits
  const index = searchIndexMap[searchState.filters.sortBy] || searchIndex

  await search(index, searchState.lastSearchString, filtersQuery, searchState.pagination, nextPageNumber, false, currentRecords, false, dispatch)
}

export const restoreInitialSearch = () => async (dispatch, getState) => {
  const searchState: SearchState = getState().search

  if (searchState.initial?.length) {
    dispatch({ type: RESTORE_INITIAL })
  } else {
    dispatch(doInitialSearch())
  }
}

export const clearSearch = () => async (dispatch, getState) => {
  dispatch({ type: CLEAR })
}

// ---------------

const getQueryForFilters = (filters: SearchFilters) => {
  const filtersQuery: string[] = []

  if (filters.creator) filtersQuery.push(`creators:"${filters.creator}"`)
  if (filters.tags) filtersQuery.push(`concept_tags:"${filters.tags}"`)
  if (filters.regions) filtersQuery.push(`regions:"${filters.regions}"`)

  if (filters.fileType === 'Text') filtersQuery.push('(item_type:PDF OR item_type:Text OR item_type:DownloadText)')
  else if (filters.fileType === 'Video') filtersQuery.push('(item_type:Video OR item_type:VideoEmbed)')
  else if (filters.fileType) filtersQuery.push('item_type:' + filters.fileType)

  if (filters.viewTypes && filters.viewTypes !== 'all') filtersQuery.push('record_type:' + filters.viewTypes)

  return filtersQuery.join(' AND ')
}

const search = async (
    index: SearchIndex,
    val: string,
    filters = '',
    pagination: number,
    page: number,
    setDefault = false,
    toPrepend: SearchHit[] = [],
    titleOnly = false,
    dispatch
  ) => {
    const options = {
      ...searchOptions,
      hitsPerPage: pagination,
      filters: filters ? `${searchOptions.filters} AND ${filters}` : searchOptions.filters,
      page
    }

    if (titleOnly) options.restrictSearchableAttributes = ['title']

    console.log(`Searching for ${val} with filters:`, options.filters)

    // Perform search on index and save to state
    const result = await index.search(val, options)

    let stateUpdates = {
      searchHits: [ ...toPrepend, ...result.hits ],
      totalHits: result.nbHits,
      currentPage: result.page,
      facets: result.facets,
      lastSearchString: val,
      filesFetched: false
    } as any
    if (setDefault) stateUpdates.initial = result.hits || []

    dispatch({
      type: MERGE_STATE,
      state: stateUpdates
    })

    // Add files to search results
    const hitsWithFiles = await addFilesToData(result.hits)

    stateUpdates = {
      searchHits: [ ...toPrepend, ...hitsWithFiles ],
      filesFetched: true
    } as any
    if (setDefault) stateUpdates.initial = hitsWithFiles || []

    dispatch({
      type: MERGE_STATE,
      state: stateUpdates
    })
}
