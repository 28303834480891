import { Button } from 'reactstrap'

import './HighlightItem.scss'

const HighlightItem = () => {
  return (
    <div className="highlight-item highlight-item--loading">
      <div className="highlight-item__image" />
      <h3 className="highlight-item__title">0</h3>
      <div className="highlight-item__summary">0</div>
      <div className="highlight-item__tags" style={{marginTop: 10, marginBottom: 10}}>
        { [ ...Array(5) ].map((tag, i) => (
          <Button
            className="page-link tag d-inline-block"
            style={{ padding: 0, marginBottom: 10 }}
            key={ i }
          >
            #{ i }
          </Button>
        )) }
      </div>
    </div>
  )
}

export default HighlightItem
