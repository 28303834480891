import { Item } from 'types/Item';

export interface PlayerQueueState {
  items: Item[],
  current: number,
  currentItem?: Item,
  hasMore: boolean
}

export default (state: PlayerQueueState, action) => {
  if (state === undefined) state = {
    items: [],
    current: -1,
    currentItem: undefined,
    hasMore: false
  }

  switch (action.type) {
    case 'ITEMS':
      return {
        items: action.items?.length ? action.items : [],
        current: -1,
        currentItem: undefined,
        // hasMore: state.current !== undefined ? action.items?.length - 1 > state.current : !!state.items.length
        hasMore: !!state.items.length
      }

    case 'SELECT':
      console.log(action);
      
      const isInRange = action.index > -1 && action.index < state.items.length
      if (!isInRange) return { ...state }

      return {
        ...state,
        current: action.index,
        currentItem: state.items[action.index],
        hasMore: action.index < state.items.length - 1
      }

    case 'NEXT':
      const current = state.hasMore
        ? state.current + 1
        : -1

      console.log(state, current)

      return {
        ...state,
        current,
        currentItem: current !== -1 ? state.items[current] : undefined,
        hasMore: current !== -1 ? state.items?.length - 1 > current : !!state.items.length
      }

    case 'PREV':
      return {
        ...state,
        current: state.current > 0 ? state.current - 1 : -1,
        currentItem: state.current > 0 ? state.items[state.current - 1] : undefined,
        hasMore: true
      }

    default:
      return state
  }
}
