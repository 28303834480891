import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { fetchItem } from 'actions/items/viewItem'
import { Alerts, ErrorMessage } from '../utils/alerts'
import { Item, itemType } from '../../types/Item'
import { Col, Row } from 'reactstrap'
import { ViewItemState } from 'reducers/items/viewItem'
import { itemURL } from 'urls'
import config from 'config'

type MatchParams = {
  id: string
}

interface Props extends RouteComponentProps<MatchParams>, Alerts {
  fetchItem: Function
  item: Item
}

interface State {
  errorMessage: string | undefined
  item: Item | undefined
}

export const getReplayIframeSrc = (s3_key, url) => {
  const bucketUrl = config.urls.BASE_CONTENT_URL
  return `/replayweb/index.html?source=${encodeURIComponent(bucketUrl + s3_key)}#view=pages&url=${encodeURIComponent(url + '')}`
}

class ViewWebArchive extends React.Component<Props, State> {
  constructor(props: any) { 
    super(props)

    this.state = {
      errorMessage: undefined,
      item: undefined
    }
  }

  componentDidMount() {
    const itemId = this.props.match?.params.id || null

    if (itemId) {
      this.props.fetchItem(itemId)
    } else {
      this.setState({ errorMessage: 'No item with that id.' })
    }
  }

  render() {
    if (typeof this.props.item === 'undefined') {
      return <ErrorMessage message={this.props.errorMessage} />
    }

    const { id, s3_key, url } = this.props.item

    return (
      <>
        <Link to={ itemURL(id) } className="home" style={{ position: 'fixed', top: '15px', left: '18px' }} title="Go back to item">
          <svg width="25px" height="23px" viewBox="0 0 295 233">
            <path d="M187.7,180.2 L241.4,180.2 L241.4,232.6 L187.7,232.6 L101.6,186.7 L66.6,232.6 L0,232.6 L86.1,120 L187.7,180.2 Z M241.3,180.2 L241.3,127.7 L295,127.7 L295,180.2 L241.3,180.2 Z"/>
            <path d="M283,10.1 L230.1,10.1 C230.8,18.4 229.8,37.6 219,44.7 C210.8,50.1 193.8,47.7 160.6,29.5 C156.1,27 151.6,24.7 147.2,22.6 C103.8,0.6 67.3,-7.5 41.8,9.5 C8.6,31.6 9.5,80.7 10.8,98.2 L63.7,98.2 C63,89.9 63.7,70.8 74.6,63.8 C82.8,58.4 100.8,60.8 133.9,79 C138.4,81.5 142.9,83.8 147.3,85.9 C190.7,107.9 227.1,114.2 252.6,97.2 C285.8,75.1 284.3,27.6 283,10.1"/>
          </svg>
        </Link>
        <iframe src={getReplayIframeSrc(s3_key, url)} frameBorder="0" style={{ width: '100%', height: '100vh' }} />
      </>
    )
  }
}

const mapStateToProps = (state: { viewItem: ViewItemState }) => ({
  errorMessage: state.viewItem.errorMessage,
  item: state.viewItem.item
})

// Connect our redux store State to Props, and pass through the fetchItem function.
export default withRouter(connect(mapStateToProps, {
  fetchItem,
})(ViewWebArchive))
