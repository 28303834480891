import { Item } from '../../types/Item';
import { checkFile } from '../items/viewItem';
import { getById, getCollectionsInCollection, getItemsInCollection } from '../../REST/collections';
import { removeTopology } from '../../components/utils/removeTopology';
import { Collection } from '../../types/Collection';
import { S3File } from '../../types/s3File';
import { getItem } from '../../REST/items';
import { WP_REST_API_Post } from 'wp-types';

/**
 *
 * API call to get collection information based on the collectionID 
 *
 * @param id {string}
 */
export const getAndPrepareCollection = async (id: string) => {
  const response = await getById(id);
  const collections = removeTopology(response) as Collection[];
  const collection = (!!collections && collections.length && !!collections[0] && collections[0].id) ? collections[0] : undefined;
  return collection;
};


const getItemsAndCollectionsInCollection = async (id: string, offset: number = 0, limit: number = 1): Promise<(Item | Collection | WP_REST_API_Post)[]> => {
  const itemResponse = await getItemsInCollection({id, limit, offset});
  const collectionResponse = await getCollectionsInCollection({id, limit, offset});
  return [...removeTopology(itemResponse, 'item'), ...removeTopology(collectionResponse, 'collection')];
};

export const loadMore = async (id: string, offset: number = 0, callback: Function) => {
  try {
    const data = await getItemsAndCollectionsInCollection(id, offset, 100);
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]) {
          let file: S3File | false = false;
          if (data[i].__typename === 'item') {
            file = await checkFile(data[i] as unknown as Item);
          } else if (data[i].__typename === 'collection') {
            const collection: Collection = data[i] as Collection;
            if (collection && collection.s3_key && collection.s3_key.length) {
              if (collection.s3_key[0]) {
                const getItemResponse: Item[] = removeTopology(await getItem({s3Key: collection.s3_key[0]})) as Item[];
                file = await checkFile(getItemResponse[0]);
              }
            }
          }

          if (file) {
            Object.assign(data[i], {file});
          }
        }
      }

      callback(data);
    } else {
      callback(false);
    }
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    throw Error('We\'ve had a bit of an issue.');
  }
};

