import { random } from 'lodash'

const fetchRandomWaveform = () => {
  const randFileNo = random(1, 9)
  return fetch(`https://prod-content.ocean-archive.org/waveform-${randFileNo}.json`)
}

export const getWaveformData = async (s3_key?: string) => {
  const jsonKey = s3_key?.substring(0, s3_key?.lastIndexOf('.')) + '.json' || ''

  const response = s3_key
    ? await fetch(`https://audio.ocean-archive.org/${jsonKey}`)
    : await fetchRandomWaveform()

  let data

  if (response?.ok) {
    data = ( await response.json() ).data
  } else {
    const randomWaveForm = await fetchRandomWaveform()
    data = ( await randomWaveForm.json() ).data
  }

  return data
}
