import * as React from 'react';
import { getCurrentCredentials } from '../Auth';
import { get } from 'lodash';
import { CognitoIdentityProviderClient, AdminConfirmSignUpCommand } from '@aws-sdk/client-cognito-identity-provider'; 
import config from '../../../config';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Alerts, ErrorMessage } from '../alerts';

interface State extends Alerts {
  userId?: string;
  userEmail?: string;
  isOpen?: boolean | undefined;
  callback?: Function;
}

export class ConfirmUser extends React.Component<{}, State> {
  cognitoIdentityServiceProviderClient;

  constructor(props: {}) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  async componentDidMount(): Promise<void> {
    const credentials = await getCurrentCredentials();

    this.cognitoIdentityServiceProviderClient = new CognitoIdentityProviderClient({
       region: config.cognito.REGION,
       credentials: {
         accessKeyId: get(credentials, 'accessKeyId'),
         sessionToken: get(credentials, 'sessionToken'),
         secretAccessKey: get(credentials, 'secretAccessKey'),
       }
     });

    this.setState({
      isOpen: this.state.isOpen
    });
  }

  /**
   * Get the user details
   */
  loadDetails(userId: string, userEmail: string, callback: Function) {
    if (userId) {
      this.setState({
        userId: userId,
        userEmail: userEmail,
        callback: callback,
        isOpen: true
      });
    } else {
      this.setState({ errorMessage: 'No user id', isOpen: true });
    }
  }
  /**
   * Confirming signup
   */
  confirmUser = async (): Promise <void> => {
    try {
      const command = new AdminConfirmSignUpCommand({
        Username: this.state.userId,
        UserPoolId: config.cognito.USER_POOL_ID
      });
      await this.cognitoIdentityServiceProviderClient.send(command);
      if (typeof this.state.callback === 'function') {
        this.state.callback();
      }
    } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      this.setState({ errorMessage:  `Something went wrong, please try again later. (${e.message})` });
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      errorMessage: undefined
    }));
  };
  /**
   * Hide the button if there's a message
   */
  confirmButton = () => {
    if (this.state.errorMessage) {
      return <></>;
    } else {
      return <Button color="danger" onClick={this.confirmUser}>Confirm</Button>;
    }
  };

  render() {
    const hasError = this.state.errorMessage;

    return (
      <Modal isOpen={this.state.isOpen} toggle={this.toggleModal}>
        <ModalHeader>Confirm User?</ModalHeader>
        <ModalBody>
          {
            hasError ?
              <ErrorMessage message={this.state.errorMessage}/>
              :
              <>Confirm {this.state.userEmail ? this.state.userEmail : this.state.userId}?</>
          }
        </ModalBody>
        <ModalFooter>
          {this.confirmButton()}
          <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
