import React from 'react'

const TextWithNewlines = ({ text }: { text?: string | null }) => {
  return (
    <>
      { text?.split('\n').map((it, i) => (
        <React.Fragment key={ i }>{ it }<br/></React.Fragment>
      )) || '' }
    </>
  )
}

export default TextWithNewlines