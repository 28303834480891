import * as React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'

import HomeIntro from 'components/home/HomeIntro'
import HomeCarousel from 'components/home/HomeCarousel'
import HomeHighlights from 'components/home/HomeHighlights'
import HomeExplore from 'components/home/HomeExplore'
import HomeCommunity from 'components/home/HomeCommunity'
import Footer from 'components/layout/Footer'

import { HighlightsState } from '../../reducers/highlights'
import { SearchConsoleState } from '../../reducers/searchConsole'
import { StoryListState } from '../../reducers/story/storyList'
import { fetchHighlights } from '../../actions/highlights'
import { fetchStoriesInitial } from '../../actions/story/storyList'

import { APITag } from 'components/metadata/Tags'

import { FETCH_STORIES_LOADING } from '../../actions/story/storyList'
import { itemURL, collectionURL, storyURL } from 'urls'
import config from 'config'

interface Props {
  highlights: HighlightsState
  concept_tags: APITag[]
  story_list: StoryListState
  fetchHighlights: Function
  fetchStoriesInitial: Function
}

interface State {
  communityHighlights: []
  carouselSlides: []
}

const communityHighlightsFile = `${config.urls.BASE_CONTENT_URL}community-highlights.json`

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      communityHighlights: [],
      carouselSlides: []
    }
  }

  async componentDidMount(): Promise<void> {
    document.title = 'Ocean Archive'

    const carouselSettings = await API.get('tba21', 'admin/settings', { queryStringParameters: { name: 'home_carousel_items' } })
    this.setState(prev => ({ ...prev, carouselSlides: carouselSettings.home_carousel_items || [] }))

    if (this.props.highlights.data?.length === 0) {
      this.props.fetchHighlights()
    }
    if (this.props.story_list.stories.length < 4 && this.props.story_list.hasMore) {
      this.props.fetchStoriesInitial({ order: 'desc', orderBy: 'date', page: 1, perPage: 4 })
    }
    if (this.state.communityHighlights.length === 0) {
      try {
        const response = await fetch(communityHighlightsFile, { cache: 'no-cache' })
        this.setState({ communityHighlights: await response.json() })
      } catch (error) {
        console.error('Error fetching community highlights')
      }
    }
  }

  getIntroSurpriseLink = () => {
    const both = [ ...(this.props.highlights.data || []), ...(this.props.story_list.stories || []) ]
    if (!both.length) return ''

    const randomItem = both[ Math.floor(Math.random() * both.length) ]
    const randomItemID = randomItem.id as string

    return randomItem.item_type
      ? itemURL(randomItemID)
      : randomItem.type === 'post'
        ? storyURL(randomItem.slug)
        : collectionURL(randomItemID)
  }

  render() {
    return (
      <>
        <HomeIntro surpriseLink={ this.getIntroSurpriseLink() } />
        <HomeCarousel slides={ this.state.carouselSlides } />
        <HomeHighlights
          data={ this.props.highlights.data }
          loading={ this.props.highlights.isLoading }
          title="Ocean Archive highlights"
          subtitle="See recommendations of the Archive keeper"
          className="background-dark"
        />
        <HomeHighlights
          data={ this.props.story_list.stories.slice(0, 4) }
          loading={ this.props.story_list.status === FETCH_STORIES_LOADING }
          title="Journeys highlights"
          subtitle="Let the Journeys guide you by narrating the Archive’s many currents. You can also design a Journey of your own."
          className="background-rainbow"
        >
          <div className="text-center">
            <Link to="/stories" className="button mt-4">
              Read more Journeys
            </Link>
          </div>
        </HomeHighlights>
        <HomeExplore tags={ this.props.concept_tags } className="background-white" />
        <HomeCommunity data={ this.state.communityHighlights }>
          <div className="text-center">
            <a href="https://community.ocean-archive.org" target="_blank" className="button mt-4">
              Join the ocean comm/uni/ty
            </a>
          </div>
        </HomeCommunity>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = (state: { highlights: HighlightsState, storyList: StoryListState, searchConsole: SearchConsoleState }) => ({
  highlights: state.highlights,
  concept_tags: state.searchConsole.concept_tags,
  story_list: state.storyList
})

export default connect(mapStateToProps, { fetchHighlights, fetchStoriesInitial })(Home)
