import * as React from 'react';
import { connect } from 'react-redux';
import { PrivacyPolicyState } from 'reducers/pages/privacyPolicy';
import { modalToggle } from 'actions/pages/privacyPolicy';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import 'styles/components/modals/helpStoriesModal.scss';

interface Props extends PrivacyPolicyState {
  modalToggle: Function;
}

class HelpStoriesModal extends React.Component<Props, {}> {

  close() {
    const { location: { hash } } = window;
    if (hash && hash.endsWith('helpStoriesModal')) {
      history.pushState('', document.title, window.location.pathname
                                                       + window.location.search);
    }
    this.props.modalToggle('HELP_STORIES_MODAL');
  }

  render() {
    return (
      <Modal isOpen={this.props.help_stories_open} toggle={() => this.close()} className="help-stories-modal fullwidth">
        <ModalHeader className="help-stories-modal help-stories-modal-header" toggle={() => this.close()}>
          Welcome to STORIES... & how to create one
        </ModalHeader>
        <ModalBody className="help-stories-modal help-stories-modal-body">
          <h1>THIS IS YOUR STORY!</h1>
          <p>
            Welcome to Stories, a brand new tentacle of our digital ecosystem designed to
            inspire action and care for the Oceans. We developed Stories to help us navigate
            the many currents of content on the platform by adding narratives to the items and
            collections of the Archive.
          </p>
          <p>
            In all our activities - and Stories are no exception - we strive to act from a place
            of responsibility towards our audiences and the Ocean: a responsibility to reject
            hierarchies and binary separations, and to engage in listening as a radical act. We
            aim to foster inclusion not only by granting free access to programs and activities,
            but also by proactively encouraging and enabling the participation of diverse
            audiences with attention to accessibility.
          </p>
          <p>
            <strong>Now, we&apos;d like to invite you to write your own Story too.</strong><br />
            Whether you got inspired during a dive within the depths of Ocean-Archive.org,
            serendipitously stumbled across an interesting topic, or are already approaching
            with a tale to tell, Stories are here for you!
          </p>
          <h3>Format & Topic</h3>
          <p>
            A Story can be anything from an essay to an article, a learning resource, or a poem. However, no need to feel bound by these formats; we encourage you to express yourself in any way that feels authentic and supports your purpose, even if it means creating a new category!
          </p>
          <p>
            Is there a theme, a topic, or a trajectory to follow? This year, our digital streams will be focusing on Indigenous practices and epistemologies, the Mediterranean as a hotspot for climate change and adaptation, and the opaque world of deep- sea mining. Stories, however, are a place to voice your interests and opinions, whether or not they fall into any of these boxes.
          </p>
          <h3>Language & Accessibility</h3>
          <p>
            In your contributions, we kindly ask you to engage in respectful and intentional use of language without any discrimination on the basis of race, ethnicity, gender, sexual orientation, disability, nationality, religion, age, physical appearance, language, culture, education and economic status. Any hate speech or discriminatory content will be removed promptly.
          </p>
          <p>
            We would like this platform to be as inclusive as possible, but we still need your help.
          </p>
          <p>
            The language on the platform is English, however, we do accept contributions in other languages (these will not be proofread). Bilingual content is welcome too.
            If you upload your own content to accompany your Stories, please keep in mind its accessibility. We aim for all video files on the Archive to be captioned, and a transcription to be available for all audio files, however, we are not quite there yet - so your support with sticking to these principles when uploading new material is much appreciated.
          </p>
          <h3>Supporting material</h3>
          <p>
            Is using content from Ocean-Archive.org required? More or less, yes. Ocean-Archive.org serves as a tool to spark your research ignition, to simply support it, or to help you discover new ways of relating. It is also a place FOR your research and materials - meaning you can upload any material you own yourself. Sign up to find out more about the process and available licenses. If it&apos;s your first time, don&apos;t worry! A welcome email with useful links will find you soon after the registration.
          </p>
          <p>
            The reason why we ask you to include the content within your text is to breathe new life into the Archive. Supporting your thinking process with its material can illuminate unknown directions for other users to follow and discover their own paths. Every added item can work as a reference point to explore a specific topic or consider it in a different light.
          </p>
          <p>
            What if you already have an oceanic story without any reference material? Dive in! Try picking a couple of keywords and concept tags for your Story and using them in the search bar to explore what we have to offer. Find at least one or two assets and embed them into your Story— you can find a detailed guide on how to do it below.
          </p>
          <h3>Publishing & Citation</h3>
          <p>
            Even the sharpest pair of eyes can overlook a typo (and a few other things), so before sharing your Story in our digital waters, we want to make sure everything is in order. All contributions will be subject to <strong>sensitive editorial review</strong>. This means we will check for possible typographical errors and inappropriate content listed previously, but will not impose stylistic corrections on your text if support is not requested from your side. We believe that there is no one “good writing style” and that linguistic and stylistic curiosities are what makes contributions versatile and situated.
          </p>
          <p>
            We don&apos;t require contributions to adhere to any citation style guidelines - after all, this is a digital ecosystem, not an academic journal. However, we find it a matter of respect and accuracy to let your audience know if certain thoughts were quoted from or inspired by someone else, be it an author, or a community. The author(s)
            of a particular contribution is also responsible for the accuracy of the information provided in the piece.
            We wish you the best of luck with writing - and if you encounter any difficulties, don&apos;t hesitate to <a href="mailto:info@ocean-archive.org">contact us!</a>
          </p>
          <h2>How to create a Story?</h2>
          <p>
            <a href="https://prod-content.ocean-archive.org/private/eu-central-1:fd85f4c8-de23-4b7a-ae80-c6c4edf63ee7/46eef4a7-ad20-46ba-b17f-15c8db7031fd/167a28e0-9e11-11ec-b4e9-c113118a9508-StoriesGuidelines_SHARE.pdf" target="_blank" rel="noopener noreferrer">View and read</a> the technical guidelines, and start your first Story
          </p>
        </ModalBody >
      </Modal>
    );
  }
}

const mapStateToProps = (state: { privacyPolicy: PrivacyPolicyState }) => ({
  help_stories_open: state.privacyPolicy.help_stories_open,
});

export default connect(mapStateToProps, { modalToggle })(HelpStoriesModal);
