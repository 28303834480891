import { CognitoIdentityProviderClient, DeleteUserCommand } from '@aws-sdk/client-cognito-identity-provider'; 

import { API, Auth } from 'aws-amplify';
import { get } from 'lodash';

import { getCurrentAuthenticatedUser } from '../../components/utils/Auth';
import config from '../../config';

// Actions
export const OVERLAY = 'OVERLAY';
export const DELETED_ACCOUNT = 'DELETED_ACCOUNT';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_GET_DETAILS = 'PROFILE_GET_DETAILS';
export const PROFILE_ADD_FAVORITE_LOADING = 'PROFILE_ADD_FAVORITE_LOADING';
export const PROFILE_ADD_FAVORITE_SUCCESS = 'PROFILE_ADD_FAVORITE_SUCCESS';
export const PROFILE_ADD_FAVORITE_ERROR = 'PROFILE_ADD_FAVORITE_ERROR';
export const PROFILE_DELETE_FAVORITE_LOADING =
  'PROFILE_DELETE_FAVORITE_LOADING';
export const PROFILE_DELETE_FAVORITE_SUCCESS =
  'PROFILE_DELETE_FAVORITE_SUCCESS';
export const PROFILE_DELETE_FAVORITE_ERROR = 'PROFILE_DELETE_FAVORITE_ERROR';

/**
 * Dispatches to the PROFILE_ERROR reducer action.type
 */
export const dispatchError = (message: string) => (dispatch) => {
  dispatch({ type: PROFILE_ERROR, message: message });
};

export const overlayToggle = (on: boolean) => (dispatch) => {
  dispatch({ type: OVERLAY, overlay: on });
};

/**
 * Updates the users attributes in AWS Cognito
 *
 * @param attributes {Object: Name, Value}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateAttributes = (attributes: any) => async (dispatch) => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  try {
    const cognitoUser = await getCurrentAuthenticatedUser();

    // Puts an overlay over the entire page.
    dispatch({ type: OVERLAY, overlay: true });

    if (cognitoUser && typeof cognitoUser !== 'boolean') {
      // Update attributes on the users local Cognito storage.
      await Auth.updateUserAttributes(cognitoUser, attributes);
      dispatch({
        type: PROFILE_SUCCESS,
        message: 'We\'ve updated your profile',
      });
    } else {
      throw new Error('Not authed');
    } // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // Account with that email address already exists in Cognito.
    if (e && e.code === 'AliasExistsException') {
      dispatch({
        type: PROFILE_ERROR,
        message: 'An account with the given email already exists.',
        showForm: true,
      });
    } else {
      dispatch({
        type: PROFILE_ERROR,
        message: 'We\'re having some difficulties at the moment.',
      });
    }
  }
};

/**
 * Deletes the users account from AWS Cognito
 */
export const deleteAccount = () => async (dispatch) => {
  // Checks that the user is Authenticated and has an accessToken from aws-amplify
  const accessToken = get(
    await getCurrentAuthenticatedUser(),
    'signInUserSession.accessToken.jwtToken'
  ) as unknown as string;

  // Puts an overlay over the entire page.
  dispatch({ type: OVERLAY, overlay: true });

  // No accessToken? Have an error
  if (accessToken && accessToken.length) {
    try {
      // Sets up CognitoIdentityServiceProvider with our correct region and API version
      const client = new CognitoIdentityProviderClient({ region: config.cognito.REGION });
      const command = new DeleteUserCommand({
        AccessToken: accessToken,
      });
      const response = await client.send(command);
      console.log(response); // temp. for double checking

      dispatch({ type: DELETED_ACCOUNT });

      await API.del('tba21', 'profiles', {});
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        message:
          'We\'ve had trouble removing your account, please try again.',
      });
    }
  } else {
    dispatch({
      type: PROFILE_ERROR,
      message: 'You don\'t seem to be logged in.',
    });
  }
};

/**
 * Updates the users Password.
 *
 * @param oldPassword {string}
 * @param newPassword {string}
 */
export const changePassword =
  (oldPassword: string, newPassword: string) => async (dispatch) => {
    const cognitoUser = await getCurrentAuthenticatedUser();

    if (cognitoUser && typeof cognitoUser !== 'boolean') {
      try {
        await Auth.changePassword(cognitoUser, oldPassword, newPassword);
        dispatch({
          type: PROFILE_SUCCESS,
          message: 'Your password has been changed.',
        }); // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        const message = e.message
          ? e.message
          : 'We\'re having some difficulties at the moment.';
        dispatch({ type: PROFILE_ERROR, message: message });
      }
    }
  };

export const getCurrentUserProfileById = (profileId) => async (dispatch) => {
  try {
    dispatch({
      type: OVERLAY,
      overlay: true,
    });
    const result = await API.get('tba21', 'currentUserProfile', {});

    let profileImage: string | undefined = undefined;
    if (result.profile.profile_image) {
      profileImage = await checkProfileImageExists(
        result.profile.profile_image
      );
    }

    dispatch({
      type: PROFILE_GET_DETAILS,
      details: { ...result.profile, profile_image: profileImage },
    });
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    console.log(e.message);
    dispatch({
      type: PROFILE_ERROR,
      message: e.message,
    });
  }
};

export const getCurrentUserProfile = () => async (dispatch, getState) => {
  getCurrentUserProfileById(getState().profile.id);
};

export const deleteUserFavourite =
  (type: 'stories' | 'items' | 'collections', id: number) =>
  async (dispatch, getState) => {
    await getCurrentUserProfile();
    const profileDetails = getState().profile.details || {};
    const profileId = getState().profile.id;
    try {
      dispatch({
        type: PROFILE_DELETE_FAVORITE_LOADING,
      });
      await API.patch('tba21', 'profiles', {
        body: {
          favourites: {
            ...profileDetails.favourites,
            [type]: (profileDetails.favourites[type] || []).filter(
              (oldId) => oldId !== id
            ),
          },
        },
      });

      dispatch(getCurrentUserProfileById(profileId));

      dispatch({
        type: PROFILE_DELETE_FAVORITE_SUCCESS,
      });
    } catch {
      dispatch({
        type: PROFILE_DELETE_FAVORITE_ERROR,
      });
    }
  };

export const addUserFavourite =
  (type: 'stories' | 'items' | 'collections', id: number) =>
  async (dispatch, getState) => {
    await getCurrentUserProfile();
    const profileDetails = getState().profile.details || null;
    const profileId = getState().profile.id;

    if (profileDetails === null) {
      window.alert('Log in or create an account to favourite a story.');
      return;
    }

    try {
      dispatch({
        type: PROFILE_ADD_FAVORITE_LOADING,
      });
      const favouriteByType = profileDetails.favourites ? (profileDetails.favourites[type] || []) : [];

      if (!favouriteByType.includes(id)) {
        await API.patch('tba21', 'profiles', {
          body: {
            favourites: {
              ...(profileDetails.favourites || {} ),
              [type]: favouriteByType.concat(id),
            },
          },
        });
      }

      dispatch(getCurrentUserProfileById(profileId));

      dispatch({
        type: PROFILE_ADD_FAVORITE_SUCCESS,
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: PROFILE_ADD_FAVORITE_ERROR,
      });
    }
  };

export const checkProfileImageExists = async (
  imageURL: string
): Promise<string | undefined> => {
  if (imageURL) {
    try {
      await fetch(imageURL, {
        mode: 'cors',
        method: 'HEAD',
      });
      return imageURL;
    } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      return undefined;
    }
  } else {
    return undefined;
  }
};
