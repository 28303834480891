import { SET_THEME } from 'actions/global'

export const DEFAULT_THEME = 'dark'

export interface GlobalState {
  theme: string
}

const initialState = {
  theme: DEFAULT_THEME
}

export default (state: GlobalState = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.theme
      }
    default:
      return state
  }
}