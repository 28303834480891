export const getLicenceLink = (licence: string) => {
  switch (licence) {
    case 'CC BY':
      return 'https://creativecommons.org/licenses/by/4.0/legalcode'
    case 'CC BY-SA':
      return 'https://creativecommons.org/licenses/by-sa/4.0/legalcode'
    case 'CC BY-ND':
      return 'https://creativecommons.org/licenses/by-nd/4.0/legalcode'
    case 'CC BY-NC':
      return 'https://creativecommons.org/licenses/by-nc/4.0/legalcode'
    case 'CC BY-NC-SA':
      return 'https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode'
    case 'CC BY-NC-ND':
      return 'https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode'
    case 'YouTube':
      return 'https://www.youtube.com/static?template=terms'
    case 'Vimeo':
      return 'https://vimeo.com/terms'
    case 'CC PD':
      return 'https://creativecommons.org/publicdomain/zero/1.0/legalcode'
    default:
      return null
  }
}
