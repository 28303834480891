import classnames from 'classnames'
import './RollingHeader.scss'

const RollingHeader = ({ absolute = false, dark = false, noMargin = false }: { absolute?: boolean, dark?: boolean, noMargin?: boolean }) => {
  let classes = classnames(
    'rolling-header',
    { 'rolling-header--dark': dark },
    { 'rolling-header--absolute': absolute },
    { 'rolling-header--no-margin': noMargin }
  )

  return (
    <div className={ classes } />
  )
}

export default RollingHeader