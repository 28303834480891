import * as React from 'react'
import { connect } from 'react-redux'
import { FiClock } from 'react-icons/fi'
import { IoPlay } from 'react-icons/io5'

import { Item } from 'types/Item'
import { itemURL } from 'urls'
import { secondsToTimeString } from 'components/utils/humanTime'
import { updateQueueItems, selectQueueItem } from 'actions/playerQueue'

import './AudioPlaylist.scss'
import { PlayerQueueState } from 'reducers/playerQueue'
import classNames from 'classnames'

interface Props {
  items?: Item[]
  currentItem?: string | number
  baseUrl?: string
  onSelect?: Function
  queue: PlayerQueueState
  updateQueue: Function
  select: Function
  compact?: boolean | undefined
}

const AudioPlaylist = ({ items, currentItem, baseUrl, onSelect, queue, select, updateQueue, compact }: Props) => {

  const onItemClick = (item: Item, i, e) => {
    e.preventDefault()

    // updateQueue(items?.slice(i))
    if (!queue.items.length) updateQueue(items)
    select(i)
    onSelect?.(item.id)
  }

  const currentId = currentItem || queue.items[queue.current]?.id

  const cls = classNames('playlist', { 'playlist--compact': compact })

  return (
    <div className={cls}>
      <div className="playlist__table">
        { !compact && (
          <div className="playlist__row">
            <div>#</div>
            <div>Title</div>
            <div className="pb-1"><FiClock size={ 20 } /></div>
          </div>
        ) }

        { items?.map((it, i) => (
          <div className={ 'playlist__row' + (currentId === it.id ? ' active' : '') } key={ it.id }>
            <div className="playlist__row-number">
              <span>{ i + 1 }</span>
              <IoPlay size="1.2em" onClick={ (e) => onItemClick(it, i, e) } />
            </div>
            <div>
              <a
                href={ baseUrl ? `?play=${it.id}` : itemURL(it.id) }
                onClick={ (e) => onItemClick(it, i, e) }
              >
                { it.title }
              </a>
              <br />
              <span className="playlist__authors">{ it.creators?.join(', ') || '' }</span>
            </div>
            <div>{ it.duration && secondsToTimeString(it.duration) || '' }</div>
          </div>
        )) }
      </div>
    </div>
  )
}

const mapStateToProps = (state: { playerQueue: PlayerQueueState }) => ({ // eslint-disable-line @typescript-eslint/no-explicit-any
  queue: state.playerQueue
})

export default connect(mapStateToProps, { updateQueue: updateQueueItems, select: selectQueueItem })(AudioPlaylist)
