export const viewProfileURL = (id: string): string => `/profiles/${id}`;
export const portfolioURL = (id: string): string => `/portfolios/${id}`;

export const itemURL = (id: string | number): string => `/view/${id}`;

export const embedCollectionURL = (id: string | number): string => `/embedcollection/${id}`;
export const collectionURL = (id: string | number): string => `/collection/${id}`;

export const iframeItemEmbedCodeURL = (id: string, title: string, type: string, url: string): string => {
    // ref: https://faq.dailymotion.com/hc/en-us/articles/360022841393-How-to-preserve-the-player-aspect-ratio-on-a-responsive-page
  if (type === 'Video') {
return `<div style="position:relative;padding-bottom:56.25%;">
  <iframe style="width:100%;height:100%;position:absolute;left:0px;top:0px;"
  frameborder="0" width="100%" height="100%" 
  allowfullscreen allow="autoplay"
  src="https://${window.location.hostname}/embed/${id}" title="${title}"><!-- Sizes may need tweaking but should be good for 16:9 ratio videos--></iframe></div>`; 
}

  if (type === 'Audio') {
return `<iframe width="100%" height="130px" src="https://${window.location.hostname}/embed/${id}" title="${title}"><!-- width="npx" height="npx" for pixel dimensions, or percentages, may need to be added to the iframe or a surrounding <div></div> --></iframe>`; 
}

  if (type === 'IFrame') {
return `<iframe src="${url}" title="${title}"><!-- width="npx" height="npx" for pixel dimensions, or percentages, may need to be added to the iframe or a surrounding <div></div> --></iframe>`; 
}

  return `<iframe src="https://${window.location.hostname}/embed/${id}" title="${title}"><!-- width="npx" height="npx" for pixel dimensions, or percentages, may need to be added to the iframe or a surrounding <div></div> --></iframe>`;

};

export const iframeCollectionEmbedCodeURL = (id: string, title): string => {
  return `<iframe width="100%" height="600px" src="https://${window.location.hostname}/collection/${id}" title="${title}"><!-- width="npx" height="npx" for pixel dimensions, or percentages, may need to be added to the iframe or a surrounding <div></div> --></iframe>`;
};

export const iframeAudioCollectionEmbedCodeURL = (id: string, title): string => {
  return `<iframe width="100%" height="350px" src="https://${window.location.hostname}/embed-collection/${id}" title="${title}"><!-- width="npx" height="npx" for pixel dimensions, or percentages, may need to be added to the iframe or a surrounding <div></div> --></iframe>`;
};

export const storiesURL = (): string => '/';

export const storyURL = (slug: string): string => `/story/${slug}`;

export const webArchiveURL = (id: string): string => `/webarchive/${id}`;
