import { ReactNode } from 'react'

interface Props {
  checked?: boolean
  onChange?: (checked: boolean) => void
  children?: ReactNode
  className?: string
}

const Checkbox = ({ checked, onChange, children, className }: Props) => (
  <label className={ 'oa-checkbox' + (className ? ` ${className}` : '') }>
    <input
      type="checkbox"
      checked={ checked }
      onChange={(e) => onChange?.(e.target.checked)}
    />
    <span>{ children }</span>
  </label>
)

export default Checkbox