import { combineReducers, ReducersMapObject } from 'redux';
import viewItems from '../reducers/items/viewItems';
import viewItem from '../reducers/items/viewItem';
import loadingOverlay from './loadingOverlay';
import map from '../reducers/map/map';
import home from '../reducers/home';
import userHistory from './user-history';
import profile from '../reducers/user/profile';
import manageUsers from '../reducers/admin/user/manageUsers';
import searchConsole from '../reducers/searchConsole';
import audioPlayer from '../reducers/audioPlayer';
import playerQueue from '../reducers/playerQueue';
import viewProfile from '../reducers/user/viewProfile';
import privacyPolicy from 'reducers/pages/privacyPolicy';
import itemModal from 'reducers/modals/itemModal';
import collectionModal from 'reducers/modals/collectionModal';
import liveStreamModal from 'reducers/modals/liveStreamModal';
import viewStory from './story/viewStory';
import storyList from './story/storyList';
import highlights from './highlights';
import search from './search';
import global from './global';
import portfolios from './user/portfolios';

const reducers: ReducersMapObject = {
  global,

  viewItems,
  viewItem,
  viewProfile,
  userHistory,

  highlights,
  search,

  loadingOverlay,

  map,

  home,
  searchConsole,
  audioPlayer,
  playerQueue,
  privacyPolicy,
  itemModal,
  collectionModal,
  liveStreamModal,

  // User
  profile,
  portfolios,

  // Admin
  manageUsers,
  viewStory,
  storyList,
};

export default combineReducers(reducers);
