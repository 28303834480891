/* tslint:disable */
// Opera 8.0+
// @ts-ignore
// eslint-disable-next-line
const isOpera = (navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1
// Firefox 1.0+
// @ts-ignore
const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1
// Safari 3.0+ "[object HTMLElementConstructor]"
// @ts-ignore
// eslint-disable-next-line
const isSafari = navigator.userAgent.indexOf('Safari') !== -1
// Internet Explorer 6-11
// @ts-ignore
const isIE = /* @cc_on!@*/false || !!document.documentMode;
// Edge 20+
// @ts-ignore
const isEdge = !navigator.userAgent.indexOf('Edge') !== -1
// Chrome 1 - 71
// @ts-ignore
const isChrome = navigator.userAgent.indexOf('Chrome') !== -1
// Blink engine detection
// @ts-ignore
const isBlink = (isChrome || isOpera) && !!window.CSS;

// IOS
// 'standalone' in navigator thanks to https://medium.com/@firt/iphone-11-ipados-and-ios-13-for-pwas-and-web-development-5d5d9071cc49
const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i) || 'standalone' in navigator;
/* tslint:enable */

export const browser = (): string => {
 if (isOpera) {
    // Opera 8.0+
    return 'opera';
 } else if (isFirefox) {
   // Firefox 1.0+
  return 'firefox';
 } else if (isSafari) {
   // Safari 3.0+ "[object HTMLElementConstructor]"
  return 'safari';
 } else if (isIE) {
  // Internet Explorer 6-11
   return 'ie6-11';
 } else if (!isIE && isEdge) {
  // Edge 20+
  return 'edge';
 } else if (isChrome) {
   return 'chrome';
 } else if (isBlink) {
   return 'blink';
 }  else if (isIOS) {
   return 'ios';
 } else {
   return 'unknown';
 }
};
