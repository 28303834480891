import { ReactNode, useEffect, useRef } from 'react'
import { IoSearchSharp } from 'react-icons/io5'
import { RouteComponentProps, withRouter } from 'react-router'

import './SearchIntro.scss'

interface Props extends RouteComponentProps {
  children?: ReactNode
  onInput: Function
}

const SearchIntro = ({ children, onInput, location }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (location.state?.searchString && inputRef.current) {
      inputRef.current.value = location.state.searchString
    }
  }, [location])

  return (
    <section className="search-intro">
      <div className="search-intro__text">
        <h1>〜  Search the ocean database</h1>
      </div>
      <div className="search-intro__input">
        <input
          ref={ inputRef }
          type="text"
          placeholder="Start typing to search"
          onChange={ e => onInput(e.target.value) }
        />
        <IoSearchSharp className="search-intro__input-icon" size="26px" />
        { children }
      </div>
    </section>
  )
}

export default withRouter(SearchIntro)
