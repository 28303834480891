import { API } from 'aws-amplify'

import { Highlight } from '../types/Highlight'
import { getItemsAndCollectionsForCollection } from '../components/utils/DetailPreview'
import addFilesToData from 'REST/utils/addFilesToData'

export const SET_LOADING = 'SET_LOADING'
export const SET_DATA = 'SET_DATA'

export const fetchHighlights = () => async dispatch => {
  dispatch({ type: SET_LOADING })

  const oaHighlights: {
    oa_highlight_items: Highlight[]
    oa_highlight_collections: Highlight[]
  } = await API.get('tba21', 'pages/homepage', { queryStringParameters: {oa_highlight: true}})

  oaHighlights.oa_highlight_collections = [ ...await getItemsAndCollectionsForCollection(oaHighlights.oa_highlight_collections as any)] as any

  let highlightsWithFiles = [
    ...await addFilesToData(oaHighlights.oa_highlight_collections),
    ...await addFilesToData(oaHighlights.oa_highlight_items)
  ]

  highlightsWithFiles = highlightsWithFiles.sort((a, b) => {
    const x = a.oa_highlight_order
    const y = b.oa_highlight_order
    if (x === y) return 0
    if (x === null) return 1
    if (y === null) return -1
    return x < y ? -1 : 1
  }).slice(0, 4) // max 4 highlights

  dispatch({ type: SET_DATA, data: highlightsWithFiles })
}
