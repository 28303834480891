import { Container, Col, Row } from 'reactstrap'
import HighlightItem from '../highlight/HighlightItem'
import HighlightItemDummy from '../highlight/HighlightItemDummy'

import { Highlight } from '../../types/Highlight'
import { WP_REST_API_Post } from 'wp-types'

interface Props {
  data?: Highlight[] | WP_REST_API_Post[]
  loading?: boolean
  title: string
  subtitle?: string
  className?: string
  children?: any
}

const HomeHighlights = ({ data, loading = false, title = '', subtitle = '', className, children }: Props) => {
  const classNames = ['py-5', className].join(' ')

  return (
    <Container fluid className={ classNames } tag="section">
      <h2 className="pt-4 ml-1">〜 { title }</h2>
      { subtitle && 
        <p className="text-subtitle mt-4 ml-1">{ subtitle }</p>
      }
      <Row noGutters className="home-highlights__strip mt-4">
        { loading
          ? [ ...Array(4) ].map((h, i) => (
              <Col xs="12" sm="6" md="3" className="px-1" key={i}>
                <HighlightItemDummy />
              </Col>
            ))
          : data?.map((h, i) => (
              <Col xs="12" sm="6" md="3" className="px-1" key={i}>
                <HighlightItem data={h} />
              </Col>
            ))
         }
      </Row>
      { children }
    </Container>
  )
}

export default HomeHighlights
