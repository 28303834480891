import * as React from 'react'
import { API, Storage } from 'aws-amplify'
import { Button, Input, Spinner } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import { HiPlus, HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi'
import { BsTrash } from 'react-icons/bs'
import { v4 as uuidv4 } from 'uuid'

import config from 'config'

import './Settings.scss'

export type CarouselTableData = {
  s3_key: string,
  credits: string,
  selectedFile?: File,
  selectedFileBlob?: string
}[]

interface Props {
  data: CarouselTableData | []
  onError?: Function
  onSuccess?: Function
}

interface State {
  carouselTableData: CarouselTableData | []
}

class CarouselSettings extends React.Component<Props, State> {
  carouselTableCols

  constructor(props: Props) {
    super(props)

    this.state = {
      carouselTableData: [],
    }

    this.carouselTableCols = [
      {
        dataField: 's3_key',
        hidden: true
      },
      {
        dataField: 'preview',
        text: 'Image',
        align: 'center',
        headerClasses: 'w-25',
        formatter: (cell, row, i) => {
          return (
            row.s3_key || row.selectedFileBlob
              ? <img
                  src={ row.s3_key ? config.urls.BASE_CONTENT_URL + row.s3_key : row.selectedFileBlob }
                  className="clickable-image"
                />
              : <label
                  className="btn btn-secondary"
                  onChange={ e => this.addImageToItem((e.target as HTMLInputElement).files?.[0], i) }
                >
                  <input type="file" accept="image/jpeg, image/webp" />
                  Add image
                </label>

          )
        }
      },
      {
        dataField: 'credits',
        text: 'Credits',
        formatter: (cell, row, i) => {
          return (
            <Input
              defaultValue={ row.credits }
              onInput={ e => this.setCarouselItemCredits(i, (e.target as HTMLInputElement).value) }
            />
          )
        }
      },
      {
        dataField: 'actions',
        text: '',
        headerStyle: { width: '62px' },
        formatter: (cell, row, i) => {
          return (<>
            { i !== 0 &&
              <Button className="p-2" title="Move up" outline onClick={ () => this.moveCarouselItem(i, i - 1) }>
                <HiOutlineChevronUp size="20" />
              </Button>
            }
            { i !== this.state.carouselTableData.length - 1 &&
              <Button className="p-2" title="Move down" outline onClick={ () => this.moveCarouselItem(i, i + 1) }>
                <HiOutlineChevronDown size="20" />
              </Button>
            }
            <Button className="p-2" title="Remove item" outline color="danger" onClick={ () => this.removeCarouselItem(i) }>
              <BsTrash size="20" />
            </Button>
          </>)
        }
      }
    ]
  }

  componentDidMount() {
    this.setState({ carouselTableData: this.props.data })
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.data.length) {
      this.setState({ carouselTableData: this.props.data })
    }
  }

  newCarouselItem() {
    const blankItem = { s3_key: '', credits: '' }
    this.setState(prev => ({ ...prev, carouselTableData: [ ...prev.carouselTableData, blankItem ] }))
  }

  moveCarouselItem(oldIndex: number, newIndex: number) {
    const carouselTableData = [ ...this.state.carouselTableData ] as any
    [carouselTableData[oldIndex], carouselTableData[newIndex]] = [carouselTableData[newIndex], carouselTableData[oldIndex]]
    this.setState({ carouselTableData })
  }

  removeCarouselItem(index: number) {
    const carouselTableData = [ ...this.state.carouselTableData ]
    carouselTableData.splice(index, 1)

    this.setState({ carouselTableData })
  }

  addImageToItem(image: any, itemIndex: number) {
    const carouselTableData = [ ...this.state.carouselTableData ]
    carouselTableData[itemIndex].selectedFile = image
    carouselTableData[itemIndex].selectedFileBlob = URL.createObjectURL(image)

    this.setState(prev => ({ ...prev, carouselTableData: [] }))
    setTimeout(() => this.setState(prev => ({ ...prev, carouselTableData })), 10) // this is ugly af
  }

  setCarouselItemCredits(index: number, text: string) {
    const carouselTableData = [ ...this.state.carouselTableData ]
    carouselTableData[index].credits = text

    this.setState({ carouselTableData })
  }

  async uploadImagesIfNecessary() {
    return Promise.all( this.state.carouselTableData.map(async it => {
      if (it.selectedFile) {
        const fileExtension = it.selectedFile.name.split('.').slice(-1) || ''
        const s3_key = `${uuidv4()}${fileExtension && '.' + fileExtension || ''}`

        await Storage.put(s3_key, it.selectedFile, {
          customPrefix: { public: 'media/' },
          contentType: it.selectedFile.type 
        })

        return { s3_key: 'media/' + s3_key, credits: it.credits }
      } else {
        return it
      }
    }) )
  }

  async saveCarouselSettings() {
    const data = await this.uploadImagesIfNecessary()

    const result = await API.put('tba21', 'admin/settings/update', { body: {
        name: 'home_carousel_items',
        values: JSON.stringify(data)
      }
    })

    result.success
      ? this.props.onSuccess?.('Carousel items saved successfully')
      : this.props.onError?.('Failed to save carousel items')
  }

  render() {
    return (
      <>
        <h5 className="mt-5 mb-3">Carousel Items</h5>
        <BootstrapTable
          remote
          bootstrap4
          keyField="s3_key"
          data={ this.state.carouselTableData }
          columns={ this.carouselTableCols }
          // pagination={}
          // onTableChange={this.handleTableChange}
          noDataIndication="No data to display."
        />
        <div className="flex justify-content-between">
          <Button color="primary" onClick={ () => this.newCarouselItem() }><HiPlus size="24" /></Button>
          <Button onClick={ () => this.saveCarouselSettings() }>Save</Button>
        </div>
      </>
    )
  }
}

export default CarouselSettings
