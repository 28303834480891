import { RouteComponentProps, withRouter } from 'react-router';

import * as React from 'react';
import { Container } from 'reactstrap';

import { Item } from 'types/Item';
import { Alerts, ErrorMessage, SuccessMessage } from 'components/utils/alerts';
import { AuthContext } from '../../../../providers/AuthProvider';
import AdminSearch from '../../utils/AdminSearch';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import 'styles/components/admin/tables/modal.scss';

interface State extends Alerts {
  items: Item[];
  itemIndex?: number;

  componentModalOpen: boolean;
  deleteModalOpen: boolean;

  tableIsLoading: boolean;
  page: number;
  sizePerPage: number;
  totalSize: number;

  deleteErrorMessage: string | JSX.Element | undefined;
  order?: string;
}

class Items extends React.Component<RouteComponentProps, State> {
  _isMounted;

  constructor(props: RouteComponentProps) {
    super(props);
    this._isMounted = false;

    this.state = {
      componentModalOpen: false,
      deleteModalOpen: false,
      items: [],

      tableIsLoading: true,
      page: 1,
      sizePerPage: 15,
      totalSize: 0,
      deleteErrorMessage: undefined
    };
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const initialItemId = 'id' in this.props.match.params && this.props.match.params.id || ''

    return (
      <Container className="mt-3">
        <ErrorMessage message={this.state.errorMessage}/>
        <SuccessMessage message={this.state.successMessage}/>
        <AdminSearch
          limit={this.state.sizePerPage}
          path={'items'}
          initialId={`${initialItemId}`}
        />

      </Container>
    )
  }
}

export default withRouter(Items);
Items.contextType = AuthContext;