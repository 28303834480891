import * as React from 'react';
import { connect } from 'react-redux';
import { PrivacyPolicyState } from 'reducers/pages/privacyPolicy';
import { modalToggle } from 'actions/pages/privacyPolicy';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import 'styles/components/admin/tables/modal.scss';

interface Props extends PrivacyPolicyState {
  modalToggle: Function;
}

class HelpArchiveModal extends React.Component<Props, {}> {
  render() {
    return (
      <Modal isOpen={this.props.help_archive_open} toggle={() => this.props.modalToggle('HELP_ARCHIVE_MODAL')} className="fullwidth blue">
        <ModalHeader toggle={() => this.props.modalToggle('HELP_ARCHIVE_MODAL')}>HOW TO: ARCHIVE</ModalHeader>
        <ModalBody>
          <iframe style={{ width: '100%', height: '100%' }} src="https://ocean-archive.org/embedcollection/190" />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state: { privacyPolicy: PrivacyPolicyState }) => ({
  help_archive_open: state.privacyPolicy.help_archive_open,
});

export default connect(mapStateToProps, { modalToggle })(HelpArchiveModal);
