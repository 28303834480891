import { ReactNode } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import IconButton from 'components/utils/IconButton'

interface Props {
  title?: string
  children?: ReactNode
  open?: boolean
  onClose: () => void
}

const DialogModal = ({ title, children, open, onClose }: Props) => {
  return (
    <Modal isOpen={ open } className="oa-dialog">
      <ModalHeader close={ <IconButton icon="close" onClick={ onClose } /> }>
        { title }
      </ModalHeader>
      <ModalBody>
        { children}
      </ModalBody>
    </Modal>
  )
}

export default DialogModal