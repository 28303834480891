import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { getStoriesAndTotalStoriesInDatabase } from '../../REST/story';
import { WP_REST_API_Posts } from 'wp-types';
import defaultImage from 'images/defaults/Unscharfe_Zeitung.jpg';
import { NavLink } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { storyURL } from 'urls';
import { connect } from 'react-redux';
import config from '../../config';
import { StoryListState } from '../../reducers/story/storyList';
import { stringToHTML } from '../../utils';
import Blob from 'components/blob/Blob';

// eslint-disable-next-line react/prop-types
const StoryHero = ({ heroRef, categoryById }) => {
  const [heroStories, setHeroStories] = useState<WP_REST_API_Posts>([]);
  const [status, setStatus] = useState<'LOADING' | 'ERROR' | 'FETCHED'>(
    'LOADING'
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userStatus, setUserStatus] = useState<any>();
  useEffect(() => {
    const getStories = async () => {
      setStatus('LOADING');
      try {
        const result = await getStoriesAndTotalStoriesInDatabase({
          perPage: 5,
          page: 1,
          sticky: true,
        });
        setHeroStories(result.stories);
        setStatus('FETCHED');
      } catch {
        setStatus('ERROR');
      }
    };

    getStories();
  }, []);

  useEffect(() => {
    const getUserStatus = async() => {
      setUserStatus(await Auth.currentUserInfo());
    };
    getUserStatus();
  }, []);

  return (
    <div className="stories-hero" ref={heroRef}>
      {status === 'ERROR' && <p>Something went wrong</p>}
      {status === 'LOADING' && <Spinner color="white" />}
      {status === 'FETCHED' && (
        <>
          <div className="stories-hero-header">
            <img src="/svg/ocean-archive-1.svg" alt="" />
            <img src="/svg/ocean-archive-2.svg" alt="" />
          </div>
          <div className="stories-hero-items-wrapper">
            <div className="stories-hero-items">
              {heroStories.map((story, idx) => {
                const allAuthors = (story.categories ?? [])
                  .map((category) => categoryById[category])
                  .filter((c) => !!c && c.link.includes('/authors/'))
                  .map((category) => category.name)
                  .join(', ');
                return (
                  <NavLink to={storyURL(story.slug)} key={story.id}>
                    <div className={`stories-hero-item${[1, 2, 4].includes(idx) ? ' stories-hero-item--inverse' : ''}`}>
                      <div className={'story-blob'}>
                        <span
                          className="storyExcerpt"
                          dangerouslySetInnerHTML={{
                            __html: story.excerpt
                              ? stringToHTML(story.excerpt.rendered)
                                  .getElementsByTagName('p')[0]
                                  .innerHTML.replace(/(<([^>]+)>)/gi, '')
                                  .replace(/Continue reading.+/, '')
                                  .split(' ')
                                  .slice(0, 20)
                                  .join(' ')
                                  .concat('...')
                              : '<></>',
                          }}
                        />

                        <img
                          className={`story-blob__image story-blob--scale--${
                            idx + 1
                          } story-blob__image--${idx + 1}`}
                          alt={`cover for ${story.title}`}
                          src={
                            (story.jetpack_featured_media_url as string) ||
                            defaultImage
                          }
                        />
                        {/* <img
                          className={`story-blob--absolute story-blob--loops-scale--${
                            idx + 1
                          }`}
                          src={`/svg/Bubble${idx + 1}-lines.svg`}
                          alt=""
                        /> */}
                        <div className={`story-blob__lines story-blob__lines--${idx + 1}`}>
                          <Blob floating={ false } glow={ false } hoverable={ true } oneLine={ idx !== 4 } />
                          { [1, 2].includes(idx) &&
                            <Blob floating={ false } glow={ false } hoverable={ true } oneLine={ true } />
                          }
                        </div>
                      </div>
                      <h2
                        dangerouslySetInnerHTML={{ __html: story.title.rendered }}
                      />
                      <p className='stories-hero-item__author'>{allAuthors}</p>
                    </div>
                  </NavLink>
                );
              })}
            </div>
          </div>
          <a className="more-link" href={userStatus ? config.urls.WP_URL + 'strsdoor' : `https://${window.location.hostname}/login`} target={userStatus ? '_blank' : ''} rel="noreferrer noopener">
            <img
              src="/svg/stories_CTA.svg"
              alt=""
              className="stories-hero-illustration"
            />
          </a>
        </>
      )}
    </div>
  );
};

export default connect((state: { storyList: StoryListState }) => ({
  categoryById: state.storyList.categoryById,
}))(StoryHero);
