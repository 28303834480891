import classnames from 'classnames'
import { connect } from 'react-redux'

import SearchableDropdown from '../utils/SearchableDropdown'

import { SearchState } from '../../reducers/search'
import { FileTypes } from '../../types/s3File'
import { Regions } from '../../types/Item'

import { setFilter } from 'actions/search'
import { getItemIcon } from 'lib/itemTypeIcons'

import './SearchFilters.scss'

export interface Facets {
  [key: string]: {
    [key: string]: number
  }
}

interface Props {
  searchState: SearchState
  facets: Facets
  onChange: Function
  setStateFilter: Function
  open: boolean
  className?: string
  children: any
}

const SearchFilters = ({ searchState, facets, onChange, setStateFilter, open, className, children }: Props) => {

  const authorOptions = facets.creators
    ? Object.entries(facets.creators).map(it => ({ label: it[0], value: it[0], info: it[1] }))
    : []

  const tagOptions = facets.concept_tags
    ? Object.entries(facets.concept_tags).map(it => ({ label: it[0], value: it[0], info: it[1] }))
    : []

  const regions = facets.regions
    ? Object.entries(facets.regions).map(it => ({ label: Regions[it[0]], value: it[0], info: it[1] }))
    : []

  const fileTypes = [
    { label: 'Audio', value: FileTypes.Audio },
    { label: 'Video', value: FileTypes.Video },
    { label: 'Text', value: FileTypes.Text },
    { label: 'Image', value: FileTypes.Image }
  ]

  const handleFilter = (name, value) => {
    const updatedFilters = { ...searchState.filters, [name]: value }
    onChange(updatedFilters)
    setStateFilter(name, value)
  }

  return (
    <div className={ classnames('search-filters', { open }, className) }>
      <div className="search-filters__inner">
        <h2>〜  REFINE YOUR RESULTS</h2>
        <div className="search-filters__selects">
          <p className="text-lead py-1">Start typing or select from the list</p>
          <SearchableDropdown
            placeholder="Author"
            items={ authorOptions }
            value={ searchState.filters.creator }
            onSelect={ item => handleFilter('creator', item?.value || '') }
          />
          <SearchableDropdown
            placeholder="Concept tags"
            items={ tagOptions }
            value={ searchState.filters.tags }
            onSelect={ item => handleFilter('tags', item?.value || '') }
          />
          <SearchableDropdown
            placeholder="File type"
            items={ fileTypes }
            value={ searchState.filters.fileType }
            onSelect={ item => handleFilter('fileType', item?.value || '') }
          />
          <SearchableDropdown
            placeholder="Region"
            items={ regions }
            value={ searchState.filters.regions }
            onSelect={ item => handleFilter('regions', item?.value || '') }
          />
        </div>

        <div className="search-filters__radios">
          <div className="mt-4">
            <label>Order results by:</label>
            <div className="mb-1">
              <input
                className="oa-radio"
                type="radio"
                id="filter-newest"
                name="orderby"
                value="newest"
                checked={ 'newest' === searchState.filters.sortBy }
                onChange={e => handleFilter('sortBy', e.target.value) }
              />
              <label htmlFor="filter-newest">Newest</label>
            </div>
            <div>
              <input
                className="oa-radio"
                type="radio"
                id="filter-views"
                name="orderby"
                value="views"
                checked={ 'views' === searchState.filters.sortBy }
                onChange={e => handleFilter('sortBy', e.target.value) }
              />
              <label htmlFor="filter-views">Most popular</label>
            </div>
          </div>
          <div className="mt-4">
            <label>View:</label>
            <div className="mb-1">
              <input
                className="oa-radio"
                type="radio"
                id="filter-items"
                name="viewTypes"
                value="item"
                checked={ 'item' === searchState.filters.viewTypes }
                onChange={e => handleFilter('viewTypes', e.target.value) }
              />
              <label htmlFor="filter-items">Items</label>
            </div>
            <div className="mb-1">
              <input
                className="oa-radio"
                type="radio"
                id="filter-collections"
                name="viewTypes"
                value="collection"
                checked={ 'collection' === searchState.filters.viewTypes }
                onChange={e => handleFilter('viewTypes', e.target.value) }
              />
              <label htmlFor="filter-collections">Collections</label>
            </div>
            <div className="mb-1">
              <input
                className="oa-radio"
                type="radio"
                id="filter-stories"
                name="viewTypes"
                value="story"
                checked={ 'story' === searchState.filters.viewTypes }
                onChange={e => handleFilter('viewTypes', e.target.value) }
              />
              <label htmlFor="filter-stories">Journeys</label>
            </div>
            <div className="mb-1">
              <input
                className="oa-radio"
                type="radio"
                id="filter-portfolios"
                name="viewTypes"
                value="portfolio"
                checked={ 'portfolio' === searchState.filters.viewTypes }
                onChange={e => handleFilter('viewTypes', e.target.value) }
              />
              <label htmlFor="filter-stories">Portfolios</label>
            </div>
            <div>
              <input
                className="oa-radio"
                type="radio"
                id="filter-all"
                name="viewTypes"
                value="all"
                checked={ 'all' === searchState.filters.viewTypes }
                onChange={e => handleFilter('viewTypes', e.target.value) }
              />
              <label htmlFor="filter-both">All</label>
            </div>
          </div>
        </div>

        { children }

        <div className="search-filters__legend">
          <p className="text-lead mt-4">Legend:</p>
          <ul>
            <li>{ getItemIcon('Journey') }Journey</li>
            <li>{ getItemIcon('Image') }Image</li>
            <li>{ getItemIcon('Video') }Video</li>
            <li>{ getItemIcon('Audio') }Audio</li>
            <li>{ getItemIcon('PDF') }PDF</li>
            <li>{ getItemIcon('Collection') }Collection</li>
            <li>{ getItemIcon('NestedCollection') }Nested Collection</li>
            <li>{ getItemIcon('Portfolio') }Portfolio</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: { search: SearchState }) => ({
  searchState: state.search
})

export default connect(mapStateToProps, { setStateFilter: setFilter })(SearchFilters)
