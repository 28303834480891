import { Alerts } from '../../components/utils/alerts';
import { Profile } from '../../types/Profile';
import { Item } from 'types/Item';
import { Collection } from 'types/Collection';
import { WP_REST_API_Posts } from 'wp-types';

// Defining our Actions for the reducers.
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';
export const FETCH_PROFILE_ERROR_NO_SUCH_PROFILE = 'FETCH_PROFILE_ERROR_NO_SUCH_PROFILE';

export const FETCH_PROFILE_ITEMS_AND_COLLECTIONS_LOADING = 'FETCH_PROFILE_ITEMS_AND_COLLECTIONS_LOADING';
export const FETCH_PROFILE_ITEMS_AND_COLLECTIONS_SUCCEED = 'FETCH_PROFILE_ITEMS_AND_COLLECTIONS_SUCCEED';
export const FETCH_PROFILE_ITEMS_AND_COLLECTIONS_ERROR = 'FETCH_PROFILE_ITEMS_AND_COLLECTIONS_ERROR';

export const FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_LOADING = 'FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_LOADING';
export const FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_SUCCEED = 'FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_SUCCEED';
export const FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_ERROR = 'FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_ERROR';

export const FETCH_PROFILE_STORIES_LOADING = 'FETCH_PROFILE_STORIES_LOADING';
export const FETCH_PROFILE_STORIES_SUCCEED = 'FETCH_PROFILE_STORIES_SUCCEED';
export const FETCH_PROFILE_STORIES_ERROR = 'FETCH_PROFILE_STORIES_ERROR';

export const profileItemAndCollectionsFetchLimit = 100;

export interface State extends Alerts {
  profileId?: string | boolean;
  profile?: Profile;
  items: Item[];
  collections: Collection[];
  favouriteItems: Item[];
  favouriteCollections: Collection[];
  itemsHasMore: boolean;
  collectionsHasMore: boolean;
  isItemsAndCollectionsLoading: boolean;
  isFavouriteItemsAndCollectionsLoading: boolean;
  isFavouriteItemsAndCollectionsLoaded: boolean;
  isFavouriteStoriesLoading: boolean;
  favouriteStories: WP_REST_API_Posts;
}

const initialState: State = {
  errorMessage: undefined,
  items: [],
  collections: [],
  favouriteItems: [],
  favouriteCollections: [],
  isItemsAndCollectionsLoading: false,
  isFavouriteItemsAndCollectionsLoading: false,
  isFavouriteItemsAndCollectionsLoaded: false,
  isFavouriteStoriesLoading: false,
  itemsHasMore: true,
  collectionsHasMore: true,
  favouriteStories: [],
};

/**
 * Performs an action based on the action.type
 *
 * @param state {object} either empty or the previous state
 * @param action {string} the action to perform
 *
 * @returns {object} the state with modified values
 */
export default (state: State = initialState, action) => {
  if (state === undefined) {
 state = initialState; 
}

  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...initialState,
        profile: action.profile,
        profileId: action.profileId,
        errorMessage: undefined
      };

    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        errorMessage: 'Looks like we\'ve had a bit of a hiccup.',
      };

    case FETCH_PROFILE_ERROR_NO_SUCH_PROFILE:
      return {
        ...state,
        profile: action.profile,
        errorMessage: 'Are you sure you\'ve got the right url? We can\'t find what you\'re looking for. Sorry!',
      };

    case FETCH_PROFILE_ITEMS_AND_COLLECTIONS_LOADING:
      return {
        ...state,
        isItemsAndCollectionsLoading: true,
      };
    case FETCH_PROFILE_ITEMS_AND_COLLECTIONS_SUCCEED:
      return {
        ...state,
        isItemsAndCollectionsLoading: false,
        items: [
          ...state.items,
          ...action.items
        ],
        collections: [
          ...state.collections,
          ...action.collections
        ],
        itemsHasMore: action.items.length >= profileItemAndCollectionsFetchLimit,
        collectionsHasMore: action.collections.length >= profileItemAndCollectionsFetchLimit,
      };
    case FETCH_PROFILE_ITEMS_AND_COLLECTIONS_ERROR:
      return {
        ...state,
        isItemsAndCollectionsLoading: false,
        itemsHasMore: false,
        collectionsHasMore: false,
        errorMessage: 'Some error is occurred. Please try again later.'
      };
      case FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_LOADING:
        return {
          ...state,
          isFavouriteItemsAndCollectionsLoading: true,
        };
      case FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_SUCCEED:
        return {
          ...state,
          isFavouriteItemsAndCollectionsLoading: false,
          isFavouriteItemsAndCollectionsLoaded: true,
          favouriteItems: action.favouriteItems,
          favouriteCollections: action.favouriteCollections,
        };
    case FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_ERROR:
        return {
          ...state,
          isFavouriteItemsAndCollectionsLoading: false,
          errorMessage: 'Some error is occurred. Please try again later.'
        };

        case FETCH_PROFILE_STORIES_LOADING: 
        return {
          ...state,
          isFavouriteStoriesLoading: true,
        };

        case FETCH_PROFILE_STORIES_SUCCEED: 
        return {
          ...state,
          favouriteStories: action.stories,
          isFavouriteStoriesLoading: false,
        };
// TODO add error
        case FETCH_PROFILE_STORIES_ERROR: 
        return {
          ...state,
          favouriteStories: [],
          isFavouriteStoriesLoading: false,
        };

    default:
      return state;
  }

};
