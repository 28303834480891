import { useState, useRef, useEffect } from 'react'
import { FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { IoCloseCircleSharp } from 'react-icons/io5'

interface Props {
  items: {}[]
  value: {}
  placeholder?: string
  labelName?: string
  keyName?: string
  infoName?: string
  initialValue?: {}
  onSelect: Function
}

const SearchableDropdown = ({ items, value, placeholder, labelName = 'label', keyName = 'value', infoName = 'info', initialValue, onSelect }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(initialValue || null)

  useEffect(() => {
    select(items.find(it => it[keyName] === value), false)
  }, [value])

  const select = (item, emit = true) => {
    if (inputRef.current) inputRef.current.value = item?.[labelName] || ''
    if (selectedItem !== item) {
      setSelectedItem(item)
      emit && onSelect(item)
    }
  }

  const handleToggle = () => setDropdownOpen(prevState => {
    if (prevState && document.activeElement === inputRef.current) return prevState
    else {
      setTimeout(() => !prevState && inputRef.current?.focus(), 10)
      return !prevState
    }
  })

  const handleBlur = (val: string) => {
    if (selectedItem) {
      if (val === '') select(null)
      else if (inputRef.current) inputRef.current.value = selectedItem?.[labelName] || ''
    }
  }

  return (
    <FormGroup>
      <Dropdown className="oa-dropdown" isOpen={ dropdownOpen } toggle={ handleToggle }>
        <DropdownToggle tag="div" caret={ !selectedItem }>
          <Input
            type="text"
            innerRef={ inputRef }
            placeholder={ placeholder }
            onChange={ e => setInputValue(e.target.value) }
            onClick={ () => setTimeout(() => dropdownOpen && setDropdownOpen(false), 50) }
            onBlur={ e => handleBlur(e.target.value) }
          />
          { !!selectedItem && (
            <IoCloseCircleSharp className="dropdown-clear" size="20px" onClick={e => {
              e.stopPropagation()
              select(null)
            }} />
          ) }
        </DropdownToggle>
        <DropdownMenu>
          { items.map(item => (
            ( !inputValue || item[labelName].toLowerCase().startsWith(inputValue.toLowerCase()) ) && (
              <DropdownItem
                key={ item[keyName] }
                // title={ item[labelName] }
                onClick={() => select(item)}
              >
                <span className="dropdown-item-label">{ item[labelName] }</span>
                <span className="dropdown-item-info">{ item[infoName] || '' }</span>
              </DropdownItem>
            )
          )) }
        </DropdownMenu>
      </Dropdown>
    </FormGroup>
  )
}

export default SearchableDropdown
