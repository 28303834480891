import { useState, useRef } from 'react'
import { Spinner } from 'reactstrap'
import classNames from 'classnames'

import WavedHeadline from './WavedHeadline'
import TextWithNewlines from './TextWithNewlines'
import { browser } from './browser'
import { sanitizeInput } from 'utils'

import './EditableTextBlock.scss'

interface Props {
  title: string
  content: string
  placeholder?: string
  loading?: boolean
  enabled?: boolean
  onSave?: (content: string) => void
}

const EditableTextBlock = ({ title, content, placeholder, loading, enabled, onSave }: Props) => {
  const isFirefox = browser() === 'firefox'
  const [isEditing, setIsEditing] = useState(false)
  const [editedContent, setEditedContent] = useState('')
  const [canceled, setCanceled] = useState(0)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const edit = () => {
    setIsEditing(true)
    setEditedContent(content)
    setTimeout(() => focusContent(), 10)
  }

  const cancel = () => {
    setIsEditing(false)
    setEditedContent(content)
    setCanceled(canceled + 1)
  }

  const save = () => {
    setIsEditing(false)
    onSave?.( sanitizeInput(editedContent) )
  }

  const onTextInput = (e) => {
    setEditedContent(e.target.innerText)
  }

  const focusContent = () => {
    if (!contentRef.current) return
    contentRef.current.focus()
    const sel = window.getSelection()
    sel?.selectAllChildren(contentRef.current)
    sel?.collapseToEnd()
  }

  const showPlaceholder = !!(enabled && !content && !editedContent && !isEditing && placeholder)

  return (
    <div className={ classNames('editable-textblock', { loading }) }>
      <WavedHeadline
        isEditing={ isEditing }
        onEdit={ edit }
        onCancel={ cancel }
        onSave={ save }
        editable={ enabled }
      >
        { title }
      </WavedHeadline>
      <div
        ref={ contentRef }
        className={`editable-textblock__content text-alta${showPlaceholder ? ' empty' : ''}`}
        contentEditable={ (isEditing ? (isFirefox ? true : 'plaintext-only') : false) as any }
        suppressContentEditableWarning
        onInput={ onTextInput }
        onClick={ () => showPlaceholder && edit() }
        key={ canceled }
      >
        { showPlaceholder && placeholder }
        <TextWithNewlines text={ content } />

      </div>
      { loading && <div className="loader"><Spinner /></div> }
    </div>
  )
}

export default EditableTextBlock
