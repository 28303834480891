import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { FiFilter } from 'react-icons/fi'
import classnames from 'classnames'

import { Portfolio } from 'types/Portfolio'
import { portfolioURL } from 'urls'

import { countries as selectableCountries } from 'components/metadata/SelectOptions'
import Blob from 'components/blob/Blob'
import SearchableDropdown from 'components/utils/SearchableDropdown'
import { plainText } from 'components/utils/textUtils'

import './PortfolioSearch.scss'

interface Props {
  portfolios: Portfolio[]
}

const PortfolioSearch = ({ portfolios }: Props) => {
  const [results, setResults] = useState<Portfolio[]>([])
  const [filterOptions, setFilterOptions] = useState<any>({})
  const [searchState, setSearchState] = useState<any>({
    filters: {
      sort: 'az'
    },
    query: ''
  })

  useEffect(() => {
    const fields = new Set<string>()
    const countries = new Set<string>()
    const conceptTags = {}

    portfolios.forEach(portfolio => {
      portfolio.profile.field_expertise?.split(',').forEach( it => fields.add(it.trim()) )
      portfolio.profile.aggregated_concept_tags?.forEach( it => conceptTags[it.id] = it.tag_name )
      portfolio.profile.country && countries.add(portfolio.profile.country)
    })

    setFilterOptions({
      workFields: Array.from(fields).map(it => ({ value: it, label: it })),
      countries: Array.from(countries).map(it => ({ value: it, label: selectableCountries.find(country => country.value === it)?.label || it })),
      conceptTags: Object.entries(conceptTags).map(([ value, label ]) => ({ value, label }))
    })

    setResults( sort(portfolios, 'az') )
  }, [portfolios])

  const sort = (_portfolios, mode) => (
    _portfolios.sort((a, b) => {
      return mode === 'contributions'
        ? (b.items_count || 0) - (a.items_count || 0)
        : (a.profile.full_name || '').localeCompare(b.profile.full_name || '')
    })
  )

  const doSearch = (query, filters) => {
    let res = portfolios

    query && ( res = res.filter(portfolio => (
      (portfolio.profile.full_name || '').toLowerCase().includes(query.toLowerCase())
    )) )

    filters.workField && ( res = res.filter(portfolio => (
      portfolio.profile.field_expertise?.split(',').map(it => it.trim()).includes(filters.workField)
    )) )

    filters.country && ( res = res.filter(portfolio => (
      portfolio.profile.country === filters.country
    )) )

    filters.conceptTags !== undefined && ( res = res.filter(portfolio => (
      portfolio.profile.concept_tags?.includes(Number(filters.conceptTags))
    )) )

    setResults( sort(res, filters.sort) )
  }

  const handleFilter = (key: string, value: any) => {
    const filters = { ...searchState.filters, [key]: value }
    doSearch(null, filters)
    setSearchState({ ...searchState, filters })
  }

  const onTagClick = (tag: any) => {
    return 
  }

  return (
    <div className="portfolio-search theme-colors">
      <aside className="search-filters open">
        <div className="search-filters__inner">
          <h2>〜  REFINE YOUR RESULTS</h2>
          <div className="search-filters__selects">
            <p className="text-lead py-1">Start typing or select from the list</p>
            <SearchableDropdown
              placeholder="Field of work"
              items={ filterOptions.workFields || [] }
              value={ searchState.filters.workField }
              onSelect={ item => handleFilter('workField', item?.value || '') }
            />
            <SearchableDropdown
              placeholder="Geography"
              items={ filterOptions.countries || [] }
              value={ searchState.filters.country }
              onSelect={ item => handleFilter('country', item?.value || '') }
            />
            <SearchableDropdown
              placeholder="Concept tags"
              items={ filterOptions.conceptTags || [] }
              value={ searchState.filters.conceptTags }
              onSelect={ item => handleFilter('conceptTags', item?.value) }
            />
          </div>

          <div className="search-filters__radios">
            <div className="mt-4">
              <label>Order results:</label>
              <div className="mb-1">
                <input
                  className="oa-radio"
                  type="radio"
                  id="filter-az"
                  name="orderby"
                  value="az"
                  checked={ 'az' === searchState.filters.sort }
                  onChange={e => handleFilter('sort', e.target.value) }
                />
                <label htmlFor="filter-az">Alphabetically</label>
              </div>
              <div>
                <input
                  className="oa-radio"
                  type="radio"
                  id="filter-contributions"
                  name="orderby"
                  value="contributions"
                  checked={ 'contributions' === searchState.filters.sort }
                  onChange={e => handleFilter('sort', e.target.value) }
                />
                <label htmlFor="filter-contributions">Most prolific</label>
              </div>
            </div>
          </div>

          <div className="search-filters__legend">
            <p className="text-lead mt-4">
              Showing { results.length } out of { portfolios.length } results
            </p>
          </div>
        </div>
      </aside>

      <div className="portfolio-search__results">
        { results.map(portfolio => (
          <div className="search-results__item" key={ portfolio.id }>
            <div className="search-results__item-image">
              <Link to={ portfolioURL(portfolio.profile_id) }>
                <Blob floating={ false } glow={ false } oneLine={ true } image={ portfolio.profile.profile_image || '' } />
              </Link>
            </div>
            <h3 className="search-results__item-title">
              <Link to={ portfolioURL(portfolio.profile_id) }>
                { portfolio.profile.full_name }
              </Link>
            </h3>
            <span className="search-results__item-meta">
              { portfolio.profile.field_expertise ? portfolio.profile.field_expertise + ' | ' : ''}{ portfolio.profile.profile_type }
            </span>
            <p className="search-results__item-description">
              { plainText(portfolio.profile.biography).substring(0, 800) }
            </p>
            <div className="search-results__item-tags">
              { portfolio.profile.aggregated_concept_tags?.map(tag => (
                <Button
                  key={ tag.id }
                  className={ classnames('page-link tag d-inline-block', { active: searchState.filters.tags === tag.id }) }
                  style={{ padding: 0, marginBottom: 10 }}
                  dangerouslySetInnerHTML={{ __html: '#' + tag.tag_name }}
                  onClick={ () => onTagClick?.(tag.id) }
                />
              )) }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    portfolios: state.portfolios.portfolios?.filter(it => it.profile.public_profile)
  }
}

export default connect(mapStateToProps, {})(PortfolioSearch)
