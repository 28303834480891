import { Link } from 'react-router-dom'

import RollingHeader from 'components/layout/RollingHeader'

import './HomeIntro.scss'

const HomeIntro = ({ surpriseLink }: { surpriseLink: string }) => {
  const handleAnchor = (e) => {
    e.preventDefault()
    const target = document.querySelector(e.target.attributes.href.value)
    target.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className="home-intro">
      <RollingHeader />
      <div className="home-intro__text">
        <p>Welcome to Ocean-Archive.org — a digital organism for a living Ocean — fostered by TBA21‑Academy.</p>
        <p>How would you like to dive in?</p>
      </div>
      <div className="home-intro__actions">
        <Link to="/search" className="button button--wave" role="button">
          Search
        </Link>
        <a href="#explore" className="button button--wave" role="button" onClick={ handleAnchor }>
          Explore
        </a>
        <Link to={ surpriseLink } className="button button--wave" role="button">
          Surprise me
        </Link>
      </div>
    </section>
  )
}

export default HomeIntro
