import { connect } from 'react-redux'
import { IoSunny, IoMoon } from 'react-icons/io5'

import { setTheme } from 'actions/global'

import './ThemeSwitch.scss'

interface Props {
  currentTheme: string
  setState: Function
}

const ThemeSwitch = ({ currentTheme, setState }: Props) => {
  const handleClick = () => {
    setState(currentTheme === 'dark' ? 'light' : 'dark')
  }

  return (
    <div className={ `theme-switch ${currentTheme}` } title='Switch theme' onClick={ handleClick }>
      <div className="theme-switch__toggle">
        <IoSunny />
        <IoMoon />
      </div>
    </div>
  )
}

const mapStateToProps = (state: { global: { theme: string } }) => ({
  currentTheme: state.global.theme
})

export default connect(mapStateToProps, { setState: setTheme })(ThemeSwitch)
