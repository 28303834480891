import './BackToTop.scss'

const BackToTop = ({ className }: { className?: any }) => {
  return (
    <button
      className={['back-to-top', className].join(' ')}
      onClick={ () => window.scrollTo(0, 0) }
    >
      <svg width="211" height="256" fill="none" viewBox="0 0 211 256">
        <path fill="url(#arrow-up-a)" d="M120.69 154.22v79.76c0 4.53-.04 9.24-2.01 13.32-2.46 5.09-7.98 8.56-13.63 8.55-5.66 0-11.17-3.48-13.62-8.57-1.95-4.06-1.99-8.74-2-13.25-.03-30.09-.03-60.18-.01-90.27 0-15.22.03-30.44.05-45.67.01-7.43.02-14.87.04-22.3 0-4.33 1.62-12.56-3.56-14.67-3.44-1.4-5.61.78-7.78 2.96-16.7 16.71-33.42 33.4-50.14 50.09-4.98 4.97-10.74 7.16-17.7 4.77-9.92-3.41-13.55-15.71-7.06-23.97 1.08-1.37 2.26-2.67 3.49-3.9C35.35 62.39 64.15 33.89 92.42 4.88c6.64-6.81 18.92-6.17 24.93-.08 28.74 29.13 57.78 57.98 86.69 86.95 6.51 6.53 7.84 13.5 4.05 20.3-4.45 7.99-15.28 10.41-22.7 5.02-1.34-.97-2.57-2.11-3.74-3.28-16.59-16.59-33.15-33.2-49.73-49.8-.7-.71-1.4-1.44-2.19-2.04-3.34-2.5-7.52-1.05-8.71 2.95-.37 1.23-.31 2.47-.31 3.71 0 28.54 0 57.07-.01 85.61h-.01Z"/>
        <defs>
          <linearGradient id="arrow-up-a" x1="38.51" x2="151.52" y1="219.7" y2="23.95" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B2D6FF" />
            <stop offset="0.51" stopColor="#CAF6EC" />
            <stop offset="1" stopColor="#DDC4FF" />
          </linearGradient>
        </defs>
      </svg>
    </button>
  )
}

export default BackToTop