import { FETCH_PORTFOLIOS_ERROR } from 'actions/user/portfolios'
import { Portfolio } from 'types/Portfolio'

export const SET_PORTFOLIOS = 'SET_PORTFOLIOS'
export const PORTFOLIOS_LOADING = 'PORTFOLIOS_LOADING'

export interface PortfoliosState {
  errorMessage: string
  portfolios: Portfolio[]
  loading?: boolean
}

const initialState = {
  errorMessage: '',
  portfolios: [],
  loading: false
}

export default (state: PortfoliosState = initialState, action) => {
  switch (action.type) {
    case SET_PORTFOLIOS:
      return {
        ...state,
        portfolios: action.portfolios
      }
    case PORTFOLIOS_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case FETCH_PORTFOLIOS_ERROR:
      return {
        ...state,
        errorMessage: 'Could not load portfolios'
      }
    default:
      return state
  }
}