import { useState } from 'react'
import type { ReactNode } from 'react'
import { FiEdit, FiCheck } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'

import './WavedHeadline.scss'

interface Props {
  children?: ReactNode | ReactNode[]
  editable?: boolean
  isEditing?: boolean
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const WavedHeadline = ({ children, editable, isEditing, onEdit, onSave, onCancel }: Props) => {
  return (
    <div className="waved-headline">
      <h2>〜 { children }</h2>
      { editable && (
        <div className="waved-headline__actions">
          { !isEditing
              ? <button className="button button--plain" onClick={ () => onEdit?.() }><FiEdit size={ 28 } /></button>
              : <>
                  <button className="button button--plain mr-2" onClick={ () => onCancel?.() }><IoClose size={ 30 } /></button> 
                  <button className="button button--plain" onClick={ () => onSave?.() }><FiCheck size={ 32 } /></button> 
                </>
          }
        </div>
      )}
    </div>
  )
}

export default WavedHeadline
