import { getStory } from 'REST/story';
import { stringToHTML } from 'utils';

export const FETCH_STORY_LOADING = 'FETCH_STORY_LOADING';
export const FETCH_STORY_ERROR = 'FETCH_STORY_ERROR';
export const FETCH_STORY_SUCCESS = 'FETCH_STORY_SUCCESS';

export const fetchStory = (slug: string) => async (dispatch, getState) => {
  dispatch({ type: FETCH_STORY_LOADING });
  try {
    let story = await getStory(slug);
    dispatch({
      type: FETCH_STORY_SUCCESS,
      payload: {
        id: story.id,
        title: story.title.rendered,
        html: story.content.rendered,
        featured_image_url: story.jetpack_featured_media_url ? story.jetpack_featured_media_url : 'https://ocean-archive.org/favicons/apple-touch-icon.png?v=ng9EmB9dQn',
        concept_tags: story.concept_tags,
        keyword_tags: story.keyword_tags,
        excerpt: stringToHTML(story.excerpt.rendered).getElementsByTagName('p')[0].innerHTML.replace(/(<([^>]+)>)/ig, '').replace(/Continue reading.+/, ''),
        prev: story.prev,
        next: story.next,
      },
    });
  } catch {
    dispatch({
      type: FETCH_STORY_ERROR,
    });
  }
};
