
import { ReactComponent as DecadeLogo } from '../../images/logo/decade_logo.svg'

const About = () => {
  return (
    <>
      <h1 className="mb-4">Ocean-Archive.org is a digital organism for a living ocean.</h1>

      <p>Ocean-Archive.org is an online platform that investigates the potential of storytelling and transdisciplinary collaboration within and beyond archival practices. It strives to expand critical ocean literacy in a time of great necessity and catalyzes collective action for a living Ocean. The aim of Ocean-Archive.org is to bring together the multitude of voices and <strong>journeys</strong> around the Ocean and connect those striving to nurture and protect it. With <strong>ocean comm/uni/ty</strong>, the platform instigates conversations around the Ocean so that members can connect and co-create. Designed as a storytelling and pedagogical tool, Ocean-Archive.org translates current knowledge into a shared language that fosters synergy among art, science, policy, and conservation and enables us to make better decisions for urgently needed policies.</p>

      <h2 className="mt-5 mb-4">Who is behind Ocean-Archive.org?</h2>
      <p><strong>TBA21–Academy</strong> is a research platform, fostering a deeper relationship with the Ocean and wider ecologies by working as an incubator for transdisciplinary inquiry, artistic production, and environmental advocacy. For more than a decade, the Academy has catalyzed new forms of knowledge emerging from the exchanges between art, science, policy, and conservation in long-term and collaborative engagement through commissions, fellowships, residency programs, and activities in a wide variety of formats.</p>

      <h2 className="mt-5 mb-4">How to engage with Ocean-Archive.org?</h2>

      <h3 className="mt-4">〜 Immerse yourself</h3>
      <p>You can interact with Ocean-Archive.org in several ways - plunge straight into the deep waters of the "Archive" and find your route through the database. Ocean-Archive.org uses its own layered tagging system that interweaves all content across the site. Explore a path of unexpected discovery through the Archive’s concept tags. If you already know what you are looking for, use the simple "Search" function. Or, let yourself guide by coincidence and click the “Suprise” button on the homepage. In addition, you can find the Archive keeper’s recommendations there as well.
      </p>

      <p>However, if you prefer a more narrative approach, <a href="/stories">"Journeys"</a> can guide you through the Archive's many currents. Journeys are a part of this digital ecosystem, dedicated to creating new narratives around bodies of water. Developed to inspire care for the Ocean, they host a variety of formats, from fiction stories to learning resources, and are open for free to public contributions.</p>

      <h3 className="mt-4">〜 Contribute your memories, research, and stories</h3>
      <p>Ocean-Archive.org is all about knowledge sharing and collaboration. It is a platform for all, and it is up to you to decide if you prefer to be a passive or rather an active user. You can <a href="/login">sign up</a>, create your profile and contribute by yourself! The platform operates under <a href="/terms-and-conditions">creative commons licenses</a>, and we, therefore, encourage you to dive into the existing content. If you find something that supports or illustrates your own research, collection of memories, or Journey, feel free to include it.  If you get lost on the way, see the <a href="/collection/190">“How to” tutorials</a> or feel free to contact us, we'll be happy to help.</p>

      <h3 className="mt-4">〜 Connect with the Community</h3>
      <p>The <a href="https://community.ocean-archive.org/" target="_blank">ocean comm/uni/ty</a> is an online social space for ocean lovers, researchers, and practitioners interested in interdisciplinary approaches to exchange knowledge and seek inspiration; to share and promote their work; to spark and maintain collaborations. The platform is intergenerational and transdisciplinary, and facilitates encounters across geographies through live events, posts, and more, dissolving academic boundaries on the way.</p>

      <h3 className="mt-4">〜 Think oceanically and (un)learn with a like-minded community</h3>
      <p>On Ocean-Archive.org, you will also find materials about our online course <strong>OCEAN / UNI</strong>, an initiative dedicated to art, activism, law, and science that invites fluid thinking with the Ocean as a way to move beyond the binaries of land and sea. OCEAN / UNI's curriculum provides students, researchers, and the public access to wide-ranging ideas and explorations through regular live sessions, reading groups, small-scale workshops or activations, and other online material, free and accessible to everyone on Ocean-Archive.org.</p>

      <br />
      <p><strong>In concert with OCEAN / UNI, and ocean comm/uni/ty, Ocean-Archive.org, forms an immersive three-pronged ecosystem for learning, community, and discovery.</strong></p>

      <p>Join TBA21–Academy’s mission to develop critical ocean literacy across worlds and ways of knowing through the lens of art. That means finding ways to understand and mobilize bodies of ocean knowledge that grow among places, people, and their lively connectivities.</p>

      <p>Dive in and let us know what you find 💙</p><br />

      {/* <p><strong>Ocean-Archive.org team:</strong></p> */}
      <h3>〜 Ocean-Archive.org team:</h3>

      <p>
      <strong>Petra Linhartová</strong>, Director of digital & innovation<br/>
      <strong>Michal Kučerák</strong>, Head of digital research<br/>
      <strong>Nina Šperanda</strong>, Digital project manager<br/>
      <strong>Fiona Middleton</strong>, Research and community<br/>
      <strong>Ani Ekin Özdemir</strong>, Community trainee<br/>
      <strong>Pietro Consolandi</strong>, OCEAN / UNI research lead<br/>
      <strong>Aleksandra Czerniak</strong>, Digital project manager<br/>
      <strong>Filip Kunovski</strong>, Digital intern<br/>
      <strong>Katarina Rakušček</strong>, Narrative strategist<br/>
      <strong>Barbora Horská</strong>, Journeys editor<br/>
      alongside TBA21–Academy team
      </p>

      <br />
      {/* <h3 className="mt-4">〜 Contact</h3>
      <p>
        General inquiry, <a href="mailto:info@ocean-archive.org">info@ocean-archive.org</a><br />
        Content contribution & support, <a href="mailto:acquisitions@ocean-archive.org">acquisitions@ocean-archive.org</a><br />
        Community helpdesk, <a href="mailto:community@ocean-archive.org">community@ocean-archive.org</a><br />
      </p>
      <br /> */}
      <DecadeLogo height='200px' width='auto' />
    </>
  )
}

export default About
