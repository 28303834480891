import { Item } from '../../types/Item';
import { Collection } from '../../types/Collection';
import { WP_REST_API_Post } from 'wp-types';

export function removeTopology(data: any, type?: 'item' | 'collection' | 'story'): Item[] | Collection[] | WP_REST_API_Post[] { // eslint-disable-line @typescript-eslint/no-explicit-any
  if (!data?.objects?.output?.geometries) {
    return [];
  }
  const geometries = data.objects.output.geometries;
  const response: Item[] | Collection[] = geometries.map( e => e.properties );

  // Add the type to the data
  if (type) {
    response.forEach(i => Object.assign(i, { __typename: type }));
  }

  return response;
}
