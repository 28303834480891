// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const preferredOrder = (obj: any, order: Array<string>): any => {
  let newObject = {};
  for (let i = 0; i < order.length; i++) {
      if (obj.hasOwnProperty(order[i])) {
          newObject[order[i]] = obj[order[i]];
      }
  }
  return newObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reorder = (list, startIndex, endIndex): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '8px 16px',
  margin: '0 0 8px 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  // change background colour if dragging
  backgroundColor: isDragging ? '#ffffff' : '#dddddd',
  color: '#142636',

  // styles we need to apply on draggables
  ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? '#0076FF' : '#dddddd',
  // padding: grid,
  width: '100%'
});
