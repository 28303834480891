import classnames from 'classnames'
import { connect } from 'react-redux'

import { doSearch, setFilter, nextPage } from 'actions/search'
import { SearchState } from '../../reducers/search'

import SearchItem from './SearchItem'

import './SearchResults.scss'

interface Props {
  searchState: SearchState
  search: Function
  loadNextPage: Function
  setSearchFilter: Function
  className?: string
}

const SearchResults = ({ searchState, search, loadNextPage, setSearchFilter, className }: Props) => {
  const classes = classnames('search-results', className)

  const setTagFilter = tag => {
    setSearchFilter('tags', tag, true)
    search('', { tags: tag })
  }

  const moreAvailable = searchState.totalHits > ( searchState.currentPage + 1 ) * searchState.pagination

  if (!searchState.searchHits || searchState.searchHits.length === 0) return (
    <div className={ classes }>
      <p>No results matching criteria</p>
    </div>
  )

  return (
    <div className={ classes }>
      { searchState.searchHits?.map(item => (
        <SearchItem
          key={ item.id }
          data={ item }
          filesFetched={ searchState.filesFetched }
          onTagClick={ setTagFilter }
          openInNewTab
        />
      )) }

      {/* Dummy items for last row alignment */}
      <div className="search-results__item" />
      <div className="search-results__item" />
      <div className="search-results__item" />
      <div className="search-results__item" />

      { moreAvailable && (
        <div className="search-results__more w-100 text-center mt-5">
          <button className="button" onClick={ () => loadNextPage() }>Load more</button>
        </div>
      ) }
    </div>
  )
}

const mapStateToProps = (state: { search: SearchState }) => ({
  searchState: state.search
})

export default connect(mapStateToProps, { search: doSearch, loadNextPage: nextPage, setSearchFilter: setFilter })(SearchResults)
