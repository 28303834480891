import  { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { storyURL } from 'urls';
import { WP_REST_API_EmbeddedTerms } from './StoryList';

type StoryItemProps = {
  slug: string;
  title: string;
  body: string;
  date: string;
  tags: WP_REST_API_EmbeddedTerms;
  categories: WP_REST_API_EmbeddedTerms;
  imageURL: string;
  setSelectedCategoryIds: Function;
  setSelectedTagIds: Function;
};

const StoryItem: FC<StoryItemProps> = ({
  slug,
  title,
  body,
  date,
  tags,
  categories,
  imageURL,
  setSelectedCategoryIds,
  setSelectedTagIds,
}) => {
  const conceptTags = categories.filter((category) =>
    category.link.includes('/concept-tags/')
  );
  const type = categories.filter((category) =>
    category.link.includes('/type/')
  )[0];

  const allAuthors = categories
    .filter((category) => category.link.includes('/authors/'))
    .map((author) => author.name);

  return (
    <div className="stories-item">
      <Link to={storyURL(slug)}>
        <div>
          <div
            className="stories-item__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="stories-item-content">
            <div
              className="stories-item-content__image"
              style={{ backgroundImage: `url(${imageURL})` }}
             />
            <div className="stories-item-content__texts">
              <div className="stories-item__authors">
                {allAuthors.map((authorName, idx) => (
                  <Fragment key={authorName}>
                    <p className="stories-item__author">{authorName}</p>
                    {idx < allAuthors.length - 1 ? <span>,</span> : <></>}
                  </Fragment>
                ))}
              </div>

              <div
                className="stories-item__body"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </div>
          </div>
        </div>
      </Link>
      <div className="stories-item-meta">
        <div>
          {type && (
            <>
              <span
                onClick={() => {
                  setSelectedCategoryIds(type.id);
                }}
                className="stories-item-link"
              >
                {type.name}
              </span>{' '}
              <span style={{ fontWeight: 'bold' }}>~</span>{' '}
            </>
          )}
          <span>{date.split('T')[0]}</span>
        </div>
        {conceptTags.length > 0 ? (
          <div>
            Concept tags:{' '}
            {conceptTags.map((conceptTag, idx) => (
              <Fragment key={conceptTag.id}>
                <span
                  onClick={() => {
                    setSelectedCategoryIds(conceptTag.id);
                  }}
                  className="stories-item-link"
                  dangerouslySetInnerHTML={{ __html: conceptTag.name }}
                 />
                {idx === conceptTags.length - 1 ? null : <span>, </span>}
              </Fragment>
            ))}
          </div>
        ) : null}
        {tags.length > 0 ? (
          <div>
            Keyword tags:{' '}
            {tags.map((tag, idx) => (
              <Fragment key={tag.id}>
                <span
                  key={tag.id}
                  onClick={() => setSelectedTagIds(tag.id)}
                  className="stories-item-link"
                  dangerouslySetInnerHTML={{ __html: tag.name }}
                />
                {idx === tags.length - 1 ? null : <span>, </span>}
              </Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StoryItem;
