import * as React from 'react';
import Clipboard from 'react-clipboard.js';

import 'styles/utils/share.scss';
import { FaExternalLinkAlt } from 'react-icons/fa';

interface State {
  clicked: boolean;
  error?: string;
}

type Props = {
  color?: 'dark-gray' | 'gray' | 'white';
  iconComponent?: React.ReactElement;
  size?: number;
  text?: string;
  authors?: string | string[] | null;
  title?: string | null;
  published?: string | number | null;
  url?: string;
  help?: string
} & (
  | {
      variant: 'prefixedWithHostname';
    }
  | {
      variant: 'fullText';
    }
  | {
      variant: 'citation';
    }
);

export default class Share extends React.Component<Props, State> {
  _isMounted;
  timeout;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;
    this.state = {
      clicked: false,
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  popover = () => {
    if (!this.state.clicked && this._isMounted) {
      this.setState({ clicked: true });
    }
    this.timeout = setTimeout(() => {
      clearTimeout(this.timeout);
      if (this._isMounted) {
        this.setState({ clicked: false, error: undefined });
      }
    }, 3000);
  };

  error = () => {
    if (this._isMounted) {
      this.setState({
        clicked: false,
        error: this.generateClipboardText(),
      });
    }
  };

  generateClipboardText() {
    switch (this.props.variant) {
      case 'prefixedWithHostname':
        return `https://${window.location.hostname}${this.props.text}`;
      case 'fullText':
        return this.props.text;
      case 'citation':
        return this.generateCitation();
      default:
        console.error('Invalid props on Share component');
        return '';
    }
  }

  generateCitation() {
    const authors = Array.isArray(this.props.authors) ? this.props.authors.join(', ') : this.props.authors || 'TBA21-Academy'
    const isPublishedYear = `${this.props.published}`.length === 4
    const published = isPublishedYear ? this.props.published : new Date(`${this.props.published}`)?.getFullYear()
    const accessedDate = new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

    return `${authors}, “${this.props.title || document.title}”, Ocean Archive [website], ${published || '[no date]'}, ${this.props.url || document.location}, accessed ${accessedDate}`
  }

  render() {
    let color = this.props.color || 'gray';
    return (
      <div className={`share share--${color}`}>
        <div id="shareButton">
          <Clipboard
            data-clipboard-text={this.generateClipboardText()}
            onSuccess={this.popover}
            onError={this.error}
            className="flex-vertical-center"
            style={{padding: '0px'}}
            title={this.props.help || ''}
          >
            {this.state.error
              ? this.state.error
              : this.state.clicked
              ? (this.props.size ? (<span style={{fontSize: this.props.size}}>Copied!</span>) : 'Copied!')
              : this.props.iconComponent || <FaExternalLinkAlt size={this.props.size} />}
          </Clipboard>
        </div>
      </div>
    );
  }
}
