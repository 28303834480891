import './TabSwitch.scss'

interface Props {
  tabs: {
    label: string
    value: string
  }[]
  activeTab: string
  className?: string
  onTabChange?: (value: string) => void
}

const TabSwitch = ({ tabs, activeTab, className, onTabChange }: Props) => {
  return (
    <div className={ 'tabswitch' + (className ? ` ${className}` : '') }>
      <ul>
        { tabs.map((tab, i) => (
          <li key={ i } className={ tab.value === activeTab ? 'active' : '' }>
            <button onClick={ () => onTabChange?.(tab.value) }>{ tab.label }</button>
          </li>
        )) }
      </ul>
    </div>
  )
}

export default TabSwitch
