import { FileTypes } from 'types/s3File';
import { Item, itemType } from '../../types/Item'

import { dateFromTimeYearProduced } from 'actions/home';

import PrettyAudio from 'components/layout/audio/PrettyAudio';
import { FilePreview } from 'components/utils/FilePreview';
import { Link } from 'react-router-dom';
import { itemURL, webArchiveURL } from 'urls';
import { TbHandClick } from 'react-icons/tb';
import { ReactComponent as HandClickIcon } from 'images/icons/hand-click.svg';
import { getReplayIframeSrc } from 'components/webarchive/ViewWebArchive';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { HiOutlineArrowsExpand } from 'react-icons/hi';

const ItemPreview = ({ item, autoplay = false }: { item: Item, autoplay?: boolean }) => {
  const {
    id,
    file,
    title,
    time_produced,
    year_produced,
    end_year_produced,
    url,
    s3_key,
    item_type,
    poster
  } = item

  const isAudio =
    (!!file && item_type === itemType.Audio) ||
    (!!file && file.type === FileTypes.Audio)

  const isSoundCloud = item_type === itemType.IFrame && (url?.match(/soundcloud/))
  const is3DOA = item_type === itemType.IFrame && (url?.match(/3DOA/))
  const isWebArchive = item_type === itemType.WebArchive

  return (
    <>
      { file && file.url && item_type !== itemType.IFrame && !isWebArchive && (
        <div className="file">
          { isAudio ? (
            <div className="w-100">
              <PrettyAudio
                data={{
                  id: `${id}_slider`,
                  title: title ? title : '',
                  url: file.url,
                  s3_key,
                  isCollection: false,
                  date: dateFromTimeYearProduced(
                    time_produced,
                    year_produced,
                    end_year_produced
                  )
                }}
                autoplay={ autoplay }
              />
            </div>
          ) : (
            <FilePreview file={file} poster={poster || undefined} />
          )}
        </div>
      ) }

      { item_type === itemType.IFrame && (
        <>
          { isSoundCloud || is3DOA
              ? <div>
                  <div id="item" className="container-fluid">
                    <iframe
                      title={title ? title : ''}
                      src={url || undefined}
                      allow="autoplay"
                      scrolling={'no'}
                      className={ isSoundCloud ? 'soundcloud-iframe' : 'threedee_model-iframe' }
                    />
                  </div>
                </div>
              : <div className="flex align-items-center" style={{ minHeight: '150px' }}>
                  <Link to={ itemURL(id) } target="_blank" className="opacity-30">
                    <FilePreview file={file} />
                  </Link>
                  <div className="absolute absolute-center no-events">
                    <HandClickIcon width="80px" height="80px" />
                  </div>
                  {/* <button className="absolute absolute-center button button--large-circle">
                    <HandClickIcon width="80px" height="80px" />
                  </button> */}
                </div>
          }
        </>
      ) }

      { isWebArchive && (
        <div>
          <div id="item" className="container-fluid">
            <Link to={ webArchiveURL(id) } target="_blank" style={{ position: 'absolute', top: '13px', left: '30px' }}>
              <HiOutlineArrowsExpand
                color="white"
                title='Open in separate window'
                size={28}
              />
            </Link>
            <iframe
              title={title ? title : ''}
              src={ getReplayIframeSrc(s3_key, url) }
              allow="autoplay"
              scrolling={'no'}
              className={ isSoundCloud ? 'soundcloud-iframe' : 'threedee_model-iframe' }
            />
          </div>
        </div>
      ) }
    </>
  )
}

export default ItemPreview