import { useState } from 'react'
import { Col, Row } from 'reactstrap'

import { Item } from 'types/Item'
import { Collection } from 'types/Collection'

import HighlightItem from 'components/highlight/HighlightItem'

interface Props {
  data: (Item | Collection)[]
  title?: string
}

const PortfolioContent = ({ data, title }: Props) => {
  const [ currentPage, setCurrentPage ] = useState(1)

  const content = data.slice(0, currentPage * 6)

  return (
    <>
      { data?.length !== 0 &&
        <section className="w-max-1024 slide-fade-in">
          <h2 className="mb-4">〜 { title }</h2>
          <Row className="profile-page__items">
            { content.map(it => (
              <Col xs="12" sm="6" md="4" className="px-1 mb-5" key={ it.id }>
                <HighlightItem data={ it as any } noTags withDescription />
              </Col>
            )) }
          </Row>
          { content.length < data.length && (
            <div className="text-center">
              <button className="button mt-4" onClick={ () => setCurrentPage(currentPage + 1) }>
                Load more
              </button>
            </div>
          ) }
        </section>
      }
    </>
  )
}

export default PortfolioContent
