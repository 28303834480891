// Defining our Actions for the reducers.

import { Item } from 'types/Item'

export const updateQueueItems = (data?: Item[]) => dispatch => {
  const state = {
    type: 'ITEMS',
    items: data || []
  }

  dispatch(state)
}

export const nextQueueItem = () => dispatch => {
  const state = {
    type: 'NEXT'
  }

  dispatch(state)
}

export const prevQueueItem = () => dispatch => {
  const state = {
    type: 'PREV'
  }

  dispatch(state)
}

export const selectQueueItem = (index: number) => dispatch => {
  const state = {
    type: 'SELECT',
    index
  }

  dispatch(state)
}
