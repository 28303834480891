import classnames from 'classnames'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import defaultImage from 'images/defaults/Unscharfe_Zeitung.jpg'

import { itemURL, collectionURL, storyURL, portfolioURL } from 'urls'
import { getItemIcon } from 'lib/itemTypeIcons'
import { SearchState, SearchHit } from '../../reducers/search'
import { plainText } from 'components/utils/textUtils'
import { tryGetImage } from 'utils'

import './SearchItem.scss'

interface Props {
  data: SearchHit,
  searchState: SearchState
  filesFetched?: boolean
  onTagClick?: Function
  className?: string
  link?: string
  noTags?: boolean
  openInNewTab?: boolean
  focused?: boolean
}

const SearchItem = ({ data, searchState, filesFetched = false, onTagClick, className, link, noTags, openInNewTab = false, focused = false }: Props) => {
  const isNestedCollection = data.subcollections?.length

  // const [ image ] = useState(() => tryGetImage(data)) 
  const image = tryGetImage(data)
  
  const title = data._highlightResult?.title?.value || data.title
  // const subtitle = data._highlightResult?.subtitle?.value || data.subtitle
  const description = data._highlightResult?.description?.value || data.description
  const plainDescription = plainText(description)
  const plainBio = plainText(data._highlightResult?.biography?.value || data.biography)
  const creators = data._highlightResult?.creators?.map(it => it.value) || data.creators || []
  const concept_tags = data._highlightResult?.concept_tags?.map(it => it.value) || data.concept_tags || []
  const yearProduced = data.year_produced || ( data.time_produced && new Date(data.time_produced).getFullYear() ) || ''

  const iconName = (() => {
    switch (data.record_type) {
      case 'collection':
        return isNestedCollection ? 'NestedCollection' : 'Collection'
      case 'story':
        return 'Journey'
      case 'portfolio':
        return 'Portfolio'
      default:
        return data.file?.type
    }
  })()

  const getLink = (id: string) => {
    if (link) return link

    switch (data.record_type) {
      case 'item':
        return itemURL(id)
      case 'collection':
        return collectionURL(id)
      case 'story':
        return storyURL(data.slug as string)
      case 'portfolio':
        return portfolioURL(data.profile_id as string)
      default:
        return null
    }
  }

  const cls = classnames(
    'search-results__item',
    { 'search-results__item--focused': focused },
    className
  )

  return (
    <div className={ cls }>
      <div className="search-results__item-image">
        <Link to={ getLink(`${data.id}`) } target={ openInNewTab ? '_blank' : '_self' }>
          <img src={ !image && filesFetched ? defaultImage : image } />
          { getItemIcon(iconName) }
        </Link>
      </div>
      <h3 className="search-results__item-title">
        <Link
          to={ getLink(`${data.id}`) }
          dangerouslySetInnerHTML={{ __html: title }}
          target={ openInNewTab ? '_blank' : '_self' }
        />
      </h3>
      <span className="search-results__item-meta" dangerouslySetInnerHTML={{ __html: [ ...creators, yearProduced ].join(', ') }} />
      <p
        className="search-results__item-description"
        dangerouslySetInnerHTML={{ __html: plainDescription?.substring(0, 800) || plainBio?.substring(0, 800) }}
      />
      { !noTags && (
        <div className="search-results__item-tags">
          { concept_tags?.map(tag => (
            <Button
              key={ tag }
              className={ classnames('page-link tag d-inline-block', { active: searchState.filters.tags === tag }) }
              style={{ padding: 0, marginBottom: 10 }}
              dangerouslySetInnerHTML={{ __html: '#' + tag }}
              onClick={ () => onTagClick?.(tag) }
            />
          )) }
        </div>
      ) }
    </div>
  )
}

const mapStateToProps = (state: { search: SearchState }) => ({
  searchState: state.search
})

export default connect(mapStateToProps, { })(SearchItem)
