import { API } from 'aws-amplify';
import { getItemUrls } from '../components/utils/s3File';
import { LOADINGOVERLAY } from './loadingOverlay';
import { ItemOrCollectionOrProfileOrStory } from '../reducers/searchConsole';
import { Item } from '../types/Item';
import { Collection } from '../types/Collection';
import { S3File } from '../types/s3File';
import {
  getStoriesAndTotalStoriesInDatabase,
  SearchStoryParams,
} from '../REST/story';
import { StoryListState } from '../reducers/story/storyList';

// Defining our Actions for the reducers.
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_RESULTS_LOADING = 'SEARCH_RESULTS_LOADING';
export const SEARCH_TOGGLE_OPEN = 'SEARCH_TOGGLE_OPEN';
export const SEARCH_CONCEPT_TAGS = 'SEARCH_CONCEPT_TAGS';

export interface CriteriaOption {
  label: string;
  value: string;
  originalValue: string;
  field: string;
}

export const toggle =
  (open: boolean = false) =>
  (dispatch) => {
    const state = {
      type: SEARCH_TOGGLE_OPEN,
      open: open,
    };

    getConceptTags();

    dispatch(state);
  };

export const getConceptTags = () => async (dispatch, getState) => {
  const { searchConsole } = getState();
  if (searchConsole.concept_tags && !searchConsole.concept_tags.length) {
    try {
      API.get('tba21', 'tags', {
        queryStringParameters: { type: 'concept' },
      }).then((res) => {
        dispatch({ type: SEARCH_CONCEPT_TAGS, concept_tags: res.tags });
      });
    } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      return;
    }
  }
};

export const changeView = (view: 'grid' | 'list') => (dispatch) => {
  dispatch({
    type: CHANGE_VIEW,
    view: view,
  });
};

export const search =
  (
    criteria: CriteriaOption[],
    focusArts: boolean = false,
    focusAction: boolean = false,
    focusScitech: boolean = false
  ) =>
  async (dispatch, getState) => {
    const state = {
      type: SEARCH_RESULTS,
    };

    dispatch({ type: LOADINGOVERLAY, on: true }); // Turn on the loading overlay

    try {
      let response;
      let results;
      let storyResults;
      if (!criteria || criteria.length === 0) {
        response = {};
        results = {};
        storyResults = [];
      } else {
        let freeTextSearch = criteria.filter((c) => c.field === 'story')[0];

        // user only search for story, skip tba21 api call
        if (criteria.length === 1 && freeTextSearch) {
          response = {
            results: [],
          };
          results = {
            loadedResults: [],
          };
        } else {
          response = await API.post('tba21', 'pages/search', {
            body: {
              criteria: criteria.map((e) => ({
                field: e.field,
                value: e.originalValue,
              })),
              limit: 50,
              focus_arts: focusArts,
              focus_action: focusAction,
              focus_scitech: focusScitech,
            },
          });

          results = await loadMore(response.results, 0);
        }


        let conceptTagsName = criteria.filter((c) => c.field === 'concept_tag');
        let keywordTagsName = criteria.filter((c) => c.field === 'keyword_tag');

        let categoryByName = getState().storyList
          .categoryByName as StoryListState['categoryByName'];

        let categoryIds = conceptTagsName
          .concat(keywordTagsName)
          .map(
            (conceptTag) =>
              categoryByName[conceptTag.originalValue] &&
              categoryByName[conceptTag.originalValue].id
          )
          .filter((t) => !!t);

        let storyParams: SearchStoryParams = {
          order: 'desc',
          orderBy: 'date',
          perPage: 50,
          page: 1,
          categoryIds,
          sticky: false,
        };

        if (freeTextSearch) {
          storyParams.title = freeTextSearch.originalValue;
        }

        // only fetch the first 50 stories...
        let storyResponse =
          categoryIds.length || freeTextSearch
            ? await getStoriesAndTotalStoriesInDatabase(storyParams)
            : { stories: [] };

        storyResults = storyResponse.stories;
      }

      let sortedResults = [...response.results.concat(storyResults)].sort(
        (a, b) => (b.date && a.date ? b.date.localeCompare(a.date) : a)
      );
      let sortedLoadedResults = [
        ...results.loadedResults.concat(storyResults),
      ].sort((a, b) => (b.date && a.date ? b.date.localeCompare(a.date) : a));

      Object.assign(state, {
        results: sortedResults,
        loadedResults: sortedLoadedResults,
        offset: results.offset,
        selectedCriteria: [...(criteria ? criteria : [])],
      });
    } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      console.log(e);
    } finally {
      dispatch(state);
      dispatch({ type: LOADINGOVERLAY, on: false }); // Turn off the loading overlay
    }
  };

export const loadMoreResults = () => async (dispatch, getState) => {
  dispatch({ type: SEARCH_RESULTS_LOADING, loading: true });
  try {
    const { results, loadedResults, offset } = getState().searchConsole;
    const moreResults = await loadMore(results, loadedResults.length, offset);
    dispatch({
      type: SEARCH_RESULTS,
      results: results,
      loadedResults: [...loadedResults, ...moreResults.loadedResults],
      offset: moreResults.offset,
    });
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    dispatch({ type: SEARCH_RESULTS_LOADING, loading: false });
  }
};

const loadMore = async (
  results: ItemOrCollectionOrProfileOrStory[],
  amountLoaded: number,
  offset: number = 0
): Promise<{
  loadedResults: ItemOrCollectionOrProfileOrStory[];
  offset?: number;
}> => {
  const response: ItemOrCollectionOrProfileOrStory[] = [];
  const counter = results.length - amountLoaded >= 10 ? 10 : results.length;

  try {
    for (let i = 0; i < counter; i++) {
      let result: ItemOrCollectionOrProfileOrStory = results[offset + i];
      if (result) {
        if (result.hasOwnProperty('s3_key')) {
          const itemOrCollection = result as Item | Collection;

          const s3Key = itemOrCollection.s3_key;
          if (Array.isArray(s3Key) && s3Key.length) {
            if (s3Key[0]) {
              const file: S3File | false = await getItemUrls(s3Key[0]);
              if (file) {
                Object.assign(itemOrCollection, { file });
              }
            }
          } else if (typeof s3Key === 'string') {
            let file: S3File | false = false;
            if (itemOrCollection.url) {
              file = await getItemUrls(s3Key, itemOrCollection.url);
            } else {
              file = await getItemUrls(s3Key);
            }
            if (file) {
              Object.assign(itemOrCollection, { file });
            }
          }
        }

        response.push(result);
      }
    }

    return {
      loadedResults: response,
      offset: offset + counter,
    };
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    return {
      loadedResults: response,
    };
  }
};

export const searchAndOpen =
  (...args) =>
  (dispatch) => {
    // @ts-ignore
    dispatch(search(...args));
    dispatch(toggle(true));
  };
