import { SearchStoryParams } from 'REST/story';
import { getAllCategories, getAllTags, getAllPostsInPreview } from '../../REST/story';
import { getStoriesAndTotalStoriesInDatabase } from '../../REST/story';

export const FETCH_STORIES_LOADING = 'FETCH_STORIES_LOADING';
export const FETCH_STORIES_LOADING_INITIAL = 'FETCH_STORIES_LOADING_INITIAL';
export const FETCH_STORIES_ERROR = 'FETCH_STORIES_ERROR';
export const FETCH_STORIES_INITIAL_SUCCESS = 'FETCH_STORIES_INITIAL_SUCCESS';
export const FETCH_STORIES_INCREMENTAL_SUCCESS =
  'FETCH_STORIES_INCREMENTAL_SUCCESS';
export const FETCH_STORIES_NEXT_PAGE = 'FETCH_STORIES_NEXT_PAGE';
export const FETCH_STORIES_PER_PAGE = 10;

export const FETCH_CATEGORIES_LOADING = 'FETCH_CATEGORIES_LOADING';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

export const FETCH_TAGS_LOADING = 'FETCH_TAGS_LOADING';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';

export const fetchStoriesInitial =
  (params: SearchStoryParams) => async (dispatch) => {
    dispatch({ type: FETCH_STORIES_LOADING_INITIAL });
    _fetchStories(params, FETCH_STORIES_PER_PAGE, 1, dispatch);
  };

export const fetchStoriesIncremental =
  (params: SearchStoryParams) => async (dispatch, getState) => {
    const state = getState();
    if (state.storyList.status === FETCH_STORIES_LOADING) {
      return;
    }
    const totalStory = state.storyList.stories.length;
    const page = Math.floor(totalStory / FETCH_STORIES_PER_PAGE) + 1;
    _fetchStories(params, FETCH_STORIES_PER_PAGE, page, dispatch);
  };

export const _fetchStories = async (
  params: Omit<SearchStoryParams, 'perPage' | 'page'>,
  perPage: number,
  page: number,
  dispatch
) => {
  dispatch({ type: FETCH_STORIES_LOADING });
  try {
    const { stories, totalStoriesInDatabase, hasMore } =
      await getStoriesAndTotalStoriesInDatabase({
        ...params,
        perPage,
        page,
      });
    dispatch({
      type:
        page === 1
          ? FETCH_STORIES_INITIAL_SUCCESS
          : FETCH_STORIES_INCREMENTAL_SUCCESS,
      payload: {
        stories,
        totalStoriesInDatabase,
        hasMore,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_STORIES_ERROR,
    });
  }
};

export const fetchCategories = () => async (dispatch) => {
  dispatch({ type: FETCH_CATEGORIES_LOADING });
  try {
    let categories = await getAllCategories();
    let previews = await getAllPostsInPreview();

    // adjust relevant category.count to exclude posts in preview
    previews.forEach((preview) => {
      if (preview.categories){
        for (var x = 0; x < preview.categories.length; x++){
          let idx = categories.findIndex(category => {
            return category.id === preview.categories![x];
          });

          categories[idx].count--;
        }
      }
    });

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: {
        categories,
      },
    });
  } catch {
    dispatch({
      type: FETCH_CATEGORIES_ERROR,
    });
  }
};

export const fetchTags = () => async (dispatch) => {
  dispatch({ type: FETCH_TAGS_LOADING });
  try {
    // refer to REST/Story for existing fetching limitation.
    let tags = await getAllTags();
    let previews = await getAllPostsInPreview();

    // exclude posts in preview from relevant tag.count
    previews.forEach((preview) => {
      if (preview.tags){
        for (var x = 0; x < preview.tags.length; x++){
          let idx = tags.findIndex(tag => {
            return tag.id === preview.tags![x];
          });

          tags[idx].count--;
        }
      }
    });

    dispatch({
      type: FETCH_TAGS_SUCCESS,
      payload: {
        tags,
      },
    });
  } catch {
    dispatch({
      type: FETCH_TAGS_ERROR,
    });
  }
};
