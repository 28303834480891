import { RouteComponentProps, withRouter } from 'react-router'
import { Container, Button } from 'reactstrap'

import { APITag } from 'components/metadata/Tags'
import BackToTop from 'components/utils/BackToTop'

import './HomeExplore.scss'

interface Props extends RouteComponentProps {
  tags: APITag[]
  className?: string
}

const HomeExplore = ({ tags, className, history }: Props) => {
  const classNames = ['home-explore py-5', className].join(' ')

  return (
    <Container id="explore" fluid className={ classNames } style={{ scrollMarginTop: '60px' }} tag="section">
      <h2 className="py-4 ml-1 text-md-center">〜 Explore<BackToTop /></h2>
      <p className="text-md-center text-subtitle col-md-6 mx-auto">Try a path of unexpected discovery through the Archive’s concept tags.</p>
      { tags?.length ?
        <div className="home-explore__tags col-md-8 mx-auto mt-4 text-center" style={{marginTop: 10, marginBottom: 10}}>
          { tags.map(tag => (
            <Button
              className="page-link tag d-inline-block"
              style={{ padding: 0, marginBottom: 10 }}
              key={ tag.id }
              onClick={ () => history.push('/search', { filters: { tags: tag.tag_name } }) }
            >
              #{ tag.tag_name }
            </Button>
          )) }
        </div>
        : null
      }
    </Container>
  )
}

export default withRouter(HomeExplore)
